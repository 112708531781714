/**
 * Tickets API
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface InvoicePojo { 
    id?: number;
    status?: InvoicePojo.StatusEnum;
    amount?: number;
    invoiceNumber?: string;
    datePaid?: string;
    customerName?: string;
    customerEmail?: string;
    customerPhoneNumber?: string;
    paymentStatus?: InvoicePojo.PaymentStatusEnum;
    description?: string;
    dueDate?: string;
    customerAddress?: string;
    billType?: InvoicePojo.BillTypeEnum;
    approvalStatus?: InvoicePojo.ApprovalStatusEnum;
    reasonForDisapproval?: string;
    totalAmountPaid?: number;
}
export namespace InvoicePojo {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE';
    export const StatusEnum = {
        ACTIVE: 'ACTIVE' as StatusEnum,
        INACTIVE: 'INACTIVE' as StatusEnum
    };
    export type PaymentStatusEnum = 'PAID' | 'PENDING' | 'PARTIAL';
    export const PaymentStatusEnum = {
        PAID: 'PAID' as PaymentStatusEnum,
        PENDING: 'PENDING' as PaymentStatusEnum,
        PARTIAL: 'PARTIAL' as PaymentStatusEnum
    };
    export type BillTypeEnum = 'TICKET_BATCH';
    export const BillTypeEnum = {
        TICKET_BATCH: 'TICKET_BATCH' as BillTypeEnum
    };
    export type ApprovalStatusEnum = 'APPROVED' | 'PENDING' | 'DISAPPROVED';
    export const ApprovalStatusEnum = {
        APPROVED: 'APPROVED' as ApprovalStatusEnum,
        PENDING: 'PENDING' as ApprovalStatusEnum,
        DISAPPROVED: 'DISAPPROVED' as ApprovalStatusEnum
    };
}


