<div class=" m-0">
  <div class="modal-header">
    <div class="container-fluid">
      <div class="d-flex align-items-center">
        <div class="pull-left flex-fill">
          <h6 class="modal-title" id="modal-title">Change Password</h6>
        </div>
        <button (click)="onCancel()" class="close pull-right" type="button">
          <span aria-hidden="true" class="fa fa-times modal-title"></span>
        </button>
      </div>

    </div>

  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <form class="" (submit)="onUpdatePassword()" [formGroup]="form" id="form">
        <div class="custom-card">
          <div class="row g-3 px-2 py-5">
            <div class="col-md-12">
              <span class="text-danger h6">Are you sure you want to reset the password of this user?</span>
            </div>
            <div class="col-md-12">
              <div class="row filter-bg my-0 border-left " style="border-width: 14px !important;border-color: #053C4D !important;">
                <div class="col-md-3">
                 <span class="h6">Username:</span>
                </div>
                <div class="col-md-9">
                  <span class="">{{username}}</span>

                </div>
                <div class="col-md-3">
                  <span class="h6">Phone Number:</span>
                </div>
                <div class="col-md-9">
                  {{phoneNumber}}
                </div>
              </div>
            </div>
            <div class="col-md-12 form-group">
              <input [formControlName]="'newPassword'" class="form-control" required="" id="newPassword"
                     placeholder="Enter new password" type="password" [maxLength]="30"/>
            </div>
          </div>
        </div>
      </form>
    </div>

  </div>
  <div class="modal-footer">
    <div class="container-fluid text-end">
      <button (click)="onCancel()" class="btn btn-light mr-2" type="button">
        Cancel
      </button>
      <button form="form" class="btn btn-danger" type="submit">
        <i class="fa" [ngClass]="{'':!loading,'fa-spinner fa-spin':loading}"></i>
        Update Password
      </button>
    </div>

  </div>
</div>
