<div class="trezo-card-content">
    <div class="row g-3 mb-4">
        <div class="col-sm-12"></div>
        <div class="col-sm-12 text-center mb-2">
            <i class="material-symbols-outlined">
                {{ 'for_you' }}
            </i>
            <h3>Configuring Scheme Details</h3>
        </div>
    </div>

    <form [formGroup]="form">
        <div class="row g-3">
            <div class="col-md-6">
                <label for="adminFirstName" class="form-label">Admin First Name</label>
                <input [formControlName]="'adminFirstName'"
                       class="form-control" required="" id="adminFirstName"
                       placeholder="Admin First Name" [maxLength]="30">
                <error-message
                        [controlName]="'adminFirstName'"
                        [singularError]="true"
                        [form]="form"
                />
            </div>

            <div class="col-md-6">
                <label for="adminLastName" class="form-label">Admin Last Name</label>
                <input [formControlName]="'adminLastName'"
                       class="form-control" required id="adminLastName"
                       placeholder="Admin Last Name" [maxLength]="30"/>
                <error-message
                        [controlName]="'adminLastName'"
                        [singularError]="true"
                        [form]="form"
                />
            </div>

            <div class="col-md-6">
                <label for="adminEmail" class="form-label">Admin Email</label>
                <input [formControlName]="'adminEmail'"
                       class="form-control" required id="adminEmail"
                       placeholder="Admin Email Address" [maxLength]="100"/>
                <error-message
                        [controlName]="'adminEmail'"
                        [singularError]="true"
                        [form]="form"
                />
            </div>

            <div class="col-md-6">
                <label for="adminPhoneNumber" class="form-label">Admin Phone Number</label>
                <input class="form-control" [formControlName]="'adminPhoneNumber'"
                       required id="adminPhoneNumber"
                       placeholder="Admin Phone Number" [maxLength]="30"/>
                <error-message
                        [controlName]="'adminPhoneNumber'"
                        [singularError]="true"
                        [form]="form"
                />
            </div>
        </div>

        <hr class="hr mt-5 mb-5">

        <div class="row g-3">
            <div class="col-md-6">
                <label for="schemeName" class="form-label">Scheme Name</label>
                <input [formControlName]="'name'" class="form-control"
                       required id="schemeName" placeholder="Scheme Name"/>
                <error-message
                        [controlName]="'name'"
                        [singularError]="true"
                        [form]="form"
                />
            </div>


            <div class="col-md-6">
                <label class="form-label">Scheme Type</label>
                <select [formControlName]="'type'" class="form-select">
                    @for (scheme of schemeTypes; track scheme) {
                        <option [value]="scheme">{{ scheme | removeUnderscore }}</option>
                    }
                </select>
                <error-message
                        [controlName]="'type'"
                        [singularError]="true"
                        [form]="form"
                />
            </div>

            <div class="col-md-6">
                <label for="revenueHead" class="form-label">Service Name</label>
                <input [formControlName]="'revenueHeadName'" id="revenueHead"
                       type="text" class="form-control">
                <error-message
                        [controlName]="'revenueHeadName'"
                        [singularError]="true"
                        [form]="form"
                />
            </div>

            <div class="col-md-6">
                <label for="revenueCode" class="form-label">Service Code</label>
                <input [formControlName]="'revenueHeadCode'" id="revenueCode"
                       type="text" class="form-control">
                <error-message
                        [controlName]="'revenueHeadCode'"
                        [singularError]="true"
                        [form]="form"
                />
            </div>
        </div>

        <hr class="hr mt-5 mb-5">

        <div class="row g-3">
            <div class="col-md-4">
                <label class="form-label">Country</label>
                <select class="form-select" [formControlName]="'countryId'">
                    <option [value]="''">-- Select Country --</option>
                    @for (country of countries; track country) {
                        <option [value]="country.id">{{ country.name | titlecase }}</option>
                    }
                </select>
                <error-message
                        [controlName]="'countryId'"
                        [singularError]="true"
                        [form]="form"
                />
            </div>

            <div class="col-md-4">
                <label class="form-label">State</label>
                <select class="form-select" [formControlName]="'stateId'" id="stateId" required>
                    @if (fetchingStates) {
                        <option [value]="''">Fetching States</option>
                    }
                    @if (!fetchingStates && !countryId) {
                        <option [value]="''">-- Select a Country --</option>
                    }
                    @if (!fetchingStates && states?.length == 0) {
                        <option [value]="''">No states found</option>
                    }
                    @if (!fetchingStates && states?.length) {
                        <option [value]="''">-- Select a State --</option>
                        @for (p of states; track p) {
                            <option [value]="p.id">{{ p.name }}</option>
                        }
                    }
                </select>
                <error-message
                        [controlName]="'stateId'"
                        [singularError]="true"
                        [form]="form"
                />
            </div>
            <div class="col-md-4">
                <label class="form-label">LGA</label>
                <select class="form-select" [formControlName]="'lgaId'" id="lgaId" required>
                    @if (fetchingLga) {
                        <option [value]="''">Fetching LGA</option>
                    }
                    @if (!fetchingLga && !stateCode) {
                        <option [value]="''">-- Select a State --</option>
                    }
                    @if (!fetchingLga && stateCode && lgas?.length == 0) {
                        <option [value]="''">No Lgas found</option>
                    }
                    @if (!fetchingStates && stateCode && lgas?.length) {
                        <option [value]="''">-- Select LGA --</option>
                        @for (p of lgas; track p) {
                            <option [value]="p.id">{{ p.name }}</option>
                        }
                    }
                </select>
                <error-message
                        [controlName]="'lgaId'"
                        [singularError]="true"
                        [form]="form"
                />
            </div>
        </div>

        <div class="row g-3 mt-5">
            <label class="form-label">Scheme Banner</label>
            <ngx-dropzone class="mb-2"
                          [ngClass]="{
                              'border-danger': form.get('banner')?.invalid && !form.get('banner')?.untouched,
                              'border-success': !form.get('banner')?.untouched && form.get('banner')?.valid
                          }"
                          (change)="onBannerSelected($event)" style="width: 100%" [accept]="'.jpeg,.jpg,.png'">
                @for (f of [form.get('banner')?.value]; track $index) {
                    <div class="d-flex flex-fill" style="width: 100%">
                        @if (!f) {
                            <ngx-dropzone-label>
                                <div class="d-flex flex-column">
                                    <div class="text-center flex-fill mb-2">
                                        <span class="iconify" data-icon="icon-park-outline:upload-one"
                                              data-height="25"></span>
                                    </div>
                                    <div class="text-center flex-fill">
                                        Upload Banner
                                    </div>
                                </div>
                            </ngx-dropzone-label>
                        }
                        @if (f) {
                            <img src="{{bannerPreview}}" class="img-fluid" style="background-size: cover">
                        }
                    </div>
                }
            </ngx-dropzone>
            <span class="small">Only .png, .jpeg, .jpg, files not more than 5mb and a ratio of 3:1 (900px by 300px)</span>
        </div>
        <div class="mt-4 text-end">
            <button type="submit" class="btn btn-primary" (click)="submit()">
                Next
                <i class="material-symbols-outlined btn-icon">
                    arrow_forward
                </i>
            </button>
        </div>
    </form>
</div>
