import {Component, Input, OnInit} from '@angular/core';
import {
  AgentControllerService,
  AgentPojo,
  AppUserPojo,
  OrganizationControllerService,
  OrganizationSummaryPojo,
  SearchAppUsersRequestParams,
  TicketBatch,
  TicketBatchControllerService,
  TicketBatchPojo
} from "../../../../../sdk/tickets-sdk";
import {AuthenticationService} from "../../../services/authentication.service";
import {ToastrService} from "ngx-toastr";
import {UserAccount} from "../../../models/account/user-account.model";
import {BsModalRef} from "ngx-bootstrap/modal";
import {SearchManager} from "../../../shared/search/search-manager";
import {SearchHandler} from "../../../shared/search/search-handler";
import {SearchFilterSource} from "../../../shared/search/search-filter-source";
import {Observable} from 'rxjs';
import {PaginatedSearch} from "../../../shared/search/paginated-search";
import {NameValuePair} from "../../../models/etc/name-value-pair.model";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {distinctUntilChanged, tap} from "rxjs/operators";
import {SelectionManager} from "../../../models/etc/selection-manager";
import {DatePipe, NgClass, TitleCasePipe} from "@angular/common";
import {PaginationComponent} from "ngx-bootstrap/pagination";

@Component({
  selector: 'app-app-user-batch-assignment',
  templateUrl: './app-user-batch-assignment.component.html',
  styleUrls: ['./app-user-batch-assignment.component.css'],
  imports: [
    ReactiveFormsModule,
    TitleCasePipe,
    PaginationComponent,
    DatePipe,
    FormsModule,
    NgClass
  ],
  standalone: true
})
export class AppUserBatchAssignmentComponent implements OnInit, SearchHandler<TicketBatchPojo, any>,
  SearchFilterSource <any> {
  @Input()
  appUser: AppUserPojo
  @Input()
  organizationId: number | undefined;
  @Input()
  agentId: number | undefined;
  account: UserAccount;
  searchManager: SearchManager<TicketBatchPojo, any>;
  form: FormGroup
  maxPageSize = 10;
  organizations: Array<OrganizationSummaryPojo> | undefined;
  agents: Array<AgentPojo> | undefined;
  fetchingAgents = false;
  selectionManager: SelectionManager<TicketBatchPojo, TicketBatchPojo>;
  loading = false;

  constructor(
    private organizationControllerService: OrganizationControllerService,
    private authenticationService: AuthenticationService,
    private agentControllerService: AgentControllerService,
    private toastrService: ToastrService,
    private fb: FormBuilder,
    private bsModalRef: BsModalRef,
    private ticketBatchControllerService: TicketBatchControllerService
  ) {
    class TicketBatchSelection extends SelectionManager<TicketBatchPojo, TicketBatchPojo> {

      getIdentifier(e: TicketBatchPojo): string | number {
        return e.id;
      }

      getState(e: TicketBatchPojo) {
        return e;
      }

      override isSelectable(e: TicketBatchPojo): boolean {
        return true;
      }
    }

    this.selectionManager = new TicketBatchSelection();
    this.form = this.fb.group({
      agentId: ['', [Validators.required]],
      organizationId: ['', [Validators.required]]
    })
    this.searchManager = new SearchManager<TicketBatch, any>(this, this)
  }

  search(page: number, filter?: any): Observable<any> {
    console.log(filter)
    const offset = ((page || 0) - 1) * this.searchManager.itemsPerPage;
    let paginatedSearch = PaginatedSearch.createCleanFilter(filter, this.searchManager.itemsPerPage, offset);
    console.log(paginatedSearch.getSearchParams())
    return this.ticketBatchControllerService.getBatchesUnassignedToAppUsers({...paginatedSearch.getSearchParams() as any})
  }

  ngOnInit(): void {
    this.authenticationService.getAccount()
      .pipe(
        tap(x => {
          this.account = x;
          this.form.patchValue({
            agentId: this.account.agent?.id || '',
            organizationId: this.account.organization?.id || '',
          });
        })).subscribe(value => {
      if (this.canSelectScheme) {
        this.organizationControllerService.searchOrganizations({
          limit: 100,
          id: this.getOrganizationId(),
          approvalStatus: 'APPROVED'
        }).subscribe(value => {
          this.organizations = value.results;
        });
        this.form.get('organizationId').valueChanges.pipe(distinctUntilChanged()).subscribe(value => {
          this.organizationId = value;
          this.form.get('agentId').patchValue('');
          this.fetchAgents(value);
        });
      }
      if (this.canSelectAgent) {
        if (this.getOrganizationId()) {
          this.fetchAgents(this.getOrganizationId());
        }
        this.form.get('agentId').valueChanges.pipe(distinctUntilChanged()).subscribe(value => {
          if (value) {
          }
        });
      } else {
        this.searchManager.reloadAndShowFirstPage();
      }
    });
  }

  onCancel() {
    this.bsModalRef.hide();
  }

  get canSelectScheme() {
    return this.account && (this.account.accountType == 'ADMIN');
  }

  get canSelectAgent() {
    return this.account && (this.account.accountType == 'ADMIN' || this.account.accountType == 'ORGANIZATION') && this.authenticationService.hasRole('ADMIN');
  }

  getFilter(): any {
    console.log("=====> get filter")
    return {agentId: this.getAgentId(), organizationId: this.getOrganizationId()};
  }

  getPersistentKey(): string {
    return AppUserBatchAssignmentComponent.name;
  }

  getSearchDescriptor(filter: SearchAppUsersRequestParams): NameValuePair[] {
    return [];
  }

  getOrganizationId() {
    if (this.account.accountType == 'ORGANIZATION') {
      return this.account.organization?.id;
    }
    return this.form.get('organizationId').value;
  }

  getAgentId() {
    if (this.account.accountType == 'ORGANIZATION' && this.account.agent) {
      return this.account.agent?.id;
    }
    return this.form.get('agentId').value;
  }

  private fetchAgents(orgId: any) {
    if (!orgId) {
      this.agents = [];
      return;
    }
    this.fetchingAgents = true;
    this.agentControllerService.searchAgent({organizationId: orgId, limit: 200, status: 'ACTIVE'})
      .subscribe(value => {
        this.fetchingAgents = false;
        this.agents = value.results;
      }, error => {
        this.fetchingAgents = false;
        if (error.error && typeof error.error == 'object' && error.error.message) {
          this.toastrService.error(error.error.message);
          return;
        }
        this.toastrService.error('Could not fetch agents. Please check your internet connection.')
      });
  }

  assign() {
    if (this.selectionManager.selectionSize() < 1) {
      this.toastrService.info('You have not selected any batch to assign');
      return;
    }
    if (confirm('Are you sure you want to assign the selected batches to this user?')) {
      this.loading = true;
      this.ticketBatchControllerService.assignTicketBatchToAppUser({
        newTicketBatchAssignmentDto: {
          appUserId: this.appUser.id,
          agentId: this.getAgentId(),
          organizationId: this.getOrganizationId(),
          ticketBatchIds: this.selectionManager.getSelections().map(value => value.id)
        }
      })
        .subscribe(value => {
          this.loading = false;
          this.bsModalRef.hide();
        }, error => {
          this.loading = false;
          if (error.error && typeof error.error == 'object' && error.error.message) {
            this.toastrService.error(error.error.message);
            return;
          }
          this.toastrService.error('Could not assign batches to user. Please check your internet connection.')
        });
    }
  }

  // @ts-ignore
  searchBatches() {
    if (!this.form.valid) {
      return false;
    }
    this.searchManager.reloadAndShowFirstPage();
  }
}
