<footer
    class="pt-150"
    [class.component-dark-theme]="themeService.isDark()"
    [class.component-rtl-enabled]="themeService.isRTLEnabled()"
>
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo d-inline-block">
                        <img src="images/logo-big.svg" alt="logo">
                        <img src="images/white-logo-big.svg" class="d-none" alt="logo">
                    </a>
                    <p>
                        With customizable dashboards tailored to your needs, collaborate effortlessly with your team and stay ahead with real-time updates.
                    </p>
                    <div class="socials">
                        <a href="#" class="d-inline-block lh-1" target="_blank">
                            <i class="ri-facebook-fill"></i>
                        </a>
                        <a href="#" class="d-inline-block lh-1" target="_blank">
                            <i class="ri-twitter-x-fill"></i>
                        </a>
                        <a href="#" class="d-inline-block lh-1" target="_blank">
                            <i class="ri-linkedin-fill"></i>
                        </a>
                        <a href="#" class="d-inline-block lh-1" target="_blank">
                            <i class="ri-dribbble-fill"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="single-footer-widget">
                    <h3 class="fw-semibold">
                        Our Products
                    </h3>
                    <ul class="p-0 custom-links m-0 list-unstyled">
                        <li>
                            <a routerLink="." class="d-inline-block">
                                Trezo Dashboard
                            </a>
                        </li>
                        <li>
                            <a routerLink="." class="d-inline-block">
                                Tagus Admin
                            </a>
                        </li>
                        <li>
                            <a routerLink="." class="d-inline-block">
                                eCademy LMS
                            </a>
                        </li>
                        <li>
                            <a routerLink="." class="d-inline-block">
                                Admash Template
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="single-footer-widget">
                    <h3 class="fw-semibold">
                        Quick Links
                    </h3>
                    <ul class="p-0 custom-links m-0 list-unstyled">
                        <li>
                            <a routerLink="." class="d-inline-block">
                                Home
                            </a>
                        </li>
                        <li>
                            <a routerLink="." class="d-inline-block">
                                Features
                            </a>
                        </li>
                        <li>
                            <a routerLink="." class="d-inline-block">
                                Testimonials
                            </a>
                        </li>
                        <li>
                            <a routerLink="." class="d-inline-block">
                                Our Team
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="single-footer-widget">
                    <h3 class="fw-semibold">
                        Privacy Policy
                    </h3>
                    <ul class="p-0 custom-links m-0 list-unstyled">
                        <li>
                            <a routerLink="." class="d-inline-block">
                                Terms & Conditions
                            </a>
                        </li>
                        <li>
                            <a routerLink="." class="d-inline-block">
                                Cookie Policy
                            </a>
                        </li>
                        <li>
                            <a routerLink="." class="d-inline-block">
                                Notice at Collection
                            </a>
                        </li>
                        <li>
                            <a routerLink="." class="d-inline-block">
                                Privacy Policy
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="copyright-area bg-white text-center">
        <div class="container">
            <p>
                © <span>Trezo</span> is Proudly Owned by <a href="https://envytheme.com/" target="_blank">EnvyTheme</a>
            </p>
        </div>
    </div>
</footer>