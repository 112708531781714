/**
 * Tickets API
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PortalAccount } from './portal-account';
import { PortalAccountMemberRole } from './portal-account-member-role';
import { PortalUser } from './portal-user';


export interface PortalAccountMembership { 
    id?: number;
    createdAt?: string;
    lastUpdatedAt?: string;
    createdBy?: PortalUser;
    lastUpdatedBy?: PortalUser;
    status?: PortalAccountMembership.StatusEnum;
    dateDeactivated?: string;
    deactivatedBy?: PortalUser;
    portalAccount?: PortalAccount;
    portalUser?: PortalUser;
    portalAccountMemberRoles?: Array<PortalAccountMemberRole>;
}
export namespace PortalAccountMembership {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE';
    export const StatusEnum = {
        ACTIVE: 'ACTIVE' as StatusEnum,
        INACTIVE: 'INACTIVE' as StatusEnum
    };
}


