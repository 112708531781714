/**
 * Tickets API
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RevenueSource } from './revenue-source';
import { PortalUser } from './portal-user';


export interface RevenueItem { 
    id?: number;
    createdAt?: string;
    lastUpdatedAt?: string;
    createdBy?: PortalUser;
    lastUpdatedBy?: PortalUser;
    status?: RevenueItem.StatusEnum;
    dateDeactivated?: string;
    deactivatedBy?: PortalUser;
    name?: string;
    code?: string;
    amount?: number;
    description?: string;
    type?: RevenueItem.TypeEnum;
    minimumAmount?: number;
    maximumAmount?: number;
    computationType?: RevenueItem.ComputationTypeEnum;
    revenueSource?: RevenueSource;
}
export namespace RevenueItem {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE';
    export const StatusEnum = {
        ACTIVE: 'ACTIVE' as StatusEnum,
        INACTIVE: 'INACTIVE' as StatusEnum
    };
    export type TypeEnum = 'TAX' | 'NON_TAX';
    export const TypeEnum = {
        TAX: 'TAX' as TypeEnum,
        NON_TAX: 'NON_TAX' as TypeEnum
    };
    export type ComputationTypeEnum = 'FIXED' | 'VARIABLE' | 'RATE' | 'RANGE';
    export const ComputationTypeEnum = {
        FIXED: 'FIXED' as ComputationTypeEnum,
        VARIABLE: 'VARIABLE' as ComputationTypeEnum,
        RATE: 'RATE' as ComputationTypeEnum,
        RANGE: 'RANGE' as ComputationTypeEnum
    };
}


