import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {
  AgentControllerService,
  AppUserControllerService,
  OrganizationControllerService,
  PortalUserControllerService
} from "../../../../../sdk/tickets-sdk";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {UserAccount} from "../../../models/account/user-account.model";
import {BsModalRef} from "ngx-bootstrap/modal";
import {ToastrService} from "ngx-toastr";
import {AuthenticationService} from "../../../services/authentication.service";
import {CustomValidators} from "../../validators/custom-validators";
import {NgClass} from "@angular/common";

@Component({
  selector: 'app-reset-password-dialog',
  templateUrl: './reset-password-dialog.component.html',
  styleUrls: ['./reset-password-dialog.component.css'],
  imports: [
    ReactiveFormsModule,
    NgClass
  ],
  standalone: true
})
export class ResetPasswordDialogComponent implements OnInit {

  @Input()
  username: string;
  @Input()
  email: string;
  @Input()
  notificationChannel: 'PHONE_NUMBER' | 'EMAIL' = 'PHONE_NUMBER';
  @Input()
  phoneNumber: string;
  @Input()
  name: string;
  form: FormGroup;
  loading = false;
  account: UserAccount | undefined;
  onPasswordChanged = new EventEmitter<any>();

  constructor(
    private appUserControllerService: AppUserControllerService,
    private agentControllerService: AgentControllerService,
    private organizationControllerService: OrganizationControllerService,
    private portalUserControllerService: PortalUserControllerService,
    private formBuilder: FormBuilder,
    private bsModalRef: BsModalRef,
    private toastrService: ToastrService,
    private authenticationService: AuthenticationService
  ) {
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      newPassword: ['', [Validators.required, CustomValidators.minLength(6)]]
    })
    this.authenticationService.getAccount().subscribe(value => {
      this.account = value;
    });
  }

  onCancel() {
    this.bsModalRef.hide();
  }

  onUpdatePassword() {
    if (this.form.invalid) {
      return;
    }
    this.loading = true;
    this.portalUserControllerService.changePasswordForUser({
      newPassword: this.form.value,
      username: this.username,
      notificationChannel: this.notificationChannel
    }).subscribe(value => {
      this.loading = false;
      this.toastrService.success('User password changed successfully')
      this.onPasswordChanged.emit(value);
      this.bsModalRef.hide();
    }, error => {
      this.loading = false;
      if (error.error && typeof error.error == 'object' && error.error.message) {
        this.toastrService.error(error.error.message);
        return;
      }
      this.toastrService.error('Could not change app user. Please check your internet connection.')
    });
  }
}
