import {Component} from '@angular/core';
import {NgClass} from "@angular/common";
import {CustomizerSettingsService} from "../../../customizer-settings/customizer-settings.service";
import {OrganizationFormComponent} from "./organization-form/organization-form.component";
import {TariffFormComponent} from "./tariff-form/tariff-form.component";
import {OrganizationSummaryComponent} from "./organization-summary/organization-summary.component";
import {NewOrganizationDto} from "../../../../../sdk/tickets-sdk";

@Component({
    selector: 'app-organization-creation',
    standalone: true,
    templateUrl: './organization-creation.component.html',
    imports: [
        NgClass,
        OrganizationFormComponent,
        TariffFormComponent,
        OrganizationSummaryComponent
    ],
    styleUrl: './organization-creation.component.scss'
})
export class OrganizationCreationComponent {

    tabHeaders: TabItem[] = [
        {name: 'Scheme Details', icon: 'for_you', description: 'Enter the scheme details'},
        {name: 'Tariff Information', icon: 'money', description: 'Upload tariff information'},
        {name: 'Summary', icon: 'summarize', description: 'Scheme details'},
        {name: 'Success', icon: 'verified', description: 'Scheme creation success!!'}];

    step = 1;
    data: NewOrganizationDto;

    locationData: { lgaName: string; stateName: string; countryName: string };

    constructor(public themeService: CustomizerSettingsService) {
    }

    goToNext(data: NewOrganizationDto): void {
        this.data = data;
        this.step++
    }

    goToPreviousStep($event: any) {
        this.data = $event;
        this.step--
    }

    getLocation($event: { lgaName: string; stateName: string; countryName: string }) {
        this.locationData = $event;
    }
}

export interface TabItem {
    name: string;
    icon: string;
    description: string;
}
