/**
 * Tickets API
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PortalAccountTypeRole } from './portal-account-type-role';
import { PortalUser } from './portal-user';


export interface RolePermission { 
    id?: number;
    createdAt?: string;
    lastUpdatedAt?: string;
    createdBy?: PortalUser;
    lastUpdatedBy?: PortalUser;
    status?: RolePermission.StatusEnum;
    dateDeactivated?: string;
    deactivatedBy?: PortalUser;
    name?: RolePermission.NameEnum;
    portalAccountTypeRole?: PortalAccountTypeRole;
}
export namespace RolePermission {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE';
    export const StatusEnum = {
        ACTIVE: 'ACTIVE' as StatusEnum,
        INACTIVE: 'INACTIVE' as StatusEnum
    };
    export type NameEnum = 'CREATE_ORGANIZATION' | 'CREATE_USER' | 'REVIEW_SCHEME' | 'CREATE_AGENT';
    export const NameEnum = {
        CREATE_ORGANIZATION: 'CREATE_ORGANIZATION' as NameEnum,
        CREATE_USER: 'CREATE_USER' as NameEnum,
        REVIEW_SCHEME: 'REVIEW_SCHEME' as NameEnum,
        CREATE_AGENT: 'CREATE_AGENT' as NameEnum
    };
}


