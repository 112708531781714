<div
    class="recent-transactions-card trezo-card mb-25 border-radius d-block bg-white border-0 shadow-none"
    [class.component-rtl-enabled]="themeService.isRTLEnabled()"
>
    <div class="trezo-card-header d-flex align-items-center justify-content-between">
        <div class="trezo-card-title">
            <h5 class="mb-0">
                Recent Transactions
            </h5>
        </div>
        <div class="trezo-card-subtitle">
            <div class="trezo-card-header-menu position-relative">
                <button class="dot-menu p-0 border-0 bg-transparent d-inline-block transition lh-1" (click)="toggleCardHeaderMenu()">
                    <i class="ri-more-fill"></i>
                </button>
                <ul class="dropdown-menu border-radius border-0 show" *ngIf="isCardHeaderOpen">
                    <li>
                        <button type="button" class="dropdown-item d-block w-100 position-relative transition">
                            This Day
                        </button>
                    </li>
                    <li>
                        <button type="button" class="dropdown-item d-block w-100 position-relative transition">
                            This Week
                        </button>
                    </li>
                    <li>
                        <button type="button" class="dropdown-item d-block w-100 position-relative transition">
                            This Month
                        </button>
                    </li>
                    <li>
                        <button type="button" class="dropdown-item d-block w-100 position-relative transition">
                            This Year
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="trezo-card-content">
        <ul class="p-0 mt-0 mb-0 list-unstyled">
            <li class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                    <div class="icon text-primary bg-primary-100 rounded-circle d-flex align-items-center justify-content-center">
                        <i class="material-symbols-outlined">
                            credit_card
                        </i>
                    </div>
                    <div>
                        <span class="title d-block fw-medium text-black">
                            Master Card
                        </span>
                        <span class="date d-block">
                            16 Jun 2024 - 7:12 pm
                        </span>
                    </div>
                </div>
                <span class="d-block text-success">
                    +1,520
                </span>
            </li>
            <li class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                    <div class="icon text-danger bg-danger-100 rounded-circle d-flex align-items-center justify-content-center">
                        <i class="material-symbols-outlined">
                            redeem
                        </i>
                    </div>
                    <div>
                        <span class="title d-block fw-medium text-black">
                            Paypal
                        </span>
                        <span class="date d-block">
                            15 Jun 2024 - 1:42 am
                        </span>
                    </div>
                </div>
                <span class="d-block text-danger">
                    -2,250
                </span>
            </li>
            <li class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                    <div class="icon text-purple bg-purple-100 rounded-circle d-flex align-items-center justify-content-center">
                        <i class="material-symbols-outlined">
                            account_balance
                        </i>
                    </div>
                    <div>
                        <span class="title d-block fw-medium text-black">
                            Wise
                        </span>
                        <span class="date d-block">
                            14 Jun 2024 - 4:21 pm
                        </span>
                    </div>
                </div>
                <span class="d-block text-success">
                    +3,560
                </span>
            </li>
            <li class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                    <div class="icon text-secondary bg-secondary-100 rounded-circle d-flex align-items-center justify-content-center">
                        <i class="material-symbols-outlined">
                            currency_ruble
                        </i>
                    </div>
                    <div>
                        <span class="title d-block fw-medium text-black">
                            Payoneer
                        </span>
                        <span class="date d-block">
                            13 Jun 2024 - 2:42 am
                        </span>
                    </div>
                </div>
                <span class="d-block text-success">
                    +6,500
                </span>
            </li>
            <li class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                    <div class="icon text-success bg-success-100 rounded-circle d-flex align-items-center justify-content-center">
                        <i class="material-symbols-outlined">
                            credit_score
                        </i>
                    </div>
                    <div>
                        <span class="title d-block fw-medium text-black">
                            Credit Card
                        </span>
                        <span class="date d-block">
                            12 Jun 2024 - 3:20 pm
                        </span>
                    </div>
                </div>
                <span class="d-block text-danger">
                    -4,320
                </span>
            </li>
        </ul>
    </div>
</div>