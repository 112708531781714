<div
    class="cta-area ptb-150"
    [class.component-rtl-enabled]="themeService.isRTLEnabled()"
>
    <div class="container position-relative z-1">
        <div class="cta-content text-center mx-auto">
            <h2>
                Unlock a world of possibilities with Trezo Dashboard.
            </h2>
            <p>
                Experience the difference with Trezo Dashboard. Sign up for a free trial today and see how our intuitive platform can revolutionize your data analysis process.
            </p>
            <a routerLink="/dashboard" class="btn-box d-inline-block fw-medium border-radius transition">
                <span class="d-inline-block position-relative">
                    <i class="material-symbols-outlined">
                        person
                    </i>
                    Get started - It is free
                </span>
            </a>
        </div>
        <div class="shape1">
            <img src="images/front-pages/shape1.png" alt="shape1">
        </div>
        <div class="shape2">
            <img src="images/front-pages/shape2.png" alt="shape2">
        </div>
    </div>
</div>