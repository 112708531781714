<div class=" m-0">
    <div class="modal-header">
        <div class="container-fluid">
            <div class="d-flex align-items-center">
                <div class="pull-left flex-fill">
                    <h6 class="modal-title" id="modal-title">{{ 'Generate Bill' }}</h6>
                </div>
                <button (click)="close()" class="close pull-right" type="button">
                    <span aria-hidden="true" class="fa fa-times modal-title"></span>
                </button>
            </div>

        </div>

    </div>
    <div class="modal-body">
        <div class="container-fluid">
            <div style="margin: 0 auto 10px;">
                <div class="filter-bg mt-1">
                    <form class="row g-3" id="agent-form" [formGroup]="form">
                        <div class="custom-card">
                            <div class="row g-3">
                                @if (isPortalAdmin) {
                                <div class="col-md-6 form-group">
                                    <label for="scheme" class="form-label required"> Scheme</label>
                                    <select id="scheme" [formControlName]="'organizationId'" class="form-select form-control form-select-sm">
                                        @if (fetchingOrganization) {
                                            <option [value]="''">Fetching Scheme</option>
                                        }
                                        @if (!fetchingOrganization && organizations?.length == 0) {
                                            <option [value]="''">No Scheme found</option>
                                        }
                                        @if (!fetchingOrganization && organizations?.length) {
                                            @if (organizations?.length || 0 > 0) {
                                                <option  [value]="''">Select a Scheme</option>
                                            }
                                            @for (p of organizations; track p) {
                                                <option [value]="p.id">{{p.name}}</option>
                                            }
                                        }
                                    </select>
                                    <error-message [form]="form" [controlName]="'organizationId'"/>
                                </div>
                                }
                                @if (isPortalAdmin || isOrganizationAdmin) {
                                    <div class="col-md-6 form-group">
                                        <label for="zone" class="form-label required"> Agent</label>
                                        <select id="zone" [formControlName]="'agentId'" class="form-select form-control form-select-sm">
                                            @if (fetchingAgent) {
                                                <option [value]="''">Fetching Agents</option>
                                            }

                                            @if (!fetchingAgent && !organizationId && isPortalAdmin) {
                                                <option [value]="''">No Scheme Selected</option>
                                            }

                                            @if (!fetchingAgent  && agents?.length == 0) {
                                                <option [value]="''">No Agent found</option>
                                            }

                                            @if (!fetchingAgent && agents?.length) {
                                                @if (agents?.length||0 > 0) {
                                                    <option [value]="''">Select an Agent</option>
                                                }
                                                @for (p of agents; track p) {
                                                    <option [value]="p.id">{{p.portalUser.displayName}}</option>
                                                }
                                            }
                                        </select>
                                        <error-message [form]="form" [controlName]="'agentId'"/>
                                    </div>
                                }
                                <div class="col-md-4 ps-3 text-end">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-5 form-group mb-0">
                                <label class="required">Ticket Type</label>
                                <select class="form-control" [formControlName]="'ticketType'" id="ticketType">
                                    @if (fetchingTickets) {
                                        <option [value]="''">Fetching Tickets</option>
                                    }

                                    @if (!fetchingTickets && !organizationId && isPortalAdmin) {
                                        <option [value]="''">No Scheme Selected</option>
                                    }

                                    @if (!fetchingTickets && organizationId && !agentId && (isOrganizationAdmin || isPortalAdmin)) {    `
                                        <option [value]="''">No Agent Selected</option>
                                    }

                                    @if (!fetchingTickets && agentTicketsConfig?.length == 0) {
                                        <option [value]="''">No Ticket Found</option>
                                    }

                                    @if (!fetchingTickets && agentTicketsConfig?.length) {
                                        <option [value]="''">Select Ticket</option>
                                        @for (ticket of agentTicketsConfig; track ticket) {
                                            <option [value]="ticket.ticketId">{{ticket.name | titlecase}}</option>
                                        }
                                    }
                                </select>
                                <error-message [form]="form" [controlName]="'ticketType'"/>
                            </div>
                            <div class="col-md-5 form-group">
                                <label class="required">Number of Batches</label>
                                <input [formControlName]="'numberOfBatches'" class="form-control" id="username"
                                       placeholder="Number of Batches" type="number"/>
                                <error-message [form]="form" [controlName]="'numberOfBatches'"/>
                            </div>
                            <div class="text-right col-md-2 mt-0 mt-md-4 mb-n2">
                                <label class=""> </label>
                                <button class="btn btn-outline-info" type="button" (click)="addBatch()"><i class="fa fa-add"></i>Add</button>
                            </div>
                        </div>
                        <div class="mt-3">
                            <hr>
                            <h6 class="modal-title">{{ 'Ticket Batches' }}</h6>
                            <h6 class="mt-2"> {{ 'Total Price:' + totalPrice }}</h6>
                        </div>
                        <div class="col-md-12 form-group table-responsive">
                            <table class="table table-hover table-bordered table-sm">
                                <thead>
                                <tr class="">
                                    <th scope="col">#</th>
                                    <th scope="col">Ticket Type</th>
                                    <th scope="col">Unit Price</th>
                                    <th scope="col">Tickets per batch</th>
                                    <th scope="col">Price</th>
                                    <th scope="col" style="max-width: 120px">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                @if (selectedTickets.length < 1) {
                                    <tr>
                                        <td colspan="8" class="my-5 text-center">
                                            NO DATA
                                        </td>
                                    </tr>
                                }

                                @for (d of selectedTickets; track d; let i = $index) {
                                    <tr>
                                        <th scope="row">{{i + 1}}</th>
                                        <td>{{d.name}} ( {{d.numberOfTicketsInBatch}} batches )</td>
                                        <td> {{ d.unitPrice | currency: '₦'}}</td>
                                        <td>{{defaultTicketsInBatch}}</td>
                                        <td> {{ getPricePerRow(d.unitPrice, d.numberOfTicketsInBatch) }}</td>
                                        <td>
                                            <i class="fa fa-trash pointer text-danger" (click)="removeSelectedTicket(i)"></i>
                                        </td>
                                    </tr>
                                }

                                </tbody>
                            </table>
                        </div>

                    </form>
                </div>

            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="container-fluid text-end">
            <button (click)="close()" class="btn btn-outline-danger mr-2" type="button">
                <i class="fa fa-times mr-1 fa-spinner "></i>Close
            </button>
            <button class="btn btn-success" (click)="generateBill()" [disabled]="loading">
                <i class="fa " [ngClass]="{'fa-spinner fa-spin':loading,'fa-check':!loading}"></i>
                {{'Generate Bill'}}
            </button>
        </div>
    </div>
</div>
