<div class="toast-container show transition position-fixed bottom-0 end-0 p-3">
    <div class="toast" role="alert">
        <div class="toast-header">
            <strong class="me-auto">{{ toast.title }}</strong>
            @if (toast.subtitle) {
                <small>{{ toast.subtitle }}</small>
            }
            <button type="button" class="btn-close shadow-none" aria-label="Close"></button>
        </div>
        <div class="toast-body">
            {{ toast.message }}
        </div>
    </div>
</div>
