import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
    NewOrganizationDto,
    NewTariffItem
} from "../../../../../../sdk/tickets-sdk";
import {ToastrModule,} from "ngx-toastr";
import * as XLSX from 'xlsx';
import {CurrencyPipe, NgClass, TitleCasePipe} from "@angular/common";
import {PopoverDirective} from "ngx-bootstrap/popover";
import {ToasterService} from "../../../../shared/components/toaster/toaster.service";


@Component({
    selector: 'tariff-form',
    standalone: true,
    imports: [
        NgClass,
        PopoverDirective,
        TitleCasePipe,
        CurrencyPipe,
        ToastrModule
    ],
    templateUrl: './tariff-form.component.html',
    styleUrl: './tariff-form.component.scss'
})
export class TariffFormComponent implements OnInit {

    countryId: any;
    @Input()
    data!: NewOrganizationDto | undefined;
    tariffData: Array<Array<any>> | undefined;
    @Output()
    gotoNextStep = new EventEmitter<NewOrganizationDto>();
    @Output()
    goToPreviousStep = new EventEmitter<any>();
    private tariffHasError = false;
    zoneData: Array<Array<any>> | undefined;
    zoneHasError: Boolean = false;

    constructor(
        private toasterService: ToasterService
    ) {
    }

    ngOnInit(): void {
        if (this.data && this.data!?.tariffs) {
            this.tariffData = [...this.data!.tariffs!.map((value: NewTariffItem) => [value.ticketType, value.agentBuyingPricePerUnit, value.agentSellingPricePerUnit, []])];
        }
        if (this.data && this.data!?.zones) {
            this.zoneData = [...this.data!.zones!.map((value: string) => [value, []])];
        }
    }

    submit() {
        if (!this.data) {
            return;
        }

        if ((this.tariffData?.length || 0) < 1) {
            console.log('tariffData', this.tariffData)
            this.toasterService.info('Please upload tariff information to proceed.', 'Setup Incomplete')
            return;
        }
        if (this.tariffHasError) {
            console.log('tariffHasError', this.tariffHasError)
            this.toasterService.info('Please correct all errors on uploaded tariff to proceed.', 'Setup Incomplete')
            return;
        }
        if ((this.zoneData?.length || 0) < 1) {
            console.log('zoneData', this.zoneData)
            this.toasterService.info('Please upload zone information to proceed.', 'Setup Incomplete')
            return;
        }
        if (this.zoneHasError) {
            console.log('zoneHasError', this.zoneHasError)
            this.toasterService.info('Please correct all errors on uploaded zones to proceed.', 'Setup Incomplete')
            return;
        }
        if (this.data) {
            this.data.tariffs = [];
            this.data.zones = [];
            this.data.tariffs = this.tariffData?.map(value => ({
                ticketType: value[0],
                agentBuyingPricePerUnit: value[1],
                agentSellingPricePerUnit: value[2]
            } as NewTariffItem)) || [];
            this.data.zones = this.zoneData?.map(value => value[0]) || [];
        }
        console.log(this.data)
        this.gotoNextStep.emit({...this.data});
    }

    onFileChange(evt: any) {
        /* wire up file reader */
        this.tariffData = [];
        this.zoneData = [];
        this.tariffHasError = false;
        this.zoneHasError = false;
        const target: DataTransfer = <DataTransfer>(evt.target);
        if (target.files.length !== 1) throw new Error('Cannot use multiple files');
        const reader: FileReader = new FileReader();
        reader.onload = (e: any) => {
            /* read workbook */
            const ab: ArrayBuffer = e.target.result;
            const wb: XLSX.WorkBook = XLSX.read(ab);

            /* grab first sheet */
            const wsname: string = wb.SheetNames[0];
            const ws: XLSX.WorkSheet = wb.Sheets[wsname];

            /* save data */
            let data: Array<Array<any>> = <any>(XLSX.utils.sheet_to_json(ws, {
                header: 1, range: 1,
                blankrows: false,
                rawNumbers: true,
                raw: true
            }));
            this.processTariff(data);
            /* grab first sheet */
            const zoneWorksheetName: string = wb.SheetNames[1];
            const zoneWorkSheet: XLSX.WorkSheet = wb.Sheets[zoneWorksheetName];

            /* save data */
            let zoneData: Array<Array<any>> = <any>(XLSX.utils.sheet_to_json(zoneWorkSheet, {
                header: 1, range: 1,
                blankrows: false,
                rawNumbers: true,
                raw: true
            }));
            this.processZones(zoneData);
        };
        reader.readAsArrayBuffer(target.files[0]);
    }

    private processTariff(data: Array<Array<any>>) {
        let allVehicleTypes = new Set();
        for (let i = 0; i < data.length; i++) {
            let vehicleType = data[i][0];
            let buying = Number.isNaN(parseFloat(data[i][1])) ? null : parseFloat(data[i][1]);
            let selling = Number.isNaN(parseFloat(data[i][2])) ? null : parseFloat(data[i][2]);
            let errors = [];
            if (allVehicleTypes.has(vehicleType)) {
                errors.push('Duplicate vehicle type found');
            } else {
                if (vehicleType) {
                    allVehicleTypes.add(vehicleType);
                }
            }
            if (!vehicleType) {
                errors.push('Vehicle Type not Specified');
            }
            if (!buying) {
                errors.push('Buying Price not specified');
            }
            if (!selling) {
                errors.push('Selling Price not specified');
            }

            if ((selling) < (buying)) {
                errors.push('Selling price less than buying price');
            }
            if (!this.tariffHasError) {
                this.tariffHasError = errors?.length > 0;
            }
            data[i] = [vehicleType, buying, selling, errors];
        }
        this.tariffData = data;
    }

    previousStep() {
        this.goToPreviousStep.emit();
    }

    private processZones(data: Array<Array<any>>) {
        let allZones = new Set();
        for (let i = 0; i < data.length; i++) {
            let zone = data[i][0];
            let errors = [];
            if (allZones.has(zone)) {
                errors.push('Duplicate zone found');
            } else {
                allZones.add(zone);
            }
            if (zone.length < 1) {
                errors.push('Zone name is too short');
            }
            if (zone.length > 200) {
                errors.push('Character limit of 200 exceeded');
            }
            if (!this.zoneHasError) {
                this.zoneHasError = errors?.length > 0;
            }
            data[i] = [zone, errors];
        }
        this.zoneData = data;
        console.log(this.zoneData);
    }

    downloadTemplateSheet(): void {
        const link = document.createElement('a');
        link.href = 'tariff_sheet.xlsx';
        link.download = 'tariff_sheet.xlsx';
        link.click();
    }
}
