<div class=" m-0">
    <div class="modal-header">
        <div class="pull-left">
            <h6 class="modal-title" id="modal-title">Crop Image</h6>
        </div>
        <button (click)="close()" class="close pull-right" type="button">
            <span aria-hidden="true" class="fa fa-times modal-title"></span>
        </button>
    </div>
    <div class="modal-body">
        <image-cropper
                [imageChangedEvent]="imageChangedEvent"
                [maintainAspectRatio]="true"
                [aspectRatio]="aspectRatio"
                format="png" [cropperMaxWidth]="maxWidth" [cropperMaxHeight]="maxHeight"
                (imageCropped)="imageCropped($event)" [backgroundColor]="'#000000'"
                (imageLoaded)="imageLoaded($event)"
                (cropperReady)="cropperReady()" [imageFile]="imageFile"
                (loadImageFailed)="loadImageFailed()"
        ></image-cropper>
        <div>
            <hr/>
        </div>
        <img [src]="croppedImage"  class="img-fluid" />
    </div>
    <div class="modal-footer">
        <button (click)="close()" class="btn btn-outline-danger" type="button">
            <i class="fa fa-times mr-1 fa-spinner "></i
            >Close
        </button>
        <button (click)="confirm()" class="btn btn-success" type="button">
            <i class="fa fa-crop"></i>
            Crop
        </button>
    </div>
</div>
