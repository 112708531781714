import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  Validators
} from "@angular/forms";
import {
  AgentControllerService,
  AgentRegistrationDto, DropDownListControllerService,
  Organization,
  OrganizationControllerService, PortalUserControllerService,
  TicketTypeDto,
  Zone
} from 'sdk/tickets-sdk';
import {User} from "../../../models/user/user.model";
import {BsModalRef} from "ngx-bootstrap/modal";
import {CustomValidators} from "../../../shared/validators/custom-validators";
import {catchError, debounceTime, map} from "rxjs/operators";
import {Observable, of, take} from "rxjs";
import {HttpErrorResponse} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {AuthenticationService} from "../../../services/authentication.service";
import {EmComponent} from "../../../shared/components/em/em.component";
import {AgentTicketConfigComponent} from "../agent-ticket-config/agent-ticket-config.component";

@Component({
  selector: 'app-agent-creation-modal',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    EmComponent,
    AgentTicketConfigComponent
  ],
  templateUrl: './agent-creation-modal.component.html',
  styleUrl: './agent-creation-modal.component.scss'
})
export class AgentCreationModalComponent  implements OnInit {

  onConfirmClicked = new EventEmitter();
  loading = false;
  @Input() dismissOnConfirm = true;
  @Input() showLoadingOnConfirmClick = true;

  form!: FormGroup;
  zones: Array<Zone>;
  fetchingZone: boolean;
  fetchingOrganization: boolean;
  fetchingTickets: boolean;
  organizations: Array<Organization>;
  organizationId: number;
  agentTicketsConfig: Array<TicketTypeDto>;
  isPortalAdmin: boolean;
  user: User;
  setReadOnly: boolean;

  constructor(
      public modalRef: BsModalRef,
      private fb: FormBuilder,
      private agentControllerService: AgentControllerService,
      private toastrService: ToastrService,
      private organizationControllerService: OrganizationControllerService,
      private dropDownListControllerService: DropDownListControllerService,
      private authenticationService: AuthenticationService,
      private portalUserControllerService: PortalUserControllerService
  ) { }

  ngOnInit(): void {
    this.authenticationService.getAccount().subscribe(account => {
      if (account.accountType == 'ADMIN') {
        this.isPortalAdmin = true;
        this.fetchOrganization();
      } else {
        this.isPortalAdmin = false;
        this.organizationId = account.organization.id
        this.fetchZones()
        this.fetchTickets();
      }
    });
    this.form = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      mobileNumber: ['', [Validators.required]],
      email: ['', [Validators.required], [this.agentEmailValidator()]],
      zoneCode: ['', [Validators.required]],
      organizationId: ['', Validators.required]
    });
    this.authenticationService.getUser().subscribe(u => {
      this.user = u;
    })

    if (!this.isPortalAdmin) {
      this.form.controls.organizationId.disable();
    }
    this.form.controls.organizationId.valueChanges.subscribe( v => {
      if (v) {
        this.organizationId = v;
        this.fetchZones()
        this.fetchTickets();
      } else {
        this.organizationId = undefined;
        this.agentTicketsConfig = [];
        this.zones = undefined;
      }
      this.form.controls.email.updateValueAndValidity({onlySelf: true, emitEvent: true})
    })
    this.readValueChanges(...['email', 'firstName', 'lastName', 'mobileNumber']);
  }

  readValueChanges(...controlNames: Array<string>) {
    if (controlNames.length > 0) {
      controlNames.map(controlName => {
        this.form.get(controlName).valueChanges.subscribe((v) => {
          if (!v.trim()) {
            this.form.get(controlName).setValue(v.trim(), {emitEvent: false})
            // this.form.get(controlName).updateValueAndValidity();
          }
        })
      })

    }
  }

  fetchTickets() {
    this.fetchingTickets = true;
    this.dropDownListControllerService.getTicketTypes({orgId: this.organizationId})
        .pipe((map((r) => {
          this.agentTicketsConfig = r.results.map((v) => {
            return {
              buyingPricePerUnit: v.buyingPricePerUnit,
              ticketId: v.id,
              defaultBuyingPricePerUnit: v.buyingPricePerUnit,
              name: v.name,
              defaultSellingPricePerUnit: v.sellingPricePerUnit
            }
          });
          return r;
        })))
        .subscribe( v => {
          this.fetchingTickets = false;
        }, (error: HttpErrorResponse) => {
          this.fetchingTickets = false;
          // this.toastrService.error(error.message ? error.message : 'Error fetching Scheme')
        })
  }

  fetchZones() {
    this.fetchingZone = true;
    this.dropDownListControllerService.getZones({orgId: this.organizationId}).subscribe( v => {
      this.fetchingZone = false;
      this.zones = v.results;
    }, (error: HttpErrorResponse) => {
      this.fetchingZone = false;
      // this.toastrService.error(error.message ? error.message : 'Error fetching Zones')
    })
  }

  fetchOrganization() {
    this.fetchingOrganization = true
    this.organizationControllerService.searchOrganizations({approvalStatus: 'APPROVED'}).subscribe( v => {
      this.fetchingOrganization = false;
      this.organizations = v.results;
    }, (error: HttpErrorResponse) => {
      this.fetchingOrganization = false;
      this.toastrService.error((error.error && typeof error.error == 'object' && error.message)
          ? error.error.message : 'Error fetching Organisation')
    });
  }


  onConfirm: any = () => {
  };

  submit() {
    this.form.updateValueAndValidity();
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.loading = true;
      const payload: AgentRegistrationDto = {
        email: this.form.controls.email.value.trim(),
        firstName: this.form.controls.firstName.value.trim(),
        lastName: this.form.controls.lastName.value.trim(),
        mobileNumber: this.form.controls.mobileNumber.value.trim(),
        ticketTypes: this.agentTicketsConfig,
        zoneCode: this.form.controls.zoneCode.value,
        username: this.form.controls.email.value.trim()
      }
      if (this.isPortalAdmin) {
        payload.organizationId = this.organizationId;
      }
      this.agentControllerService.registerAgent({agentRegistrationDto: payload}).subscribe( res => {
        this.loading = false;
        this.toastrService.success('Agent created successfully!')
        this.onConfirmClicked.emit();
        this.modalRef.hide();
      }, (error: HttpErrorResponse) => {
        this.loading = false;
        this.toastrService.error((error.error && typeof error.error == 'object' && error.message)
            ? error.error.message : 'Error creating agent')
      });
    }
  }

  close() {
    this.modalRef.hide();
  }

  private agentEmailValidator() {
    return  (control: AbstractControl): Observable<ValidationErrors> => {
      if(!control.value){
        return of(null);
      }
      if (!this.organizationId) {
        return of({'noOrgId': true});
      }
      return  this.agentControllerService.uniqueAgentEmail({email: control.value.trim(), orgId: this.organizationId},)
          .pipe(map(value => {
                    this.setReadOnly = false;
                    if (value.code == 200) {
                      if (value.data) {
                        this.form.patchValue({firstName: value.data.firstName, lastName: value.data.lastName, mobileNumber: value.data.phoneNumber}, {onlySelf: true, emitEvent: false});
                        this.setReadOnly = true;
                        this.form.get('mobileNumber').clearValidators()
                        this.form.get('mobileNumber').clearAsyncValidators()
                        this.form.get('mobileNumber').updateValueAndValidity();
                      } else {
                        // this.form.patchValue({firstName: '', lastName: '', mobileNumber: ''}, {onlySelf: true, emitEvent: false});
                        this.form.get('mobileNumber').addValidators([Validators.required]);
                        this.form.get('mobileNumber').updateValueAndValidity();
                      }
                      return null;
                    }
                    if (value.code == 404) {
                      return {'organizationNotFound':true};
                    }
                    return {'memberExist':true};
                  }
              ),
              debounceTime(500),
              take(1),
              catchError((err: HttpErrorResponse, caught) => {
                return of({'nointernet': true});
              })
          );
    }
  }

  protected readonly confirm = confirm;
}
