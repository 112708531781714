<div class="row">
    <div class="col-lg-8">

        <!-- Welcome -->
        <app-welcome />

        <!-- Total Sales -->
        <app-total-sales />

    </div>
    <div class="col-lg-4">
        <div class="row">
            <div class="col-lg-12 col-md-4">

                <!-- Total Order -->
                <app-total-orders />

            </div>
            <div class="col-lg-12 col-md-4">

                <!-- Total Customers -->
                <app-total-customers />

            </div>
            <div class="col-lg-12 col-md-4">

                <!-- Total Revenue -->
                <app-total-revenue />

            </div>
        </div>
    </div>
    <div class="col-lg-5">

        <!-- Sales by Locations -->
        <app-sales-by-locations />

    </div>
    <div class="col-lg-7">

        <!-- Top Selling Products -->
        <app-top-selling-products />
        
    </div>
    <div class="col-lg-8">

        <!-- Recent Orders -->
        <app-recent-orders />
        
    </div>
    <div class="col-lg-4">

        <!-- Order Summary -->
        <app-order-summary />

    </div>
    <div class="col-lg-4">

        <!-- Recent Transactions -->
        <app-recent-transactions />

    </div>
    <div class="col-lg-8">

        <!-- Returning Customer Rate -->
        <app-returning-customer-rate />
        
    </div>
</div>