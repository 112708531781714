<div class="key-features-area position-relative z-1 pt-150 pb-120">
    <div class="container">
        <div class="section-title text-center mx-auto">
            <div class="sub-title d-inline-block position-relative">
                <span class="d-inline-block position-relative text-purple">
                    Key Features
                </span>
            </div>
            <h2 class="mb-0">
                Discover What Sets Us Apart: Highlighted Dashboard Functions
            </h2>
        </div>
        <div class="row justify-content-center">
            <div class="col-sm-6 col-lg-4">
                <div class="single-key-feature-box">
                    <div class="icon d-flex align-items-center justify-content-center">
                        <img src="images/front-pages/stacks.svg" alt="stacks">
                    </div>
                    <h3 class="fw-semibold">
                        Real-Time Updates
                    </h3>
                    <p>
                        Provide real-time updates and notifications to keep users informed about important events, changes, or updates within the system.
                    </p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="single-key-feature-box">
                    <div class="icon d-flex align-items-center justify-content-center">
                        <img src="images/front-pages/code.svg" alt="code">
                    </div>
                    <h3 class="fw-semibold">
                        Quality Code
                    </h3>
                    <p>
                        These features include adherence to coding standards, robust error handling mechanisms, efficient algorithms, scalability, maintainability, and readability.
                    </p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="single-key-feature-box">
                    <div class="icon d-flex align-items-center justify-content-center">
                        <img src="images/front-pages/support_agent.svg" alt="support_agent">
                    </div>
                    <h3 class="fw-semibold">
                        24/7 Customer Support
                    </h3>
                    <p>
                        Our 24/7 customer support is dedicated to providing round-the-clock assistance, ensuring that help is always available whenever our customers need it.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>