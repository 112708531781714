/**
 * Tickets API
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { QueryResultsPortalAccount } from '../model/query-results-portal-account';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface GetRolesForAccountRequestParams {
    accountCode: string;
}

export interface SearchPortalAccountsRequestParams {
    approvalStatus?: 'APPROVED' | 'PENDING' | 'DISAPPROVED';
    lastUpdatedBy?: string;
    code?: string;
    address?: string;
    lgaId?: number;
    organizationName?: string;
    portalAccountMembershipCollection?: string;
    lastUpdatedAt?: string;
    stateId?: number;
    lgaCode?: string;
    type?: 'ADMIN' | 'ORGANIZATION';
    countryId?: number;
    deactivatedBy?: string;
    accountPhoneNumber?: string;
    organizationType?: 'LOCAL_GOVERNMENT' | 'FEDERAL_GOVERNMENT' | 'STATE_GOVERNMENT' | 'OTHER';
    createdAt?: string;
    accountEmail?: string;
    reasonForDisapproval?: string;
    createdBy?: string;
    organization?: string;
    name?: string;
    dateDeactivated?: string;
    id?: number;
    status?: 'ACTIVE' | 'INACTIVE';
    offset?: number;
    limit?: number;
    orderColumn?: string;
    order?: 'ASC' | 'DESC';
}


@Injectable({
  providedIn: 'root'
})
export class PortalAccountControllerService {

    protected basePath = 'http://tickets.serverna.com.ng/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
            if (firstBasePath != undefined) {
                basePath = firstBasePath;
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRolesForAccount(requestParameters?: GetRolesForAccountRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Array<string>>;
    public getRolesForAccount(requestParameters?: GetRolesForAccountRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<string>>>;
    public getRolesForAccount(requestParameters?: GetRolesForAccountRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<string>>>;
    public getRolesForAccount(requestParameters?: GetRolesForAccountRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const accountCode = requestParameters?.accountCode;
        if (accountCode === null || accountCode === undefined) {
            throw new Error('Required parameter accountCode was null or undefined when calling getRolesForAccount.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (ApiKey) required
        localVarCredential = this.configuration.lookupCredential('ApiKey');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else if (localVarHttpHeaderAcceptSelected.startsWith('blob')) {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/portal-accounts/${this.configuration.encodeParam({name: "accountCode", value: accountCode, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/roles`;
        return this.httpClient.request<Array<string>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchPortalAccounts(requestParameters?: SearchPortalAccountsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<QueryResultsPortalAccount>;
    public searchPortalAccounts(requestParameters?: SearchPortalAccountsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<QueryResultsPortalAccount>>;
    public searchPortalAccounts(requestParameters?: SearchPortalAccountsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<QueryResultsPortalAccount>>;
    public searchPortalAccounts(requestParameters?: SearchPortalAccountsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const approvalStatus = requestParameters?.approvalStatus;
        const lastUpdatedBy = requestParameters?.lastUpdatedBy;
        const code = requestParameters?.code;
        const address = requestParameters?.address;
        const lgaId = requestParameters?.lgaId;
        const organizationName = requestParameters?.organizationName;
        const portalAccountMembershipCollection = requestParameters?.portalAccountMembershipCollection;
        const lastUpdatedAt = requestParameters?.lastUpdatedAt;
        const stateId = requestParameters?.stateId;
        const lgaCode = requestParameters?.lgaCode;
        const type = requestParameters?.type;
        const countryId = requestParameters?.countryId;
        const deactivatedBy = requestParameters?.deactivatedBy;
        const accountPhoneNumber = requestParameters?.accountPhoneNumber;
        const organizationType = requestParameters?.organizationType;
        const createdAt = requestParameters?.createdAt;
        const accountEmail = requestParameters?.accountEmail;
        const reasonForDisapproval = requestParameters?.reasonForDisapproval;
        const createdBy = requestParameters?.createdBy;
        const organization = requestParameters?.organization;
        const name = requestParameters?.name;
        const dateDeactivated = requestParameters?.dateDeactivated;
        const id = requestParameters?.id;
        const status = requestParameters?.status;
        const offset = requestParameters?.offset;
        const limit = requestParameters?.limit;
        const orderColumn = requestParameters?.orderColumn;
        const order = requestParameters?.order;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (approvalStatus !== undefined && approvalStatus !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>approvalStatus, 'approvalStatus');
        }
        if (lastUpdatedBy !== undefined && lastUpdatedBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lastUpdatedBy, 'lastUpdatedBy');
        }
        if (code !== undefined && code !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>code, 'code');
        }
        if (address !== undefined && address !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>address, 'address');
        }
        if (lgaId !== undefined && lgaId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lgaId, 'lgaId');
        }
        if (organizationName !== undefined && organizationName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>organizationName, 'organizationName');
        }
        if (portalAccountMembershipCollection !== undefined && portalAccountMembershipCollection !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>portalAccountMembershipCollection, 'portalAccountMembershipCollection');
        }
        if (lastUpdatedAt !== undefined && lastUpdatedAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lastUpdatedAt, 'lastUpdatedAt');
        }
        if (stateId !== undefined && stateId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>stateId, 'stateId');
        }
        if (lgaCode !== undefined && lgaCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lgaCode, 'lgaCode');
        }
        if (type !== undefined && type !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>type, 'type');
        }
        if (countryId !== undefined && countryId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>countryId, 'countryId');
        }
        if (deactivatedBy !== undefined && deactivatedBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>deactivatedBy, 'deactivatedBy');
        }
        if (accountPhoneNumber !== undefined && accountPhoneNumber !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>accountPhoneNumber, 'accountPhoneNumber');
        }
        if (organizationType !== undefined && organizationType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>organizationType, 'organizationType');
        }
        if (createdAt !== undefined && createdAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>createdAt, 'createdAt');
        }
        if (accountEmail !== undefined && accountEmail !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>accountEmail, 'accountEmail');
        }
        if (reasonForDisapproval !== undefined && reasonForDisapproval !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>reasonForDisapproval, 'reasonForDisapproval');
        }
        if (createdBy !== undefined && createdBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>createdBy, 'createdBy');
        }
        if (organization !== undefined && organization !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>organization, 'organization');
        }
        if (name !== undefined && name !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>name, 'name');
        }
        if (dateDeactivated !== undefined && dateDeactivated !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dateDeactivated, 'dateDeactivated');
        }
        if (id !== undefined && id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>id, 'id');
        }
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }
        if (offset !== undefined && offset !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>offset, 'offset');
        }
        if (limit !== undefined && limit !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>limit, 'limit');
        }
        if (orderColumn !== undefined && orderColumn !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>orderColumn, 'orderColumn');
        }
        if (order !== undefined && order !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>order, 'order');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (ApiKey) required
        localVarCredential = this.configuration.lookupCredential('ApiKey');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else if (localVarHttpHeaderAcceptSelected.startsWith('blob')) {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/portal-accounts`;
        return this.httpClient.request<QueryResultsPortalAccount>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

}
