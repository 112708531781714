<div
    class="navbar-area position-fixed top-0 start-0 end-0 transition"
    [ngClass]="{'sticky': isSticky}"
    [class.component-dark-theme]="themeService.isDark()"
    [class.component-rtl-enabled]="themeService.isRTLEnabled()"
>
    <div class="container">
        <nav
            class="navbar navbar-expand-lg"
            [class.active]="classApplied"
        >
            <a class="navbar-brand" routerLink="/">
                <img src="images/logo-icon.svg" alt="logo">
                <span class="fw-bold text-black position-relative">
                SmartTickets
            </span>
            </a>
            <button
                class="navbar-toggler"
                type="button" (click)="toggleClass()"
            >
                <span class="burger-menu">
                    <span class="top-bar"></span>
                    <span class="middle-bar"></span>
                    <span class="bottom-bar"></span>
                </span>
            </button>
            <div class="collapse navbar-collapse">
                <div class="other-options d-flex align-items-center">
                    <a (click)="login()" class="btn-box d-inline-block fw-medium border-radius position-relative">
                        <span class="d-inline-block position-relative">
                            <i class="material-symbols-outlined">
                                login
                            </i>
                            Login
                        </span>
                    </a>
                </div>
            </div>
        </nav>
    </div>
</div>
