/**
 * Tickets API
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface NewTicketValidationDto { 
    vehicleNumber?: string;
    ticketNumber?: string;
    phoneNumber?: string;
    ticketTypeCode?: string;
    organizationId: number;
    validationType: NewTicketValidationDto.ValidationTypeEnum;
}
export namespace NewTicketValidationDto {
    export type ValidationTypeEnum = 'TICKET_NUMBER' | 'VEHICLE_NUMBER' | 'PHONE_NUMBER';
    export const ValidationTypeEnum = {
        TICKET_NUMBER: 'TICKET_NUMBER' as ValidationTypeEnum,
        VEHICLE_NUMBER: 'VEHICLE_NUMBER' as ValidationTypeEnum,
        PHONE_NUMBER: 'PHONE_NUMBER' as ValidationTypeEnum
    };
}


