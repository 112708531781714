/**
 * Tickets API
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TicketTypeDto } from './ticket-type-dto';


export interface AgentRegistrationDto { 
    email?: string;
    firstName?: string;
    lastName?: string;
    mobileNumber?: string;
    gender?: AgentRegistrationDto.GenderEnum;
    username?: string;
    password?: string;
    state?: string;
    lga?: string;
    locality?: string;
    accountType?: string;
    otpPin?: string;
    ticketTypes: Array<TicketTypeDto>;
    zoneCode: string;
    organizationId?: number;
}
export namespace AgentRegistrationDto {
    export type GenderEnum = 'MALE' | 'FEMALE' | 'UNSPECIFIED' | 'OTHER';
    export const GenderEnum = {
        MALE: 'MALE' as GenderEnum,
        FEMALE: 'FEMALE' as GenderEnum,
        UNSPECIFIED: 'UNSPECIFIED' as GenderEnum,
        OTHER: 'OTHER' as GenderEnum
    };
}


