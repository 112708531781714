import {Component, Input} from '@angular/core';
import {ToastModel} from "./toaster.service";

@Component({
  selector: 'toaster',
  standalone: true,
  imports: [],
  templateUrl: './toaster.component.html',
  styleUrl: './toaster.component.scss'
})
export class ToasterComponent {

  @Input()
  toast: ToastModel;
}
