import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {
  AgentControllerService,
  AgentPojo, BillControllerService, BillGenerationDto, DropDownListControllerService, LocationService,
  Organization, OrganizationControllerService, QueryResultsAgentPojo,
  TicketBatchDto, TicketSalesControllerService,
  TicketTypeDto, ZoneControllerService
} from "../../../../../sdk/tickets-sdk";
import {User} from "../../../models/user/user.model";
import {BsModalRef} from "ngx-bootstrap/modal";
import {ToastrService} from "ngx-toastr";
import {AuthenticationService} from "../../../services/authentication.service";
import {Router} from "@angular/router";
import {HttpErrorResponse} from "@angular/common/http";
import {CurrencyPipe, NgClass, TitleCasePipe} from "@angular/common";
import {EmComponent} from "../../../shared/components/em/em.component";

@Component({
  selector: 'app-bill-creation-modal',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TitleCasePipe,
    CurrencyPipe,
    NgClass,
    EmComponent
  ],
  templateUrl: './bill-creation-modal.component.html',
  styleUrl: './bill-creation-modal.component.scss'
})
export class BillCreationModalComponent implements OnInit {
  onConfirmClicked = new EventEmitter<string>();

  @Input() dismissOnConfirm = true;
  @Input() showLoadingOnConfirmClick = true;

  loading = false;
  form!: FormGroup;
  agents: Array<AgentPojo>;
  fetchingAgent: boolean;
  fetchingOrganization: boolean;
  fetchingTickets: boolean;
  organizations: Array<Organization>;
  organizationId: number;
  agentTicketsConfig: Array<TicketTypeDto>;
  isPortalAdmin: boolean;
  isOrganizationAdmin: boolean;
  user: User;
  selectedTickets: Array<TicketBatchDto> = [];
  agentId: number;
  defaultTicketsInBatch: number = 100;

  constructor(
      public modalRef: BsModalRef,
      private fb: FormBuilder,
      private toastrService: ToastrService,
      private organizationControllerService: OrganizationControllerService,
      private dropDownListControllerService: DropDownListControllerService,
      private authenticationService: AuthenticationService,
      private billControllerService: BillControllerService
  ) { }

  ngOnInit(): void {
    this.authenticationService.getAccount().subscribe(account => {
      if (account.accountType == 'ADMIN') {
        this.isPortalAdmin = true;
        this.fetchOrganization();
      } else if (account.accountType == 'ORGANIZATION' && account.roles.includes('ADMIN')) {
        this.isOrganizationAdmin = true;
        this.organizationId = account.organization.id
        this.fetchAgents();
      } else {
        this.isPortalAdmin = false;
        this.isOrganizationAdmin = false;
        this.organizationId = account.organization.id
        this.agentId = account?.agent?.id;
        this.fetchTickets();
      }
    });
    this.form = this.fb.group({
      ticketType: [''],
      agentId: ['', [Validators.required]],
      organizationId: ['', Validators.required],
      selectedTickets: [''],
      numberOfBatches: ['']
    });
    this.authenticationService.getUser().subscribe(u => {
      this.user = u;
    })
    if (!this.isPortalAdmin) {
      this.form.controls.organizationId.disable();
    }
    if (!this.isPortalAdmin && !this.isOrganizationAdmin) {
      this.form.controls.agentId.disable();
    }
    this.form.controls.organizationId.valueChanges.subscribe( v => {
      if (v) {
        this.organizationId = v;
        this.fetchAgents()
      } else {
        this.organizationId = undefined;
        this.agentTicketsConfig = [];
        this.agentId = undefined;
        this.agents = undefined;
        this.form.get('agentId').setValue('');
      }
    });
    this.form.controls.agentId.valueChanges.subscribe( v => {
      if (v) {
        this.agentId = v;
        this.fetchTickets();
      } else {
        this.agentTicketsConfig = [];
        this.agentId = undefined;
        this.form.get('ticketType').setValue('');
      }
      this.form.get('selectedTickets').setValue('');
      this.selectedTickets = [];
    });
  }

  fetchTickets() {
    this.fetchingTickets = true;
    this.dropDownListControllerService.getAgentTicketsConfig({orgId: this.organizationId, agentId: this.agentId})
        .subscribe( v => {
          this.agentTicketsConfig = v.results;
          this.fetchingTickets = false;
        }, (error: HttpErrorResponse) => {
          this.fetchingTickets = false;
          // this.toastrService.error(error.message ? error.message : 'Error fetching Scheme')
        })
  }

  fetchAgents() {
    this.fetchingAgent = true;
    this.dropDownListControllerService.getAgents({orgId: this.organizationId}).subscribe( (v: QueryResultsAgentPojo) => {
      this.fetchingAgent = false;
      this.agents = v.results;
    }, (error: HttpErrorResponse) => {
      this.fetchingAgent = false;
      // this.toastrService.error(error.message ? error.message : 'Error fetching agents')
    })
  }

  fetchOrganization() {
    this.fetchingOrganization = true
    this.organizationControllerService.searchOrganizations({approvalStatus: 'APPROVED'}).subscribe( v => {
      this.fetchingOrganization = false;
      this.organizations = v.results;
    }, (error: HttpErrorResponse) => {
      this.fetchingOrganization = false;
      this.toastrService.error((error.error && typeof error.error == 'object' && error.message)
          ? error.error.message : 'Error fetching Organisation')
    });
  }


  onConfirm: any = () => {
  };

  generateBill() {
    if (!this.form.get('organizationId').value && this.isPortalAdmin) {
      this.form.get('organizationId').markAsDirty();
      this.toastrService.error("Please, select an Organisation")
      return;
    }

    if (!this.form.get('agentId').value && (this.isPortalAdmin || this.isOrganizationAdmin)) {
      this.form.get('agentId').markAsDirty();
      this.toastrService.error("Please, select an Agent")
      return;
    }

    if (this.selectedTickets.length <1){
      this.toastrService.error("Please, add one ticket batch")
      return
    }

    // if (this.form.valid) {
    this.loading = true;
    const payload: BillGenerationDto = {
      organizationId: this.organizationId,
      agentId: this.agentId,
      ticketBatchDtos: this.selectedTickets
    }

    this.billControllerService.generateBill({billGenerationDto: payload}).subscribe( res => {
      this.loading = false;
      this.toastrService.success('Bill generated successfully!')
      this.onConfirmClicked.emit(res.billNumber);
      this.modalRef.hide();
    }, (error: HttpErrorResponse) => {
      this.loading = false;
      this.toastrService.error((error.error && typeof error.error == 'object' && error.message)
          ? error.error.message : 'Error generating bill')
    });
    // }
  }

  close() {
    this.modalRef.hide();
  }

  removeSelectedTicket(index: number) {
    let tickets = [...this.selectedTickets];
    let tempTicket = []
    for (let j = 0; j < tickets.length; j++) {
      if (j != index) {
        tempTicket.push(tickets[j])
      }
    }
    this.selectedTickets = [];
    this.selectedTickets.push(...tempTicket);
    this.form.get('selectedTickets').patchValue(this.selectedTickets)
  }

  addBatch() {

    if (this.validateForm()) {

      let ticketType = this.form.get('ticketType').value;
      let numberOfBatches = this.form.get('numberOfBatches').value;

      let selectedAgentTicketsConfig = this.agentTicketsConfig.find(t => t.ticketId == ticketType);
      let ticketsBatch: TicketBatchDto = {
        ticketTypeId: ticketType,
        numberOfTicketsInBatch: numberOfBatches,
        unitPrice: selectedAgentTicketsConfig.buyingPricePerUnit,
        name: selectedAgentTicketsConfig.name
      };

      if (this.hasTicket(ticketsBatch)) {
        this.toastrService.info('Duplicate ticket selected');
      } else {
        this.selectedTickets.push(ticketsBatch);
      }

      this.form.get('selectedTickets').patchValue(this.selectedTickets);

      this.form.get('ticketType').reset('');
      this.form.get('ticketType').setValidators(Validators.required);
      this.form.get('ticketType').markAsPristine();

      this.form.get('numberOfBatches').reset('');
      this.form.get('numberOfBatches').setValidators([Validators.required, Validators.maxLength(100)]);
      this.form.get('numberOfBatches').markAsPristine()
    }
  }

  validateForm() {
    let {ticketType, numberOfBatches, agentId, organizationId} = this.form.value;

    if (!ticketType || !numberOfBatches || (numberOfBatches > 100) || (numberOfBatches <= 0)
        || (!agentId && (this.isPortalAdmin || this.isOrganizationAdmin)) || (!organizationId && this.isPortalAdmin) || this.isFloat(numberOfBatches)) {
      if (!organizationId && this.isPortalAdmin) {
        this.form.get('organizationId').setErrors({required: true});
        this.form.get('organizationId').markAsDirty();
      }
      if (!agentId && (this.isPortalAdmin || this.isOrganizationAdmin)) {
        this.form.get('agentId').setErrors({required: true});
        this.form.get('agentId').markAsDirty();
      }
      if (!ticketType) {
        this.form.get('ticketType').setErrors({required: true});
        this.form.get('ticketType').markAsDirty();
      }
      if (!numberOfBatches) {
        if (typeof numberOfBatches == 'string' || numberOfBatches == null) {
          this.form.get('numberOfBatches').setErrors({required: true});
        } else {
          this.form.get('numberOfBatches').setErrors({notZero: true});
        }
        this.form.get('numberOfBatches').markAsDirty();
      } else if ((numberOfBatches > 100)) {
        this.form.get('numberOfBatches').setErrors({customMaxLength: '100'});
        this.form.get('numberOfBatches').markAsDirty();
      } else if ((numberOfBatches < 0)) {
        this.form.get('numberOfBatches').setErrors({lessThanOne: '1'});
        this.form.get('numberOfBatches').markAsDirty();
      } else if (this.isFloat(numberOfBatches)) {
        this.form.get('numberOfBatches').setErrors({invalidNumber: true});
        this.form.get('numberOfBatches').markAsDirty();
      }
      this.form.markAllAsTouched();
      this.form.markAsDirty()
      return false;
    }
    return true;
  }

  private isFloat(n) {
    return Number(n) === n && n % 1 !== 0;
  }

  private hasTicket(ticketNumber: TicketBatchDto) {
    if (this.selectedTickets.length > 0) {
      return this.selectedTickets.find(value => value.ticketTypeId == ticketNumber.ticketTypeId) != null;
    } else {
      return false;
    }
  }

  getPricePerRow(unitPrice: number, numberOfBatches: number): number {
    return (unitPrice * this.defaultTicketsInBatch) * numberOfBatches;
  }

  get totalPrice() {
    return this.selectedTickets.length > 0 ? this.selectedTickets.reduce((accum, current) => {
      return accum + ((current.unitPrice * current.numberOfTicketsInBatch) * this.defaultTicketsInBatch)
    }, 0) : 0;
  }
}
