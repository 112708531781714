<div
    class="order-summary-card trezo-card mb-25 border-radius d-block bg-white border-0 shadow-none"
    [class.component-rtl-enabled]="themeService.isRTLEnabled()"
>
    <div class="trezo-card-header d-flex align-items-center justify-content-between">
        <div class="trezo-card-title">
            <h5 class="mb-0">
                Order Summary
            </h5>
        </div>
        <div class="trezo-card-subtitle">
            <div class="trezo-card-header-menu position-relative">
                <button class="menu-btn transition d-inline-block bg-transparent border-radius" (click)="toggleCardHeaderMenu()">
                    <span class="d-block position-relative">
                        Today
                    </span>
                </button>
                <ul class="dropdown-menu border-radius border-0 show" *ngIf="isCardHeaderOpen">
                    <li>
                        <button type="button" class="dropdown-item d-block w-100 position-relative transition">
                            This Day
                        </button>
                    </li>
                    <li>
                        <button type="button" class="dropdown-item d-block w-100 position-relative transition">
                            This Week
                        </button>
                    </li>
                    <li>
                        <button type="button" class="dropdown-item d-block w-100 position-relative transition">
                            This Month
                        </button>
                    </li>
                    <li>
                        <button type="button" class="dropdown-item d-block w-100 position-relative transition">
                            This Year
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="trezo-card-content">
        <div id="ecommerce_order_summary_chart"></div>
        <ul class="mb-0 p-0 list-unstyled">
            <li>
                <span class="d-block fw-medium">
                    Completed Order
                </span>
                <div class="progressbar d-flex align-items-center">
                    <div class="progress trezo-progress completed-order w-100" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100">
                        <div class="progress-bar" style="width: 60%"></div>
                    </div>
                    <span class="d-block percentage">
                        60%
                    </span>
                </div>
            </li>
            <li>
                <span class="d-block fw-medium">
                    New Order
                </span>
                <div class="progressbar d-flex align-items-center">
                    <div class="progress trezo-progress new-order w-100" role="progressbar" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100">
                        <div class="progress-bar" style="width: 30%"></div>
                    </div>
                    <span class="d-block percentage">
                        30%
                    </span>
                </div>
            </li>
            <li>
                <span class="d-block fw-medium">
                    Pending Order
                </span>
                <div class="progressbar d-flex align-items-center">
                    <div class="progress trezo-progress pending-order w-100" role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100">
                        <div class="progress-bar" style="width: 10%"></div>
                    </div>
                    <span class="d-block percentage">
                        10%
                    </span>
                </div>
            </li>
        </ul>
    </div>
</div>