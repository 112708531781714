<div class="product-development-card trezo-card mb-25 border-radius d-block bg-white border-0 shadow-none">
    <div class="trezo-card-header d-flex align-items-center justify-content-between">
        <div class="trezo-card-title">
            <h5 class="mb-0">
                Agent cost per ticket
            </h5>
        </div>
    </div>

    <div class="trezo-card-content">
        <div class="basic-table table-responsive mt-3">
            <table class="table mb-2">
                <thead>
                <tr class="">
                    <th scope="col">#</th>
                    <th scope="col">Ticket Type</th>
                    <th scope="col">Selling Price (₦)</th>
                    <th scope="col">Buying Price (₦)</th>
                </tr>
                </thead>
                <tbody>
                    @if (fetchingTickets) {
                        <tr>
                            <td colspan="8" class="my-5">
                                <div class="spinner-border spinner-border-sm" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </td>
                        </tr>
                    }
                    @if (!fetchingTickets && (!agentTicketsConfigSearch || agentTicketsConfigSearch?.length < 1)) {
                        <tr>
                            <td colspan="8" class="my-5 text-center">
                                NO DATA
                            </td>
                        </tr>
                    }
                @for (d of agentTicketsConfigSearch; track d; let i = $index) {

                    <tr>
                        <th scope="row">{{ i+1 }}</th>
                        <td>{{d?.name}}</td>
                        <td>{{d?.defaultSellingPricePerUnit | currency: '₦'}}</td>
                        <td>
                            <div class="d-flex justify-content-between">

                                @if (activeIndex != i) {
                                    <span>{{d?.buyingPricePerUnit | currency: '₦'}}</span>
                                }

                                @if (isTicketCostEdit && activeIndex == i) {
                                    <input [id]="'ticket-cost'+i"
                                           [type]="'number'"
                                           [step]=".01"
                                           (keyup)="setTicketAmount($event, i)"
                                           [(ngModel)]="ticketPrice"
                                           [pattern]="'^\d+(?:\.\d{1,2})?$'">
                                }

                                @if (hasTicketConfigError && isTicketCostEdit && activeIndex == i) {
                                    <small class="text-danger font-weight-bold">{{ticketConfigErrorMessage}}</small>
                                }

                                @if (activeIndex != i) {
                                    <span class="text-success pointer" (click)="editTicket(i)">
                                        <i class="fa fa-edit"></i>
                                        <span>Edit</span>
                                    </span>
                                }

                                @if (isTicketCostEdit && activeIndex == i) {
                                    <span class="pointer">
                                      <i class="fa fa-check text-success mr-2" style="font-size: 1.5em;" (click)="editDone(i)" title="Done"></i>
                                      <i class="fa fa-refresh text-danger" style="font-size: 1.5em;" (click)="resetCost(i)" title="Reset"></i>
                                     </span>
                                }
                            </div>
                        </td>
                    </tr>
                }

                </tbody>
            </table>

            <div class="row">
                @if (agentTicketsConfig?.length > 0) {
                    <div class="col-12 d-flex justify-content-end">
                        <pagination [rotate]="true" [boundaryLinks]="true" previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;"
                                    lastText="&raquo;" [totalItems]="agentTicketsConfig?.length" (pageChanged)="onPageChange($event)" [itemsPerPage]="10"
                                    [maxSize]="5"></pagination>
                    </div>
                }

            </div>
        </div>
    </div>
</div>
