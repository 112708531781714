<div
    class="widgets-area pb-150"
    [class.component-rtl-enabled]="themeService.isRTLEnabled()"
>
    <div class="container position-relative z-1">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="widgets-image position-relative">
                    <div class="image">
                        <img src="images/front-pages/order-summary.png" alt="order-summary-image">
                    </div>
                    <div class="image2">
                        <img src="images/front-pages/courses-sales.jpg" alt="courses-sales-image">
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="widgets-content">
                    <h2>
                        Tailor Your Dashboard: Unleash the Power of Customizable Widgets
                    </h2>
                    <ul class="mb-0 features-list list-unstyled">
                        <li class="position-relative">
                            <i class="material-symbols-outlined">
                                done_outline
                            </i>
                            <h3 class="fw-semibold">
                                Tailored Display
                            </h3>
                            <p>
                                Easily arrange, resize, and configure widgets to showcase the data most relevant to your workflow.
                            </p>
                        </li>
                        <li class="position-relative">
                            <i class="material-symbols-outlined">
                                done_outline
                            </i>
                            <h3 class="fw-semibold">
                                Personalized Insights
                            </h3>
                            <p>
                                Customize widget content and visualization options to match your specific preferences and priorities.
                            </p>
                        </li>
                        <li class="position-relative">
                            <i class="material-symbols-outlined">
                                done_outline
                            </i>
                            <h3 class="fw-semibold">
                                Flexibility and Versatility
                            </h3>
                            <p>
                                Adapt widgets to evolving business needs by adjusting layouts, styles, and data sources with ease.
                            </p>
                        </li>
                        <li class="position-relative">
                            <i class="material-symbols-outlined">
                                done_outline
                            </i>
                            <h3 class="fw-semibold">
                                Seamless Integration
                            </h3>
                            <p>
                                Integrate widgets seamlessly with other dashboard components and external systems for a cohesive user experience.
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="shape1">
            <img src="images/front-pages/shape1.png" alt="shape1">
        </div>
        <div class="shape2">
            <img src="images/front-pages/shape2.png" alt="shape2">
        </div>
    </div>
</div>