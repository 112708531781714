import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  Validators
} from "@angular/forms";
import {catchError, debounceTime, distinctUntilChanged, map, tap} from "rxjs/operators";
import {mergeMap, Observable, of, take} from "rxjs";
import {
  AgentControllerService,
  AgentPojo, AppUserControllerService,
  BasicUserDetails,
  NewAppUserRole, OrganizationControllerService,
  OrganizationSummaryPojo,
  PortalUserControllerService
} from "../../../../../sdk/tickets-sdk";
import {CustomValidators} from "../../../shared/validators/custom-validators";
import {HttpErrorResponse} from "@angular/common/http";
import {User} from "../../../models/user/user.model";
import {UserAccount} from "../../../models/account/user-account.model";
import {BsModalRef} from "ngx-bootstrap/modal";
import {ToastrService} from "ngx-toastr";
import {AuthenticationService} from "../../../services/authentication.service";
import {EmComponent} from "../../../shared/components/em/em.component";
import {NgClass, TitleCasePipe} from "@angular/common";

@Component({
  selector: 'app-app-user-creation',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    EmComponent,
    TitleCasePipe,
    NgClass
  ],
  templateUrl: './app-user-creation.component.html',
  styleUrl: './app-user-creation.component.scss'
})
export class AppUserCreationComponent  implements OnInit {
  organizations: Array<OrganizationSummaryPojo> | undefined;
  form: FormGroup;
  loading = false;
  fetchingAgents = false;
  agents: Array<AgentPojo> | undefined;
  account: UserAccount | undefined;
  @Input() organizationId: number | undefined;
  @Input() agentId: number | undefined;
  onAppUserCreated = new EventEmitter<any>();
  fetchedExistingUser: BasicUserDetails;
  selectedRoles: Array<NewAppUserRole> = [];
  user: User | undefined


  constructor(
      private appUserControllerService: AppUserControllerService,
      private agentControllerService: AgentControllerService,
      private organizationControllerService: OrganizationControllerService,
      private portalUserControllerService: PortalUserControllerService,
      private fb: FormBuilder,
      private bsModalRef: BsModalRef,
      private toastrService: ToastrService,
      private authenticationService: AuthenticationService
  ) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      firstName: ['', [Validators.required, CustomValidators.minLength(2)]],
      lastName: ['', [Validators.required, CustomValidators.minLength(2)]],
      username: ['', [Validators.required, CustomValidators.minLength(2)]],
      phoneNumber: ['', [Validators.required, CustomValidators.minLength(2)], this.createValidator(this.portalUserControllerService)],
      organizationId: ['', []],
      agentId: ['', []],
      role: ['', []],
      selectedRoles: [[], [Validators.required]],
      userId: ['', []]
    })
    this.authenticationService
        .getUser()
        .pipe(
            tap(x => this.user = x),
            mergeMap(value => this.authenticationService.getAccount()))
        .subscribe(value => {
          this.account = value;
          if (this.account.accountType == 'ADMIN') {
            this.organizationControllerService.searchOrganizations({
              limit: 200,
              id: this.organizationId,
              approvalStatus: 'APPROVED'
            })
                .subscribe(value => {
                  this.organizations = value.results;
                }, error => {
                  if (error.error && typeof error.error == 'object' && error.error.message) {
                    this.toastrService.error(error.error.message);
                    return;
                  }
                  this.toastrService.error('Could not fetch Schemes. Please check your internet connection.')
                });
            this.form.get('organizationId').valueChanges.pipe(distinctUntilChanged()).subscribe(value => {
              this.organizationId = value;
              this.form.get('agentId').patchValue('');
              this.agents = []
              this.fetchAgents(value);
            });
          } else if (this.account.accountType == 'ORGANIZATION') {
            if (this.organizationId) {
              this.form.get('organizationId').patchValue(this.organizationId)
            }
            if (this.agentId) {
              this.form.get('agentId').patchValue(this.agentId);
              this.agents = [{id: this.agentId, portalUser: {displayName: this.user.firstName+' '+this.user.lastName}}]
            }
            if (this.account.roles.find(role => role == 'ADMIN')) {
              this.fetchAgents(this.organizationId);
            }
          }
        });
  }

  fetchAgents(orgId: number) {
    if (!orgId) {
      this.agents = [];
      return;
    }
    this.fetchingAgents = true;
    this.agentControllerService.searchAgent({organizationId: orgId, limit: 200, status: 'ACTIVE'})
        .subscribe(value => {
          this.fetchingAgents = false;
          this.agents = value.results;
        }, error => {
          this.fetchingAgents = false;
          if (error.error && typeof error.error == 'object' && error.error.message) {
            this.toastrService.error(error.error.message);
            return;
          }
          this.toastrService.error('Could not fetch agents. Please check your internet connection.')
        });
  }

  onCancel() {
    this.bsModalRef.hide();
  }

  onCreate() {
    if (this.form.invalid) {
      return;
    }
    if (this.selectedRoles.length < 1) {
      this.toastrService.info('You must select a role for this user.');
      return;
    }
    this.loading = true;
    this.appUserControllerService.createAppUser({
      newAppUserDto: {
        ...this.form.value,
        roles: this.form.value['selectedRoles']
      }
    })
        .subscribe(value => {
          this.loading = false;
          this.toastrService.success('App user added successfully')
          this.onAppUserCreated.emit(value);
          this.bsModalRef.hide();
        }, error => {
          this.loading = false;
          if (error.error && typeof error.error == 'object' && error.error.message) {
            this.toastrService.error(error.error.message);
            return;
          }
          this.toastrService.error('Could not create app user. Please check your internet connection.')
        });
  }

  get canSelectScheme() {
    return this.account && (this.account.accountType == 'ADMIN');
  }

  get canSelectAgent() {
    return this.account && this.authenticationService.hasRole('ADMIN');
  }

  createValidator(portalUserControllerService: PortalUserControllerService): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      console.log('=====>', control.value)
      if (!control.value) {
        return of(null);
      }
      return portalUserControllerService.getUserDetailsByPhoneNumber({phoneNumber: control.value})
          .pipe()
          .pipe(
              debounceTime(500),
              take(1),
              map(value => {
                this.fetchedExistingUser = value;
                if (this.fetchedExistingUser) {
                  this.form.patchValue({...this.fetchedExistingUser});
                } else {
                  this.form.get('userId').patchValue('');
                }
                return null;
              }),
              catchError((err: HttpErrorResponse, caught) => {
                return of({'nointernet': true});
              })
          );
    };
  }


  removeRole(i: number) {
    let roles = [...this.selectedRoles];
    let tempRole = []
    console.log(roles)
    for (let j = 0; j < roles.length; j++) {
      if (j != i) {
        tempRole.push(roles[j])
      }
    }
    this.selectedRoles = [];
    this.selectedRoles.push(...tempRole);
    this.form.get('selectedRoles').patchValue(this.selectedRoles)
  }

  addRole() {
    let organizationId = this.form.get('organizationId').value;
    let agentId = this.form.get('agentId').value;
    let role = this.form.get('role').value;
    if (!organizationId || !agentId || !role) {
      if (!organizationId) {
        this.form.get('organizationId').setErrors({required: true});
        this.form.get('organizationId').markAsDirty()
      }
      if (!agentId) {
        this.form.get('agentId').setErrors({required: true});
        this.form.get('agentId').markAsDirty()
      }
      if (!role) {
        this.form.get('role').setErrors({required: true});
        this.form.get('role').markAsDirty()
      }
      this.form.markAllAsTouched();
      this.form.markAsDirty();
      console.log(this.form)
      return;
    }

    let selectedRole: NewAppUserRole = {
      role: role,
      agentId: agentId,
      organizationId: organizationId,
      organizationName: this.organizations?.find(value => value.id == organizationId)?.name,
      agentName: this.agents?.find(value => value.id == agentId)?.portalUser?.displayName
    };
    if (this.hasRole(selectedRole)) {
      this.toastrService.info('Duplicate roles selected');
    } else {
      this.selectedRoles.push(selectedRole);
    }
    console.log(this.selectedRoles)
    this.form.get('selectedRoles').patchValue(this.selectedRoles);
    if(!this.organizationId){
      this.form.get('organizationId').reset('');
    }
    this.form.get('organizationId').setErrors(null);
    this.form.get('organizationId').markAsPristine()
    if(!this.agentId){
      this.form.get('agentId').reset('');
    }
    this.form.get('agentId').setErrors(null);
    this.form.get('agentId').markAsPristine()

    this.form.get('role').reset('');
    this.form.get('role').setErrors(null);
    this.form.get('role').markAsPristine()
  }

  private hasRole(role: NewAppUserRole) {
    return this.selectedRoles.find(value => value.role == role.role && value.organizationId == role.organizationId && value.agentId == role.agentId) != null;
  }

}
