/**
 * Tickets API
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Locality } from './locality';
import { State } from './state';
import { Lga } from './lga';
import { GpsCoordinate } from './gps-coordinate';
import { PortalUser } from './portal-user';


export interface Address { 
    id?: number;
    createdAt?: string;
    lastUpdatedAt?: string;
    createdBy?: PortalUser;
    lastUpdatedBy?: PortalUser;
    status?: Address.StatusEnum;
    dateDeactivated?: string;
    deactivatedBy?: PortalUser;
    zipCode?: string;
    street?: string;
    houseNumber?: string;
    gpsCoordinate?: GpsCoordinate;
    locality?: Locality;
    lga?: Lga;
    state?: State;
}
export namespace Address {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE';
    export const StatusEnum = {
        ACTIVE: 'ACTIVE' as StatusEnum,
        INACTIVE: 'INACTIVE' as StatusEnum
    };
}


