<div class=" m-0">
    <div class="modal-header">
        <div class="container-fluid">
            <div class="d-flex align-items-center">
                <div class="pull-left flex-fill">
                    <h6 class="modal-title" id="modal-title">New Batch Assignment</h6>
                </div>
                <button (click)="onCancel()" class="close pull-right" type="button">
                    <span aria-hidden="true" class="fa fa-times modal-title"></span>
                </button>
            </div>

        </div>

    </div>
    <div class="modal-body">
        <div class="container-fluid">
            <div class="filter-bg ">
                <form [formGroup]="form" class="row">
                    @if (canSelectScheme) {
                        <div class="col-md-4 form-group">
                            <label class="required">Scheme</label>
                            <select class="form-control" formControlName="organizationId" id="organizationId">
                                <option [value]="''">-- Select Scheme --</option>
                                @for (org of organizations; track org) {
                                    <option [value]="org.id">{{ org.name | titlecase }}</option>
                                }
                            </select>
                        </div>
                    }
                    @if (canSelectAgent) {
                        <div class="col-md-4 form-group">
                            <label class="required">Agent</label>
                            <select class="form-control" [formControlName]="'agentId'" id="agentId">
                                @if (fetchingAgents) {
                                    <option [value]="''">Fetching Agents</option>
                                }
                                @if (!fetchingAgents && !organizationId) {
                                    <option [value]="''">-- Select a Scheme --</option>
                                }
                                @if (!fetchingAgents && agents?.length == 0) {
                                    <option [value]="''">No agents found</option>
                                }
                                @if (!fetchingAgents && agents?.length) {
                                    @if (agents?.length || 0 > 0) {
                                        <option [value]="''">-- Select an Agent --</option>
                                    }
                                    @for (p of agents; track p) {
                                        <option [value]="p.id">{{ p.portalUser.displayName }}</option>
                                    }
                                }
                            </select>
                        </div>
                    }
                    @if (canSelectAgent || canSelectScheme) {
                        <div class="col-sm-12 text-right">
                            <button class="btn btn-custom-filter px-4"
                                    (click)="searchBatches()">
                          <span
                                  class="iconify me-2"
                                  data-height="25"
                                  data-icon="akar-icons:search"
                          ></span>
                                Search
                            </button>
                        </div>
                    }
                </form>
                <div class="my-3 table-responsive-md">
                    <table class="table table-hover table-bordered table-sm">
                        <thead>
                        <tr class="">
                            <th scope="col">
                                <input class="form-check-input ml-0" type="checkbox"
                                       [checked]="selectionManager.allSelected(searchManager.list)"
                                       (click)="selectionManager.toggleAll(searchManager.list);"> &nbsp;

                            </th>
                            <th scope="col">#</th>
                            <th scope="col">Batch Number</th>
                            <th scope="col">Ticket Type</th>
                            <th scope="col">Sold/Unsold</th>
                            <th scope="col">Date</th>
                            <!--              <th scope="col">Action</th>-->
                        </tr>
                        </thead>
                        <tbody>
                            @if (searchManager.working) {
                                <tr>
                                    <td colspan="8" class="my-5">
                                        <span>loading</span>
                                    </td>
                                </tr>
                            }

                            @if (!searchManager.working && searchManager.list.length < 1) {
                                <tr>
                                    <td colspan="8" class="my-5 text-center">
                                        NO DATA
                                    </td>
                                </tr>
                            }

                            @if (!searchManager.working && searchManager.list.length > 0) {
                                @for (d of searchManager.list; track d; let i = $index) {

                                    <tr>
                                        <th scope="row"><input class="form-check-input ml-0"
                                                               type="checkbox"
                                                               id="{{d.id}}"
                                                               [checked]="selectionManager.isSelected(d)"
                                                               [disabled]="!selectionManager.isSelectable(d)"
                                                               (click)="selectionManager.toggleSelection(d);"></th>
                                        <th scope="row">{{ i + 1 }}</th>
                                        <td>{{ d.batchNumber }}</td>
                                        <td>{{ d.ticketType.name }}</td>
                                        <td>{{ d.soldCount }}/{{ d.numberOfTicketsInBatch - d.soldCount }}</td>
                                        <td>{{ d.createdAt | date }}</td>
                                    </tr>
                                }
                            }

                        </tbody>
                    </table>

                    <div class="row">
                        <div class="col-12 d-flex justify-content-end">
                            <pagination [rotate]="true" [boundaryLinks]="true" previousText="&lsaquo;"
                                        nextText="&rsaquo;"
                                        firstText="First"
                                        lastText="Last" [totalItems]="searchManager.totalCount"
                                        [itemsPerPage]="searchManager.itemsPerPage"
                                        [maxSize]="maxPageSize" [(ngModel)]="searchManager.page"></pagination>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="container-fluid text-end">
            <button (click)="onCancel()" class="btn btn-danger mr-2" type="button">
                <i class="fa fa-times mr-1 fa-spinner "></i
                > Cancel
            </button>
            <button class="btn btn-success" type="button" (click)="assign()">
                <i class="fa" [ngClass]="{'fa-spinner fa-spin':loading}"></i>
                Assign selected batches ({{ selectionManager.selectionSize() }})
            </button>
        </div>

    </div>
</div>
