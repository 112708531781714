<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Scheme Details
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 p-0">
        <li class="breadcrumb-item d-inline-block position-relative">
            <a routerLink="/dashboard" class="d-inline-block position-relative">
                <i class="material-symbols-outlined">
                    home
                </i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item d-inline-block position-relative">
            Schemes
        </li>
        <li class="breadcrumb-item d-inline-block position-relative">
            Scheme Details
        </li>
    </ol>
</div>

<div
        class="profile-card trezo-card mb-25 border-radius d-block bg-white border-0 shadow-none p-0"
        [class.card-without-border-radius]="themeService.isCardWithoutBorderRadius()"
        [class.component-rtl-enabled]="themeService.isRTLEnabled()"
        [class.component-dark-theme]="themeService.isDark()"
>
    <div class="trezo-card-content">
        <div class="cover-image position-relative border-top-radius">
            <img src="{{ details.bannerId | fileUrl }}" [alt]="'Scheme Banner'" class="border-top-radius" style="width: 100%; object-fit: contain">

        </div>

    </div>
</div>

<div class="product-development-card trezo-card mb-25 border-radius d-block bg-white border-0 shadow-none"
        [class.component-rtl-enabled]="themeService.isRTLEnabled()">
    <div class="trezo-card-header d-flex align-items-center justify-content-between">
        <div class="trezo-card-title">
            <h5 class="mb-0">
                Scheme Overview
            </h5>
        </div>
        @if (canReview) {
            <div class="trezo-card-subtitle">
                <div class="mt-4 d-flex justify-content-between">
                    <button type="submit" class="btn btn-danger" (click)="disapprove()">
                        Disapproved
                    </button>

                    <button class="btn btn-primary mx-3" (click)="approve()">
                        Approve
                        @if (!working) {
                            <i class="fa fa-check"></i>
                        }

                        @if (working) {
                            <i class="fa fa-spinner fa-spin"></i>
                        }
                    </button>

                </div>
            </div>
        }
    </div>

    <div>
        <div class="row px-5">
            <div class="col-md-4 mb-5">
                <h6>Full Name</h6>
                <span>{{details.adminName}}</span>
            </div>
            <div class="col-md-4 mb-5">
                <h6>Email address</h6>
                <span>{{details.adminEmail}}</span>
            </div>
            <div class="col-md-4 mb-5">
                <h6>Phone number</h6>
                <span>{{details.adminPhoneNumber | titlecase}}</span>
            </div>
            <div class="col-md-4 mb-5">
                <h6>Location</h6>
                <span>{{details.location || '-' | titlecase}}</span>
            </div>
            <div class="col-md-4 mb-5">
                <h6>Type</h6>
                <span>{{details.organizationType | removeUnderscore | titlecase}}</span>
            </div>
            <div class="col-md-4 mb-5">
                <h6>Name</h6>
                <span>{{details.name | titlecase}}</span>
            </div>
            <div class="col-md-4 mb-4">
                <h6>Approval Status</h6>
                <span class=""
                      [ngClass]="{
                      'text-danger':details.approvalStatus=='DISAPPROVED',
                      'text-success':details.approvalStatus=='APPROVED'}">
                    {{ details.approvalStatus | titlecase }}
                </span>
            </div>
            <div class="col-md-4 mb-4">
                <h6>Created</h6>
                <span class="">{{details.createdAt | date }}</span>
            </div>
        </div>
    </div>
</div>

<div class="product-development-card trezo-card mb-25 border-radius d-block bg-white border-0 shadow-none">
    <div class="trezo-card-header d-flex align-items-center justify-content-between">
        <div class="trezo-card-title">
            <h5 class="mb-0">
                Tariff Details
            </h5>
        </div>
    </div>

    @if (details && details.tariffs.length > 0) {
        <div class="trezo-card-content">
            <div class="basic-table table-responsive mt-3">
                <table class="table mb-2">
                    <thead>
                    <tr>
                        <th scope="col" class="fw-medium">S/N</th>
                        <th scope="col" class="fw-medium">Vehicle Types</th>
                        <th scope="col" class="fw-medium">Buying Price</th>
                        <th scope="col" class="fw-medium">Selling Price</th>
                    </tr>
                    </thead>
                    <tbody class="w-100">
                        @for (d of details.tariffs; track d; let i = $index) {
                            <tr>
                                <td class="text-body">{{ i + 1 }}</td>
                                <td>{{ d.name | removeUnderscore | titlecase }}</td>
                                <td>{{ d.buyingPricePerUnit | currency:'₦' }}</td>
                                <td>{{ d.sellingPricePerUnit | currency:'₦' }}</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    }
</div>


<div class="product-development-card trezo-card mb-25 border-radius d-block bg-white border-0 shadow-none">
    <div class="trezo-card-header d-flex align-items-center justify-content-between">
        <div class="trezo-card-title">
            <h5 class="mb-0">
                Zones
            </h5>
        </div>
    </div>
    @if (details && details.zones.length > 0) {
        <div class="trezo-card-content">
            <div class="basic-table table-responsive mt-3">
                <table class="table mb-2">
                    <thead>
                    <tr>
                        <th scope="col" class="fw-medium">S/N</th>
                        <th scope="col" class="fw-medium">Zone</th>
                    </tr>
                    </thead>
                    <tbody class="w-100">
                        @for (d of details.zones; track d; let i = $index) {
                            <tr>
                                <td class="text-body">{{ i + 1 }}</td>
                                <td>{{ d.name | removeUnderscore | titlecase }}</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    }
</div>
