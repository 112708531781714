<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        App Users
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 p-0">
        <li class="breadcrumb-item d-inline-block position-relative">
            <a routerLink="/dashboard" class="d-inline-block position-relative">
                <i class="material-symbols-outlined">
                    home
                </i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item d-inline-block position-relative">
            App Users
        </li>
        <li class="breadcrumb-item d-inline-block position-relative">
            App Users List
        </li>
    </ol>
</div>

<!-- To Do List -->
<!-- To Do List -->
<div
        class="trezo-card table-style-two mb-25 border-radius d-block bg-white border-0 shadow-none"
        [class.component-rtl-enabled]="themeService.isRTLEnabled()"
>
    <div class="trezo-card-header d-sm-flex align-items-center justify-content-end">
        <div class="trezo-card-subtitle">
            @if (canAddAppUser) {
            <button class="add-btn d-inline-block transition fw-medium border-radius" type="button" (click)="addAppUser()">
                <span class="d-inline-block position-relative">
                    <i class="material-symbols-outlined">
                        add
                    </i>
                    Add New App Uuser
                </span>
            </button>
            }
        </div>
    </div>
    <div class="trezo-card-header d-sm-flex align-items-center justify-content-between mt-4">
        <div class="trezo-card-title">
            <form [formGroup]="form" class="search-box position-relative">
                <label class="text-black">
                    <i class="material-symbols-outlined">
                        search
                    </i>
                </label>
                <input type="text" class="input-search d-block w-100 border-radius" placeholder="Search task here....." [formControlName]="'organizationName'">
            </form>
        </div>
        <div class="trezo-card-subtitle">
            <button class="add-btn btn-primary d-inline-block transition fw-medium border-radius" type="button" (click)="submit()">
                <span class="d-inline-block position-relative">
                    <i class="material-symbols-outlined">
                        search
                    </i>
                    Search
                </span>
            </button>
        </div>
    </div>
    <div class="trezo-card-content">
        <div class="basic-table table-responsive">
            <table class="table mb-0">
                <thead>
                <tr>
                    <th scope="col" class="fw-medium">
                        S/N
                    </th>
                    <th scope="col" class="fw-medium">
                        Name
                    </th>
                    <th scope="col" class="fw-medium">
                        Username
                    </th>
                    <th scope="col" class="fw-medium">
                        Phone Number
                    </th>
                    @if (canSelectAgent) {
                        <th scope="col" class="fw-medium">
                            Agent
                        </th>
                    }
                    <th scope="col" class="fw-medium">
                        Status
                    </th>
                    <th scope="col" class="fw-medium">
                        Action
                    </th>
                </tr>
                </thead>
                <tbody>
                    @for (d of searchManager.list; track d; let i = $index) {
                        <tr>
                            <td class="text-body">{{ i + 1 }}</td>
                            <td>
                                {{ d.firstName + ' ' + d.lastName || '-' }}
                            </td>
                            <td>{{ d.username }}</td>
                            <td>{{ d.phoneNumber || '-' }}</td>
                            <td>{{ getAgents(d.roles) || '-' }}</td>
                            <td>
                                <span class="trezo-badge" [ngClass]="getStatusStyle(d.status)">
                                    {{ d.status }}
                                </span>
                            </td>
                            <td>
                                <button class="dot-menu p-0 border-0 bg-transparent d-inline-block transition lh-1"
                                        type="button"
                                        id="dropdown"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false">
                                    <i class="ri-more-fill"></i>
                                </button>

                                <ul class="dropdown-menu border-radius border-0" aria-labelledby="dropdown">
                                    <li>
                                        <button type="button" class="dropdown-item d-flex align-items-center" (click)="editAppUser(d.id)">
                                            <i class="material-symbols-outlined icon edit-icon me-2">edit</i>
                                            <span>Edit User</span>
                                        </button>
                                    </li>
                                    <li>
                                        <button type="button" class="dropdown-item d-flex align-items-center" (click)="resetPassword(d)">
                                            <i class="material-symbols-outlined icon reset-icon me-2">reset_settings</i>
                                            <span>Reset Password</span>
                                        </button>
                                    </li>
                                    <li>
                                        <button type="button" class="dropdown-item d-flex align-items-center" (click)="viewBatches(d)">
                                            <i class="material-symbols-outlined icon view-icon me-2">visibility</i>
                                            <span>View Batches</span>
                                        </button>
                                    </li>
                                    <li>
                                        <button type="button" class="dropdown-item d-flex align-items-center" (click)="assignBatches(d)">
                                            <i class="material-symbols-outlined icon assign-icon me-2">assignment_returned</i>
                                            <span>Assign Batches</span>
                                        </button>
                                    </li>
                                </ul>

                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    </div>
</div>
