import {Component, EventEmitter, Input} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {CustomValidators} from "../../validators/custom-validators";
import {EmComponent} from "../em/em.component";

@Component({
  selector: 'app-disapprove-dialog',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    EmComponent
  ],
  templateUrl: './disapprove-dialog.component.html',
  styleUrl: './disapprove-dialog.component.scss'
})
export class DisapproveDialogComponent {

  form!: FormGroup;
  onConfirmClicked = new EventEmitter<any>();

  @Input() loading = false;

  constructor(private modalRef: BsModalRef, private fb: FormBuilder) {
  }


  ngOnInit() {
    this.form = this.fb.group({
      reasonForDisapproval: ['', [Validators.required,CustomValidators.minLength(5),Validators.maxLength(100)]]
    });
  }


  onDone() {
    this.onConfirmClicked.emit(this.form.value);
  }

  close() {
    this.modalRef.hide();
  }

  protected readonly confirm = confirm;
}
