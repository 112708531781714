import {Component, OnInit} from '@angular/core';
import {CustomizerSettingsService} from "../../../customizer-settings/customizer-settings.service";
import {CurrencyPipe, DatePipe, NgClass, TitleCasePipe} from "@angular/common";
import {OrganizationControllerService, OrganizationSummaryWithTariffPojo} from "../../../../../sdk/tickets-sdk";
import {UserAccount} from "../../../models/account/user-account.model";
import {ActivatedRoute} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {BsModalService, ModalModule} from "ngx-bootstrap/modal";
import {AuthenticationService} from "../../../services/authentication.service";
import {ConfirmDialogComponent} from "../../../shared/components/confirm-dialog/confirm-dialog.component";
import {DisapproveDialogComponent} from "../../../shared/components/disapprove-dialog/disapprove-dialog.component";
import {RemoveUnderscorePipe} from "../../../shared/pipes/remove-underscore.pipe";
import {FileUrlPipe} from "../../../shared/pipes/file-url.pipe";

@Component({
    selector: 'app-organization-details',
    standalone: true,
    imports: [
        CurrencyPipe,
        RemoveUnderscorePipe,
        TitleCasePipe,
        DatePipe,
        NgClass,
        FileUrlPipe
    ],
    providers: [BsModalService],
    templateUrl: './organization-details.component.html',
    styleUrl: './organization-details.component.scss'
})
export class OrganizationDetailsComponent implements OnInit {

    details: OrganizationSummaryWithTariffPojo | undefined;
    loading = false;
    account: UserAccount | undefined;
    backLink: string | undefined;
    backLinkName: string | undefined;
    working = false;

    constructor(
        private activatedRoute: ActivatedRoute,
        private toastrService: ToastrService,
        private bsModalService: BsModalService,
        protected themeService: CustomizerSettingsService,
        private organizationControllerService: OrganizationControllerService,
        private authenticationService: AuthenticationService,
    ) {
    }

    ngOnInit(): void {
        this.activatedRoute.params.subscribe((value: any) => {
            this.backLink = value['backLink'] || '/organizations';
            this.backLinkName = value['backLinkName'] || 'Schemes';
            this.getOrganizationDetails(value.id);
        })
        this.authenticationService.getAccount().subscribe(value => {
            this.account = value;
        });
    }

    private getOrganizationDetails(id: number) {
        this.loading = true;
        this.organizationControllerService.viewOrganizationDetails({id})
            .subscribe(org => {
                this.loading = false;
                this.details = org;
            }, error => {
                this.loading = false;
                if (error.error && typeof error.error == 'object' && error.error.message) {
                    this.toastrService.error(error.error.message);
                    return;
                }
                this.toastrService.error('Could not get organization details. Please check your internet connection.')
            });
    }

    approve() {
        let bsModalRef = this.bsModalService.show(ConfirmDialogComponent, {
            initialState: {
                dismissOnConfirm: false,
                body: 'Are you sure you want to approve this scheme?',
                positiveButtonText: 'Approve'
            }
        });
        bsModalRef?.content?.onConfirmClicked.subscribe(v => {
            this.organizationControllerService.reviewOrganization({review: 'APPROVED', id: this.details?.id || 0})
                .subscribe(value => {
                    bsModalRef.hide();
                    this.getOrganizationDetails(this.details?.id || 0)
                }, error => {
                    if (error.error && typeof error.error == 'object' && error.error.message) {
                        this.toastrService.error(error.error.message);
                        return;
                    }
                    this.toastrService.error('Could not approve this request. Please check your internet connection.')
                });
        });

    }

    disapprove() {
        let bsModalRef = this.bsModalService.show(DisapproveDialogComponent, {
            initialState: {
                loading: false
            }
        });
        bsModalRef?.content?.onConfirmClicked.subscribe(v => {
            if (bsModalRef?.content) {
                bsModalRef.content.loading = true;
            }
            this.organizationControllerService.reviewOrganization({
                review: 'DISAPPROVED',
                id: this.details?.id || 0,
                reasonForDisapproval: v.reasonForDisapproval
            })
                .subscribe(value => {
                    if (bsModalRef?.content) {
                        bsModalRef.content.loading = false;
                    }
                    bsModalRef.hide();
                    this.getOrganizationDetails(this.details?.id || 0)
                }, error => {
                    if (bsModalRef?.content) {
                        bsModalRef.content.loading = false;
                    }
                    if (error.error && typeof error.error == 'object' && error.error.message) {
                        this.toastrService.error(error.error.message);
                        return;
                    }
                    this.toastrService.error('Could not disapprove this request. Please check your internet connection.')
                });
        });
    }

    get canReview() {
        return this.details.approvalStatus == 'PENDING' && this.authenticationService.hasAccountType('ADMIN') && this.authenticationService.hasPermission('REVIEW_SCHEME');
    }

}
