/**
 * Tickets API
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UpdateTicketTypeDto { 
    buyingPricePerUnit: number;
    sellingPricePerUnit: number;
    name: string;
    organizationId?: number;
    ticketTypeId: number;
    status?: UpdateTicketTypeDto.StatusEnum;
}
export namespace UpdateTicketTypeDto {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE';
    export const StatusEnum = {
        ACTIVE: 'ACTIVE' as StatusEnum,
        INACTIVE: 'INACTIVE' as StatusEnum
    };
}


