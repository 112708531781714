<div
    class="banner-area"
    [class.component-rtl-enabled]="themeService.isRTLEnabled()"
>
    <div class="container position-relative z-1">
        <div class="banner-content text-center mx-auto">
            <h1>
                Insights On-the-Go: Access Your Dashboard Anywhere, Anytime
            </h1>
            <p>
                Our intuitive interface transforms complex data into actionable insights, empowering you to make informed decisions with confidence.
            </p>
            <a routerLink="/dashboard" class="btn-box d-inline-block fw-medium border-radius transition">
                <span class="d-inline-block position-relative">
                    <i class="material-symbols-outlined">
                        person
                    </i>
                    Get started - It is free
                </span>
            </a>
        </div>
        <div class="banner-image text-center">
            <img src="images/front-pages/dashboard.png" alt="dashboard-image">
        </div>
        <div class="shape1">
            <img src="images/front-pages/shape3.png" alt="shape3">
        </div>
        <div class="shape2">
            <img src="images/front-pages/shape2.png" alt="shape2">
        </div>
        <div class="shape3">
            <img src="images/front-pages/shape4.png" alt="shape4">
        </div>
        <div class="shape4">
            <img src="images/front-pages/shape5.png" alt="shape5">
        </div>
    </div>
</div>