import {Component, EventEmitter, Input, Output} from '@angular/core';
import {
  CreateOrganizationRequestParams,
  LocationService, NewOrganizationDto,
  OrganizationControllerService
} from "../../../../../../sdk/tickets-sdk";
import {FormBuilder} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {BsModalService} from "ngx-bootstrap/modal";
import {CurrencyPipe, TitleCasePipe} from "@angular/common";
import {RemoveUnderscorePipe} from "../../../../shared/pipes/remove-underscore.pipe";

@Component({
  selector: 'organization-summary',
  standalone: true,
  imports: [
    CurrencyPipe,
    TitleCasePipe,
    RemoveUnderscorePipe
  ],
  templateUrl: './organization-summary.component.html',
  styleUrl: './organization-summary.component.scss'
})
export class OrganizationSummaryComponent {

  working = false;

  @Input() locationData: { lgaName: string; stateName: string; countryName: string };
  @Input() data!: NewOrganizationDto;

  @Output() gotoNextStep = new EventEmitter<NewOrganizationDto>();
  @Output() goToPreviousStep = new EventEmitter<any>();

  constructor(
      private fb: FormBuilder,
      private locationService: LocationService,
      private toastrService: ToastrService,
      private bsModalService: BsModalService,
      private organizationControllerService: OrganizationControllerService
  ) {
  }

  ngOnInit(): void {

  }


  submit() {
    this.working = true;
    this.organizationControllerService.createOrganization({ newOrganizationDto: this.data})
        .subscribe(value => {
          this.gotoNextStep.emit(this.data);
        }, error => {
          if (error.error && typeof error.error == 'object' && error.error.message) {
            this.toastrService.error(error.error.message);
            return;
          }
          this.toastrService.error('Unable to create at this time. Try again later');
        }).add(() => this.working = false);
  }


  previousStep() {
    this.goToPreviousStep.emit(this.data);
  }

}


export interface NewOrganizationDtoExt extends NewOrganizationDto {
  lgaName: string;
  stateName: string;
  countryName: string
}
