import { Component } from '@angular/core';
import { CommonModule, ViewportScroller } from '@angular/common';
import { RouterOutlet, Router, Event, NavigationEnd } from '@angular/router';
import {SidebarComponent} from "./common/sidebar/sidebar.component";
import {HeaderComponent} from "./common/header/header.component";
import {FooterComponent} from "./common/footer/footer.component";
import {CustomizerSettingsComponent} from "./customizer-settings/customizer-settings.component";
import {ToasterComponent} from "./shared/components/toaster/toaster.component";
import {ToasterService, ToastModel} from "./shared/components/toaster/toaster.service";

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [RouterOutlet, CommonModule, SidebarComponent, HeaderComponent, FooterComponent, CustomizerSettingsComponent, ToasterComponent],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent {

    title = 'SmartTickets';
    toast: ToastModel;

    constructor(
        private router: Router,
        private toasterService: ToasterService,
        private viewportScroller: ViewportScroller
    ) {
        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                this.viewportScroller.scrollToPosition([0, 0]);
            }
        });

        this.toasterService.toast().subscribe((v) => {
            this.toast = v;
        });
    }

}
