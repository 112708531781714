/**
 * Tickets API
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { State } from './state';
import { PortalUser } from './portal-user';


export interface Country { 
    id?: number;
    createdAt?: string;
    lastUpdatedAt?: string;
    createdBy?: PortalUser;
    lastUpdatedBy?: PortalUser;
    status?: Country.StatusEnum;
    dateDeactivated?: string;
    deactivatedBy?: PortalUser;
    name?: string;
    alpha2?: string;
    alpha3?: string;
    internationalDialingCode?: string;
    supportedOnSystem?: boolean;
    states?: Array<State>;
}
export namespace Country {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE';
    export const StatusEnum = {
        ACTIVE: 'ACTIVE' as StatusEnum,
        INACTIVE: 'INACTIVE' as StatusEnum
    };
}


