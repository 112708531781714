<div
    class="total-orders-card trezo-card mb-25 border-radius d-block bg-white border-0 shadow-none"
    [class.component-rtl-enabled]="themeService.isRTLEnabled()"
    [class.component-dark-theme]="themeService.isDark()"
>
    <div class="trezo-card-content">
        <div class="title">
            <div class="d-flex d-md-block d-lg-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                    <span class="d-block">
                        Total Orders
                    </span>
                    <span class="info-badge down d-inline-block">
                        -7.6%
                    </span>
                </div>
                <span class="days d-block">
                    Last 7 days
                </span>
            </div>
            <h5 class="mb-0">
                $72,458
            </h5>
        </div>
        <div class="chart mx-auto">
            <div id="ecommerce_total_orders_chart"></div>
        </div>
        <ul class="ps-0 mb-0 list-unstyled">
            <li class="completed position-relative d-flex aling-items-center justify-content-between">
                <span class="d-block">
                    Completed
                </span>
                <span class="d-block">
                    62%
                </span>
            </li>
            <li class="pending position-relative d-flex aling-items-center justify-content-between">
                <span class="d-block">
                    Pending payment
                </span>
                <span class="d-block">
                    38%
                </span>
            </li>
        </ul>
    </div>
</div>