import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {SearchFilterSource} from "../../../shared/search/search-filter-source";
import {
    AgentControllerService, AppUserAccountPojo, AppUserAgent,
    AppUserControllerService, AppUserPojo,
    Lga,
    LocationService,
    OrganizationControllerService,
    OrganizationSummaryPojo, SearchAppUsersRequestParams, SearchOrganizationsRequestParams
} from "../../../../../sdk/tickets-sdk";
import {SearchManager} from "../../../shared/search/search-manager";
import {User} from "../../../models/user/user.model";
import {UserAccount} from "../../../models/account/user-account.model";
import {ToastrService} from "ngx-toastr";
import {AuthenticationService} from "../../../services/authentication.service";
import {PageManager} from "../../../services/page-manager";
import {BsModalService} from "ngx-bootstrap/modal";
import {CustomValidators} from "../../../shared/validators/custom-validators";
import {distinctUntilChanged} from "rxjs/operators";
import {NameValuePair} from "../../../models/etc/name-value-pair.model";
import {PaginatedSearch} from "../../../shared/search/paginated-search";
import {AppUserEditComponent} from "../app-user-edit/app-user-edit.component";
import {SearchHandler} from "../../../shared/search/search-handler";
import {Observable} from "rxjs";
import moment from "moment";
import {AppUserCreationComponent} from "../app-user-creation/app-user-creation.component";
import {
    ResetPasswordDialogComponent
} from "../../../shared/components/reset-password-dialog/reset-password-dialog.component";
import {CustomizerSettingsService} from "../../../customizer-settings/customizer-settings.service";
import {AppUserBatchAssignmentComponent} from "../app-user-batch-assignment/app-user-batch-assignment.component";
import {AppUserBatchListComponent} from "../app-user-batch-list/app-user-batch-list.component";
import {NgClass} from "@angular/common";
import StatusEnum = AppUserPojo.StatusEnum;

@Component({
    selector: 'app-app-user-list',
    templateUrl: './app-user-list.component.html',
    styleUrl: './app-user-list.component.scss',
    imports: [
        FormsModule,
        RouterLink,
        ReactiveFormsModule,
        NgClass
    ],
    standalone: true,
    providers: [BsModalService]

})
export class AppUserListComponent implements OnInit, SearchHandler<AppUserPojo, SearchAppUsersRequestParams>,
    SearchFilterSource <SearchAppUsersRequestParams> {
    form!: FormGroup;
    organizations: Array<OrganizationSummaryPojo> | undefined;
    countryId: any;
    stateId: any;
    fetchingStates = false;
    fetchingLga = false;
    lgas: Array<Lga> | undefined;
    searchManager!: SearchManager<AppUserPojo, SearchAppUsersRequestParams>
    maxPageSize = 10;
    user: User | undefined;
    account: UserAccount | undefined;
    minDate: any;


    constructor(
        private fb: FormBuilder,
        protected themeService: CustomizerSettingsService,
        private activatedRoute: ActivatedRoute,
        private toastrService: ToastrService,
        private appUserControllerService: AppUserControllerService,
        private organizationControllerService: OrganizationControllerService,
        private agentControllerService: AgentControllerService,
        private authenticationService: AuthenticationService,
        private pageManager: PageManager,
        private bsModalService: BsModalService,
    ) {
        this.searchManager = new SearchManager<AppUserPojo, SearchAppUsersRequestParams>(this, this);
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            username: [''],
            phoneNumber: ['', [CustomValidators.nigerianNumber]],
            organizationId: [''],
            organizationName: [''],
            agentId: [''],
            agentEmail: ['', [Validators.email]],
            status: [''],
            role: [''],
            name: [''],
            createdAfter: [''],
            createdBefore: [''],
        });
        this.authenticationService.getUser().subscribe(value => {
            this.user = value;
            let savedFilterData = this.searchManager.getSavedFilterData();
            console.log(savedFilterData);
            this.form.patchValue({...savedFilterData});
            if (savedFilterData?.createdBefore) {
                this.form.get('createdBefore').patchValue(moment(savedFilterData.createdBefore).toDate());
            }
            if (savedFilterData?.createdAfter) {
                this.form.get('createdAfter').patchValue(moment(savedFilterData.createdAfter).toDate())
            }
        });
        this.authenticationService.getAccount().pipe(distinctUntilChanged()).subscribe(value => {
            this.account = value;
            if (this.canSelectScheme) {
                this.organizationControllerService.searchOrganizations({
                    limit: 1000,
                    approvalStatus: 'APPROVED'
                })
                    .subscribe(value => {
                        this.organizations = value.results;
                    });
                this.form.get('organizationId').valueChanges.pipe(distinctUntilChanged()).subscribe(value1 => {
                    let orgName = this.organizations?.find(v => v.id == value1)?.name;
                    this.form.get('organizationName').patchValue(orgName)
                });
            }
        });
        this.activatedRoute.queryParams.subscribe(params => {

        });

        this.form.get('createdAfter')?.valueChanges.subscribe(v => {
            this.minDate = v;
            // this.dateValid = true;
            if (this.form.get('createdBefore').value) {

                if (moment(v).startOf('day').isAfter(moment(this.form.get('createdBefore').value).startOf('day'))) {
                    this.toastrService.error("Invalid Date Range Selected");
                    // this.dateValid = false;
                } else {
                    // this.dateValid = true;
                }
            }
        });
    }

    getFilter(): SearchAppUsersRequestParams {
        let d: SearchAppUsersRequestParams = {
            ...this.form.value
        }
        if (d.createdAfter) {
            d.createdAfter = moment(d.createdAfter).format('YYYY-MM-DD');
        }
        if (d.createdBefore) {
            d.createdBefore = moment(d.createdBefore).format('YYYY-MM-DD');
        }
        if (this.canSelectAgent) {
            delete d.agentId;
        }
        return d;
    }

    getPersistentKey(): string {
        return AppUserListComponent.name + this.user?.userId;
    }

    getSearchDescriptor(e: SearchOrganizationsRequestParams): NameValuePair[] {
        const descriptions: NameValuePair[] = [];

        Object.keys(e).forEach((key) => {
            let value = (e as any)[key];
            if (!value) {
                return;
            }
            switch (key) {
                case 'organizationId':
                    break;
                case 'createdAfter':
                    descriptions.push(new NameValuePair(`${moment(value).format('MMM DD, YYYY')}`, key));
                    break;
                case 'createdBefore':
                    descriptions.push(new NameValuePair(`${moment(value).format('MMM DD, YYYY')}`, key));
                    break;
                default:
                    descriptions.push(new NameValuePair(`${value}`, key));
                    break;
            }
        });
        return descriptions;
    }

    submit() {
        if (!this.form.valid) {
            return;
        }
        this.searchManager.reloadAndShowFirstPage();
    }

    search(page: number | undefined, filter?: SearchAppUsersRequestParams): Observable<AppUserPojo | any> {
        const offset = ((page || 0) - 1) * this.searchManager.itemsPerPage;
        let paginatedSearch = PaginatedSearch.createCleanFilter(filter, this.searchManager.itemsPerPage, offset);
        return this.appUserControllerService.searchAppUsers({...paginatedSearch.getSearchParams()});
    }

    get canAddAppUser() {
        return !!this.user;
    }

    addAppUser() {
        let bsModalRef = this.bsModalService.show(AppUserCreationComponent, {
            initialState: {
                agentId: this.account.agent?.id,
                organizationId: this.account.organization?.id,
            },
            class: 'modal-lg'
        });
        bsModalRef.content.onAppUserCreated.subscribe(v => {
            this.searchManager.reloadAndShowFirstPage();
        })
    }

    editAppUser(id: number) {
        let bsModalRef = this.bsModalService.show(AppUserEditComponent, {
            initialState: {
                agentId: this.account.agent?.id,
                organizationId: this.account.organization?.id,
                appUserId: id
            },
            class: 'modal-lg'
        });
        bsModalRef.content.onAppUserEdited.subscribe(v => {
            this.searchManager.reloadAndShowFirstPage();
        })
    }

    getAgents(roles: Array<AppUserAccountPojo>) {
        if (!roles) {
            return '';
        }
        return [...new Set(roles.map(value => value.agentName))].join(', ');
    }

    now() {
        return new Date();
    }

    getMinDate() {
        return this.minDate
    }

    resetPassword(userPojo: AppUserPojo) {
        this.bsModalService.show(ResetPasswordDialogComponent, {
            initialState: {
                username: userPojo.username,
                name: userPojo.firstName,
                phoneNumber: userPojo.phoneNumber,
                notificationChannel: 'PHONE_NUMBER'
            }, class: 'modal-lg'
        });
    }

    assignBatches(appUser: AppUserPojo) {
        let bsModalRef = this.bsModalService.show(AppUserBatchAssignmentComponent, {
            initialState: {
                appUser: appUser,
                agentId: this.account.agent?.id,
                organizationId: this.account.organization?.id,
            }, class: 'modal-lg'
        });
    }

    viewBatches(appUser: AppUserPojo) {
        let bsModalRef = this.bsModalService.show(AppUserBatchListComponent, {
            initialState: {
                appUser: appUser
            }, class: 'modal-lg'
        });
    }

    get canSelectScheme() {
        return this.account && (this.account.accountType == 'ADMIN');
    }

    get canSelectAgent() {
        return this.account && (this.account.accountType == 'ADMIN' || this.account.accountType == 'ORGANIZATION') && this.authenticationService.hasRole('ADMIN');
    }

    getStatusStyle(approvalStatus: StatusEnum) {
        switch (approvalStatus) {
            case StatusEnum.ACTIVE:
                return 'pFinished';
            case StatusEnum.INACTIVE:
                return 'rejected';
            default:
                return '';
        }

    }
}
