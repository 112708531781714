import {Component, OnInit} from '@angular/core';
import {CustomizerSettingsService} from "../../../customizer-settings/customizer-settings.service";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {FormBuilder, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {
    Country,
    Lga,
    LocationService,
    OrganizationControllerService, OrganizationSummaryPojo, OrganizationSummaryWithTariffPojo,
    SearchOrganizationsRequestParams,
    State
} from "../../../../../sdk/tickets-sdk";
import {User} from "../../../models/user/user.model";
import {AuthenticationService} from "../../../services/authentication.service";
import {PageManager} from "../../../services/page-manager";
import {CustomValidators} from "../../../shared/validators/custom-validators";
import {distinctUntilChanged} from "rxjs/operators";
import {Observable} from "rxjs";
import {NameValuePair} from "../../../models/etc/name-value-pair.model";
import {SearchHandler} from "../../../shared/search/search-handler";
import {SearchFilterSource} from "../../../shared/search/search-filter-source";
import {SearchManager} from "../../../shared/search/search-manager";
import {PaginatedSearch} from "../../../shared/search/paginated-search";
import {DatePipe, NgClass, TitleCasePipe} from "@angular/common";
import {RemoveUnderscorePipe} from "../../../shared/pipes/remove-underscore.pipe";
import ApprovalStatusEnum = OrganizationSummaryWithTariffPojo.ApprovalStatusEnum;

@Component({
    selector: 'app-organization-list',
    standalone: true,
    templateUrl: './organization-list.component.html',
    styleUrl: './organization-list.component.scss',
    imports: [
        TitleCasePipe,
        RemoveUnderscorePipe,
        NgClass,
        RouterLink,
        DatePipe,
        ReactiveFormsModule
    ]
})
export class OrganizationListComponent implements OnInit, SearchHandler<OrganizationSummaryPojo, SearchOrganizationsRequestParams>,
    SearchFilterSource <SearchOrganizationsRequestParams> {
    form!: FormGroup;
    countries: Array<Country> | undefined;
    countryId: any;
    stateId: any;
    fetchingStates = false;
    states: Array<State> | undefined;
    fetchingLga = false;
    lgas: Array<Lga> | undefined;
    searchManager!: SearchManager<OrganizationSummaryPojo, SearchOrganizationsRequestParams>
    maxPageSize = 10;
    user: User | undefined;
    countryName: string | undefined;
    stateName: string | undefined;
    lgaName: string | undefined;

    isToggled = false;


    createScheme(): void {
        this.router.navigate(['dashboard', 'organizations', 'new'])
    }

    constructor(
        private fb: FormBuilder,
        private router: Router,
        public themeService: CustomizerSettingsService,
        private locationService: LocationService,
        private activatedRoute: ActivatedRoute,
        private organizationControllerService: OrganizationControllerService,
        private authenticationService: AuthenticationService,
        private pageManager: PageManager,
    ) {
        this.searchManager = new SearchManager<OrganizationSummaryPojo, SearchOrganizationsRequestParams>(this, this);
    this.themeService.isToggled$.subscribe(isToggled => {
    this.isToggled = isToggled;
    });
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            adminName: [''],
            organizationName: [],
            adminPhoneNumber: ['', [CustomValidators.nigerianNumber]],
            schemeType: [''],
            approvalStatus: [''],
            countryId: [''],
            stateId: [''],
            lgaId: [''],
            lga: [''],
            country: [''],
            state: [''],
        });
        this.authenticationService.getUser().subscribe(value => {
            this.user = value;
        });
        this.activatedRoute.queryParams.subscribe(params => {

        });

        this.locationService.searchCountries({alpha2: 'NG'})
            .subscribe(value => {
                this.countries = value.results;
            })
        this.form.get('countryId')?.valueChanges.pipe(distinctUntilChanged()).subscribe(v => {
            this.countryId = v;
            this.countryName = this.countries?.find(value => value.id == v)?.name
            this.form.get('country').patchValue(this.countryName);
            this.form.get('stateId').patchValue('');
            this.states = [];
            this.fetchStates(v);
        })

        this.form.get('stateId')?.valueChanges.pipe(distinctUntilChanged()).subscribe(v => {
            this.stateId = v;
            this.stateName = this.states?.find(value => value.id == v)?.name
            this.form.get('state').patchValue(this.stateName);
            this.form.get('lgaId').patchValue('');
            this.lgas = []
            this.fetchLga(v);
        });
        this.form.get('lgaId')?.valueChanges.pipe(distinctUntilChanged()).subscribe(v => {
            this.lgaName = this.lgas?.find(value => value.id == v)?.name
            this.form.get('lga').patchValue(this.lgaName);
        });
        this.form.patchValue(this.searchManager.getSavedFilterData());
    }

    fetchStates(countryId: number) {
        if (!countryId) {
            this.states = [];
            return;
        }
        this.fetchingStates = true;
        this.locationService.searchStates({countryId: countryId})
            .subscribe(value => {
                this.fetchingStates = false;
                this.states = value.results;
            }, error => {
                this.fetchingStates = false;
            })
    }

    fetchLga(stateId: number) {
        this.fetchingLga = true;
        this.locationService.searchLgas({stateId: stateId as any})
            .subscribe(value => {
                this.fetchingLga = false;
                this.lgas = value.results;
            }, error => {
                this.fetchingLga = false;
            })
    }

    getFilter(): SearchOrganizationsRequestParams {
        let d: SearchOrganizationsRequestParams = {
            ...this.form.value
        }
        return d;
    }

    getPersistentKey(): string {
        return OrganizationListComponent.name;
    }

    getSearchDescriptor(e: SearchOrganizationsRequestParams): NameValuePair[] {
        const descriptions: NameValuePair[] = [];

        Object.keys(e).forEach((key) => {
            if (!(e as any)[key]) {
                return;
            }
            switch (key) {
                case 'countryId':
                    // descriptions.push(new NameValuePair(`${this.countryName}`, 'Country'));
                    break;
                case 'stateId':
                    // descriptions.push(new NameValuePair(`${this.stateName}`, 'State'));
                    break;
                case 'lgaId':
                    // descriptions.push(new NameValuePair(`${this.lgaName}`, 'Lga'));
                    break;
                default:
                    descriptions.push(new NameValuePair(`${(e as any)[key]}`, key));
                    break;
            }
        });
        return [...descriptions];
    }

    submit() {
        console.log(this.form.value);
        if (!this.form.valid) {
            return;
        }
        this.updateSearchQuery();
        this.searchManager.reloadAndShowFirstPage();
    }

    search(page: number | undefined, filter?: SearchOrganizationsRequestParams): Observable<OrganizationSummaryPojo | any> {
        const offset = ((page || 0) - 1) * this.searchManager.itemsPerPage;
        let paginatedSearch = PaginatedSearch.createCleanFilter(filter, this.searchManager.itemsPerPage, offset);
        return this.organizationControllerService.searchOrganizations({...paginatedSearch.getSearchParams()});
    }

    updateSearchQuery() {
        this.pageManager.storeData(OrganizationListComponent.name, 'search', this.form.value);
    }

    private loadSearchData() {
        this.pageManager.getData(OrganizationListComponent.name, 'search');
    }

    get canAddScheme() {
        return this.user && this.authenticationService.hasPermission('CREATE_ORGANIZATION');
    }

    ApprovalStatus = ApprovalStatusEnum;

    getStatusStyle(approvalStatus: OrganizationSummaryPojo.ApprovalStatusEnum) {
        switch (approvalStatus) {
            case OrganizationSummaryPojo.ApprovalStatusEnum.APPROVED:
                return 'pFinished';
            case OrganizationSummaryPojo.ApprovalStatusEnum.PENDING:
                return 'pInProgress';
            case OrganizationSummaryPojo.ApprovalStatusEnum.DISAPPROVED:
                return 'rejected';
            default:
                return '';
        }
    }
}
