/**
 * Tickets API
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Ticket } from './ticket';
import { Zone } from './zone';
import { AppUser } from './app-user';
import { PortalUser } from './portal-user';


export interface TicketIssuance { 
    id?: number;
    createdAt?: string;
    lastUpdatedAt?: string;
    createdBy?: PortalUser;
    lastUpdatedBy?: PortalUser;
    status?: TicketIssuance.StatusEnum;
    dateDeactivated?: string;
    deactivatedBy?: PortalUser;
    recipientName?: string;
    recipientPhoneNumber?: string;
    vehicleNumber?: string;
    revenueIdentificationNumber?: string;
    ticket?: Ticket;
    appUser?: AppUser;
    zone?: Zone;
}
export namespace TicketIssuance {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE';
    export const StatusEnum = {
        ACTIVE: 'ACTIVE' as StatusEnum,
        INACTIVE: 'INACTIVE' as StatusEnum
    };
}


