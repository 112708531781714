<div class="trezo-card-content">
    <div class="">
        <div class="row g-3">
            <div class="col-sm-12"></div>
            <div class="col-sm-12 text-center mb-2">
                <i class="material-symbols-outlined">
                    {{ 'settings' }}
                </i>
                <h3>Configuring Tariff Information</h3>
            </div>
        </div>
    </div>
</div>

<div
        class="trezo-card table-style-two mb-25 border-radius d-block bg-white border-0 shadow-none"
>
    <div class="trezo-card-header d-sm-flex align-items-center justify-content-between">
        <div class="trezo-card-subtitle">
            <input type="file"
                   [hidden]="true"
                   (change)="onFileChange($event)"
                   [accept]="'.xls,.xlsx'" #file>
            <button class="add-btn d-inline-block transition fw-medium border-radius" type="button"
                    (click)="file.click()">
                <span class="d-inline-block position-relative">
                    <i class="material-symbols-outlined">
                        {{ 'cloud_upload' }}
                    </i>
                    Upload Data
                </span>

            </button>
        </div>
        <div class="trezo-card-subtitle">
            <button class="add-btn d-inline-block transition fw-medium border-radius" type="button"
                    (click)="downloadTemplateSheet()">
                <span class="d-inline-block position-relative">
                    <i class="material-symbols-outlined">
                        table_view
                    </i>
                    Download Template
                </span>
            </button>
        </div>
    </div>
    @if (tariffData && tariffData.length > 0) {
        <div class="trezo-card-content">
        <div class="basic-table table-responsive mt-3">
            <table class="table mb-0">
                <thead>
                <tr>
                    <th scope="col" class="fw-medium">S/N</th>
                    <th scope="col" class="fw-medium">Vehicle Types</th>
                    <th scope="col" class="fw-medium" >Buying Price</th>
                    <th scope="col" class="fw-medium" >Selling Price</th>
                    <th scope="col" class="fw-medium">Status</th>
                </tr>
                </thead>
                <tbody class="w-100">
                    @if (tariffData && tariffData.length > 0) {
                        @for (row of tariffData; track row; let i = $index) {
                            <tr>
                                <th scope="row">{{ i + 1 }}</th>
                                @for (cell of row; track cell; let j = $index) {
                                    <td>
                                        @if (j < row.length - 1) {
                                            <span>{{ j == 0 ? cell || '-' : (cell | currency: '₦') || '-' }}</span>
                                        }

                                        @if (j == row.length - 1 && (!cell || cell.length < 1)) {
                                            <i class="material-symbols-outlined text-success">
                                                {{ 'check_box' }}
                                            </i>
                                        }

                                        @if (j == row.length - 1 && (cell && cell.length > 0)) {
                                            <i class="material-symbols-outlined text-danger">
                                                {{ 'cancel' }}
                                            </i>
                                        }
                                    </td>
                                }
                            </tr>
                        }
                    }
                </tbody>
            </table>
        </div>
    </div>
    }

    <div class="col-md-12 mb-3 mt-3">
    </div>

    @if (zoneData && zoneData.length > 0) {
    <div class="col-md-12">
        <div class="trezo-card mb-25 border-radius d-block bg-white border-0 shadow-none">
            <div class="trezo-card-header d-flex align-items-center justify-content-between">
                <div class="trezo-card-title">
                    <h5 class="mb-0">
                        Zones
                    </h5>
                </div>
            </div>
            <div class="trezo-card-content">
                <ul class="list-group">
                    @if (zoneData && zoneData.length > 0) {
                        <div class=" d-flex flex-wrap">
                            <ng-template #zonePopOver let-item="item">
                                @for (c of item; track c) {
                                    <span>
                                        {{ c }}<br>
                                    </span>
                                }
                            </ng-template>
                        </div>
                        @for (item of zoneData; track item) {
                            <li class="list-group-item"
                                [ngClass]="{'border-danger':(item[1]?.length||0)>0}"
                                [popover]="item[1]&&item[1].length>0?zonePopOver:'Valid Entry'"
                                [popoverContext]="{item:item[1]}"
                                [triggers]="'mouseenter:mouseleave'">
                                {{ item[0] | titlecase }}
                            </li>
                        }
                    } @else {
                        <li class="list-group-item">NO ZONES</li>
                    }
                </ul>
            </div>
        </div>
    </div>
    }

    <div class="col-md-12 mb-3">
        <hr>
    </div>

    <div class="col-md-12">
        <div class="mt-4 d-flex justify-content-between">
            <button type="submit" class="btn btn-outline-dark" (click)="submit()">
                <i class="material-symbols-outlined btn-icon">
                    arrow_backward
                </i>
                Previous
            </button>



            <button type="submit" class="btn btn-outline-primary" (click)="submit()">
                Next
                <i class="material-symbols-outlined btn-icon">
                    arrow_forward
                </i>
            </button>

        </div>
    </div>
</div>
