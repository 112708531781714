<div
    class="total-revenue-card trezo-card mb-25 border-radius d-block bg-white border-0 shadow-none"
    [class.component-rtl-enabled]="themeService.isRTLEnabled()"
    [class.component-dark-theme]="themeService.isDark()"
>
    <div class="trezo-card-content">
        <div class="title">
            <div class="d-flex d-md-block d-lg-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                    <span class="d-block">
                        Total Revenue
                    </span>
                    <span class="info-badge d-inline-block">
                        +10%
                    </span>
                </div>
                <span class="days d-block">
                    Last 30 days
                </span>
            </div>
            <h5 class="mb-0">
                $165,458
            </h5>
        </div>
        <div class="chart mx-auto">
            <div id="ecommerce_total_revenue_chart"></div>
        </div>
        <ul class="ps-0 mb-0 list-unstyled">
            <li class="fashion position-relative d-flex aling-items-center justify-content-between">
                <span class="d-block">
                    Fashion
                </span>
                <span class="d-block">
                    75%
                </span>
            </li>
            <li class="others position-relative d-flex aling-items-center justify-content-between">
                <span class="d-block">
                    Others
                </span>
                <span class="d-block">
                    25%
                </span>
            </li>
        </ul>
    </div>
</div>