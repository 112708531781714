/**
 * Tickets API
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { NewAgentTicketBatchDto } from '../model/new-agent-ticket-batch-dto';
// @ts-ignore
import { PaymentValidationPojo } from '../model/payment-validation-pojo';
// @ts-ignore
import { QueryResultsTicketSalesPojo } from '../model/query-results-ticket-sales-pojo';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface CreateAgentTicketBatchRequestParams {
    newAgentTicketBatchDto: NewAgentTicketBatchDto;
}

export interface SearchTicketSalesRequestParams {
    lastUpdatedBy?: string;
    ticketBatch?: string;
    agent?: string;
    lastUpdatedAt?: string;
    ticketType?: string;
    organizationPaymentInfo?: string;
    deactivatedBy?: string;
    createdAt?: string;
    createdBy?: string;
    dateDeactivated?: string;
    id?: number;
    status?: 'ACTIVE' | 'INACTIVE';
    batchNumber?: string;
    salesToDate?: string;
    organizationId?: number;
    offset?: number;
    zone?: string;
    salesFromDate?: string;
    paymentReference?: string;
    limit?: number;
    orderColumn?: string;
    order?: 'ASC' | 'DESC';
}

export interface ValidatePaymentRequestParams {
    organizationId: number;
    paymentReference: string;
}


@Injectable({
  providedIn: 'root'
})
export class TicketSalesControllerService {

    protected basePath = 'http://tickets.serverna.com.ng/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
            if (firstBasePath != undefined) {
                basePath = firstBasePath;
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createAgentTicketBatch(requestParameters?: CreateAgentTicketBatchRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<object>;
    public createAgentTicketBatch(requestParameters?: CreateAgentTicketBatchRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<object>>;
    public createAgentTicketBatch(requestParameters?: CreateAgentTicketBatchRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<object>>;
    public createAgentTicketBatch(requestParameters?: CreateAgentTicketBatchRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const newAgentTicketBatchDto = requestParameters?.newAgentTicketBatchDto;
        if (newAgentTicketBatchDto === null || newAgentTicketBatchDto === undefined) {
            throw new Error('Required parameter newAgentTicketBatchDto was null or undefined when calling createAgentTicketBatch.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (ApiKey) required
        localVarCredential = this.configuration.lookupCredential('ApiKey');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else if (localVarHttpHeaderAcceptSelected.startsWith('blob')) {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/ticket-sales/create`;
        return this.httpClient.request<object>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: newAgentTicketBatchDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchTicketSales(requestParameters?: SearchTicketSalesRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<QueryResultsTicketSalesPojo>;
    public searchTicketSales(requestParameters?: SearchTicketSalesRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<QueryResultsTicketSalesPojo>>;
    public searchTicketSales(requestParameters?: SearchTicketSalesRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<QueryResultsTicketSalesPojo>>;
    public searchTicketSales(requestParameters?: SearchTicketSalesRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const lastUpdatedBy = requestParameters?.lastUpdatedBy;
        const ticketBatch = requestParameters?.ticketBatch;
        const agent = requestParameters?.agent;
        const lastUpdatedAt = requestParameters?.lastUpdatedAt;
        const ticketType = requestParameters?.ticketType;
        const organizationPaymentInfo = requestParameters?.organizationPaymentInfo;
        const deactivatedBy = requestParameters?.deactivatedBy;
        const createdAt = requestParameters?.createdAt;
        const createdBy = requestParameters?.createdBy;
        const dateDeactivated = requestParameters?.dateDeactivated;
        const id = requestParameters?.id;
        const status = requestParameters?.status;
        const batchNumber = requestParameters?.batchNumber;
        const salesToDate = requestParameters?.salesToDate;
        const organizationId = requestParameters?.organizationId;
        const offset = requestParameters?.offset;
        const zone = requestParameters?.zone;
        const salesFromDate = requestParameters?.salesFromDate;
        const paymentReference = requestParameters?.paymentReference;
        const limit = requestParameters?.limit;
        const orderColumn = requestParameters?.orderColumn;
        const order = requestParameters?.order;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (lastUpdatedBy !== undefined && lastUpdatedBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lastUpdatedBy, 'lastUpdatedBy');
        }
        if (ticketBatch !== undefined && ticketBatch !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>ticketBatch, 'ticketBatch');
        }
        if (agent !== undefined && agent !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>agent, 'agent');
        }
        if (lastUpdatedAt !== undefined && lastUpdatedAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lastUpdatedAt, 'lastUpdatedAt');
        }
        if (ticketType !== undefined && ticketType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>ticketType, 'ticketType');
        }
        if (organizationPaymentInfo !== undefined && organizationPaymentInfo !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>organizationPaymentInfo, 'organizationPaymentInfo');
        }
        if (deactivatedBy !== undefined && deactivatedBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>deactivatedBy, 'deactivatedBy');
        }
        if (createdAt !== undefined && createdAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>createdAt, 'createdAt');
        }
        if (createdBy !== undefined && createdBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>createdBy, 'createdBy');
        }
        if (dateDeactivated !== undefined && dateDeactivated !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dateDeactivated, 'dateDeactivated');
        }
        if (id !== undefined && id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>id, 'id');
        }
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }
        if (batchNumber !== undefined && batchNumber !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>batchNumber, 'batchNumber');
        }
        if (salesToDate !== undefined && salesToDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>salesToDate, 'salesToDate');
        }
        if (organizationId !== undefined && organizationId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>organizationId, 'organizationId');
        }
        if (offset !== undefined && offset !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>offset, 'offset');
        }
        if (zone !== undefined && zone !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>zone, 'zone');
        }
        if (salesFromDate !== undefined && salesFromDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>salesFromDate, 'salesFromDate');
        }
        if (paymentReference !== undefined && paymentReference !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paymentReference, 'paymentReference');
        }
        if (limit !== undefined && limit !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>limit, 'limit');
        }
        if (orderColumn !== undefined && orderColumn !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>orderColumn, 'orderColumn');
        }
        if (order !== undefined && order !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>order, 'order');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (ApiKey) required
        localVarCredential = this.configuration.lookupCredential('ApiKey');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else if (localVarHttpHeaderAcceptSelected.startsWith('blob')) {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/ticket-sales`;
        return this.httpClient.request<QueryResultsTicketSalesPojo>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validatePayment(requestParameters?: ValidatePaymentRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<PaymentValidationPojo>;
    public validatePayment(requestParameters?: ValidatePaymentRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PaymentValidationPojo>>;
    public validatePayment(requestParameters?: ValidatePaymentRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PaymentValidationPojo>>;
    public validatePayment(requestParameters?: ValidatePaymentRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const organizationId = requestParameters?.organizationId;
        if (organizationId === null || organizationId === undefined) {
            throw new Error('Required parameter organizationId was null or undefined when calling validatePayment.');
        }
        const paymentReference = requestParameters?.paymentReference;
        if (paymentReference === null || paymentReference === undefined) {
            throw new Error('Required parameter paymentReference was null or undefined when calling validatePayment.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (paymentReference !== undefined && paymentReference !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paymentReference, 'paymentReference');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (ApiKey) required
        localVarCredential = this.configuration.lookupCredential('ApiKey');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else if (localVarHttpHeaderAcceptSelected.startsWith('blob')) {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/ticket-sales/${this.configuration.encodeParam({name: "organizationId", value: organizationId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/validate-payment`;
        return this.httpClient.request<PaymentValidationPojo>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

}
