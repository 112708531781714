<div class="returning-customer-rate-card trezo-card mb-25 border-radius d-block bg-white border-0 shadow-none">
    <div class="trezo-card-header d-flex align-items-center justify-content-between">
        <div class="trezo-card-title">
            <h5 class="mb-0">
                Returning Customer Rate
            </h5>
        </div>
        <div class="trezo-card-subtitle">
            <div class="trezo-card-header-menu position-relative">
                <button class="menu-btn transition d-inline-block bg-transparent border-radius" (click)="toggleCardHeaderMenu()">
                    <span class="d-block position-relative">
                        Month
                    </span>
                </button>
                <ul class="dropdown-menu border-radius border-0 show" *ngIf="isCardHeaderOpen">
                    <li>
                        <button type="button" class="dropdown-item d-block w-100 position-relative transition">
                            This Day
                        </button>
                    </li>
                    <li>
                        <button type="button" class="dropdown-item d-block w-100 position-relative transition">
                            This Week
                        </button>
                    </li>
                    <li>
                        <button type="button" class="dropdown-item d-block w-100 position-relative transition">
                            This Month
                        </button>
                    </li>
                    <li>
                        <button type="button" class="dropdown-item d-block w-100 position-relative transition">
                            This Year
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="trezo-card-content">
        <div class="chart">
            <div id="ecommerce_returning_customer_rate_chart"></div>
        </div>
    </div>
</div>