import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {AlertComponent} from "ngx-bootstrap/alert";
import {
  AgentControllerService,
  AgentPojo, AppUserControllerService,
  AppUserPojo,
  BasicUserDetails,
  NewAppUserRole, OrganizationControllerService,
  OrganizationSummaryPojo, PortalUserControllerService
} from "../../../../../sdk/tickets-sdk";
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  Validators
} from "@angular/forms";
import {UserAccount} from "../../../models/account/user-account.model";
import {BsModalRef} from "ngx-bootstrap/modal";
import {ToastrService} from "ngx-toastr";
import {AuthenticationService} from "../../../services/authentication.service";
import {catchError, debounceTime, distinctUntilChanged, map} from "rxjs/operators";
import {Observable, of, take} from "rxjs";
import {CustomValidators} from "../../../shared/validators/custom-validators";
import {HttpErrorResponse} from "@angular/common/http";
import {DatePipe, NgClass, TitleCasePipe} from "@angular/common";
import {EmComponent} from "../../../shared/components/em/em.component";

@Component({
  selector: 'app-app-user-edit',
  standalone: true,
  imports: [
    AlertComponent,
    ReactiveFormsModule,
    TitleCasePipe,
    NgClass,
    DatePipe,
    EmComponent
  ],
  templateUrl: './app-user-edit.component.html',
  styleUrl: './app-user-edit.component.scss'
})
export class AppUserEditComponent implements OnInit {
  organizations: Array<OrganizationSummaryPojo> | undefined;
  form: FormGroup;
  loading = false;
  fetchingAgents = false;
  agents: Array<AgentPojo> | undefined;
  account: UserAccount | undefined;
  @Input()
  organizationId: number | undefined;
  @Input()
  agentId: number | undefined;
  onAppUserEdited = new EventEmitter<any>();
  fetchedExistingUser: BasicUserDetails;
  appUserId: number | undefined;
  appUser: AppUserPojo | undefined;
  fetchingAppUser = false;
  selectedRoles: Array<NewAppUserRole> = [];


  constructor(
      private appUserControllerService: AppUserControllerService,
      private agentControllerService: AgentControllerService,
      private organizationControllerService: OrganizationControllerService,
      private portalUserControllerService: PortalUserControllerService,
      private formBuilder: FormBuilder,
      private bsModalRef: BsModalRef,
      private toastrService: ToastrService,
      private authenticationService: AuthenticationService
  ) {
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      firstName: ['', [Validators.required, CustomValidators.minLength(2)]],
      lastName: ['', [Validators.required, CustomValidators.minLength(2)]],
      username: ['', [Validators.required, CustomValidators.minLength(2)]],
      phoneNumber: ['', [Validators.required, CustomValidators.minLength(2), CustomValidators.nigerianNumber], this.createValidator(this.portalUserControllerService)],
      organizationId: ['', []],
      agentId: ['', []],
      role: ['', []],
      selectedRoles: [[], [Validators.required]],
      userId: ['', []]
    })
    this.authenticationService.getAccount().subscribe(value => {
      this.account = value;
      if (this.account.accountType == 'ADMIN') {
        this.organizationControllerService.searchOrganizations({
          limit: 200,
          approvalStatus: 'APPROVED'
        })
            .subscribe(value => {
              this.organizations = value.results;
            }, error => {
              if (error.error && typeof error.error == 'object' && error.error.message) {
                this.toastrService.error(error.error.message);
                return;
              }
              this.toastrService.error('Could not fetch Schemes. Please check your internet connection.')
            });
        this.form.get('organizationId').valueChanges.pipe(distinctUntilChanged()).subscribe(value => {
          this.organizationId = value;
          this.form.get('agentId').patchValue('');
          this.fetchAgents(value);
        });
      } else if (this.account.accountType == 'ORGANIZATION') {
        if (this.organizationId) {
          this.form.get('organizationId').patchValue(this.organizationId)
        }
        if (this.agentId) {
          this.form.get('agentId').patchValue(this.agentId);
        }
        if (this.account.roles.find(role => role == 'ADMIN')) {
          this.fetchAgents(this.organizationId);
        }
      }
      this.fetchAppUser();
    });
  }

  fetchAgents(orgId: number) {
    if (!orgId) {
      this.agents = [];
      return;
    }
    this.fetchingAgents = true;
    this.agentControllerService.searchAgent({organizationId: orgId, limit: 200, status: 'ACTIVE'})
        .subscribe(value => {
          this.fetchingAgents = false;
          this.agents = value.results;
        }, error => {
          this.fetchingAgents = false;
          if (error.error && typeof error.error == 'object' && error.error.message) {
            this.toastrService.error(error.error.message);
            return;
          }
          this.toastrService.error('Could not fetch agents. Please check your internet connection.')
        });
  }

  onCancel() {
    this.bsModalRef.hide();
  }

  onUpdate() {
    if (this.form.invalid) {
      return;
    }
    this.loading = true;
    this.appUserControllerService.editAppUser({
      editAppUserDto: {
        ...this.form.value,
        roles: this.form.get('selectedRoles').value
      }, id: this.appUser.id
    })
        .subscribe(value => {
          this.loading = false;
          this.toastrService.success('App user updated successfully')
          this.onAppUserEdited.emit(value);
          this.bsModalRef.hide();
        }, error => {
          this.loading = false;
          if (error.error && typeof error.error == 'object' && error.error.message) {
            this.toastrService.error(error.error.message);
            return;
          }
          this.toastrService.error('Could not edit app user. Please check your internet connection.')
        });
  }

  toggle(value: string) {
    let roles = (this.form.get('roles').value as Array<string>);
    if (roles.find(role => role == value)) {
      roles = roles.filter(role => value != role);
    } else {
      roles.push(value);
    }
    this.form.get('roles').patchValue([...new Set(roles)])
  }

  get canSelectScheme() {
    return this.account && (this.account.accountType == 'ADMIN');
  }

  get canSelectAgent() {
    return this.account && (this.account.accountType == 'ADMIN' || this.account.accountType == 'ORGANIZATION') && this.authenticationService.hasRole('ADMIN');
  }

  createValidator(portalUserControllerService: PortalUserControllerService): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      console.log('=====>', control.value)
      if (!control.value) {
        return of(null);
      }
      return portalUserControllerService.getUserDetailsByPhoneNumber({phoneNumber: control.value})
          .pipe()
          .pipe(
              debounceTime(500),
              take(1),
              map(value => {
                this.fetchedExistingUser = value;
                if (this.fetchedExistingUser) {
                  this.form.patchValue({... this.fetchedExistingUser})
                } else {
                  this.form.get('firstName').patchValue('');
                  this.form.get('lastName').patchValue('');
                  this.form.get('username').patchValue('');
                  this.form.get('userId').patchValue('');
                }
                return null;
              }),
              catchError((err: HttpErrorResponse, caught) => {
                return of({'nointernet': true});
              })
          );
    };
  }

  removeRole(i: number) {
    let roles = [...this.selectedRoles];
    let tempRole = []
    console.log(roles)
    for (let j = 0; j < roles.length; j++) {
      if (j != i) {
        tempRole.push(roles[j])
      }
    }
    this.selectedRoles = [];
    this.selectedRoles.push(...tempRole);
    this.form.get('selectedRoles').patchValue(this.selectedRoles)
  }

  addRole() {
    let organizationId = this.form.get('organizationId').value;
    let agentId = this.form.get('agentId').value;
    let role = this.form.get('role').value;
    if (!organizationId || !agentId || !role) {
      if (!organizationId) {
        console.log('organizaation is null')
        this.form.get('organizationId').setErrors({required: true});
        this.form.get('organizationId').markAsDirty()
      }
      if (!agentId) {
        this.form.get('agentId').setErrors({required: true});
        this.form.get('agentId').markAsDirty()
      }
      if (!role) {
        this.form.get('role').setErrors({required: true});
        this.form.get('role').markAsDirty()
      }
      this.form.markAllAsTouched();
      this.form.markAsDirty()
      return;
    }

    let selectedRole: NewAppUserRole = {
      role: role,
      agentId: agentId,
      organizationId: organizationId,
      organizationName: this.organizations?.find(value => value.id == organizationId)?.name,
      agentName: this.agents?.find(value => value.id == agentId)?.portalUser?.displayName
    };
    if (this.hasRole(selectedRole)) {
      this.toastrService.info('Duplicate roles selected');
    } else {
      this.selectedRoles.push(selectedRole);
    }
    if (!this.organizationId) {
      this.form.get('organizationId').setValue('');
    }
    if (!this.agentId) {
      this.form.get('agentId').setValue('');
    }
    this.form.get('selectedRoles').patchValue(this.selectedRoles);
    this.form.get('organizationId').markAsPristine()
    this.form.get('agentId').markAsPristine()
    this.form.get('role').setValue('');
    this.form.get('role').markAsPristine()
  }

  private hasRole(role: NewAppUserRole) {
    return this.selectedRoles.find(value => value.role == role.role && value.organizationId == role.organizationId && value.agentId == role.agentId) != null;
  }

  private fetchAppUser() {
    this.fetchingAppUser = true;
    this.appUserControllerService.getAppUserDetails({id: this.appUserId})
        .subscribe(value => {
          this.fetchingAppUser = false;
          this.appUser = value;
          this.form.get('firstName').patchValue(this.appUser?.firstName);
          this.form.get('lastName').patchValue(this.appUser?.lastName);
          this.form.get('username').patchValue(this.appUser?.username);
          this.form.get('phoneNumber').patchValue(this.appUser?.phoneNumber);
          //Ensure schemes and agents can only see the app user roles belonging to them
          this.selectedRoles = this.appUser?.roles?.filter(role => {
            if (!this.organizationId && !this.agentId) {
              return true;
            }
            if (this.agentId) {
              return role.agentId == this.agentId && role.organizationId == this.organizationId;
            } else {
              return role.organizationId == this.organizationId;
            }
          })
              .map((value1, index) => {
                return {
                  role: value1.role,
                  agentName: value1.agentName,
                  organizationName: value1.organizationName,
                  organizationId: value1.organizationId,
                  agentId: value1.agentId
                } as NewAppUserRole;
              }) || [];
        }, error => {
          this.fetchingAppUser = false;
          if (error.error && typeof error.error == 'object' && error.error.message) {
            this.toastrService.error(error.error.message);
            return;
          }
          this.toastrService.error('Could not fetch user. Please check your internet connection.')
        })
  }
}
