<div
    [class.card-without-border-radius]="themeService.isCardWithoutBorderRadius()"
    [class.card-with-box-shadow]="themeService.isCardWithBoxShadow()"
    class="header-area bg-white border-bottom-radius transition"
    [ngClass]="{'active': isSidebarToggled, 'sticky': isSticky}"
    [class.component-rtl-enabled]="themeService.isRTLEnabled()"
    [class.right-sidebar]="themeService.isRightSidebar()"
    [class.hide-sidebar]="themeService.isHideSidebar()"
    [class.card-borderd]="themeService.isCardBordered()"
    [class.dark-header]="themeService.isHeaderDark()"
>
    <div class="d-md-flex align-items-center justify-content-between">
        <div class="header-left-side d-flex align-items-center">
            <div
                class="burger-menu transition position-relative lh-1"
                [ngClass]="{'active': isSidebarToggled}"
                (click)="toggle()"
            >
                <i class="material-symbols-outlined">
                    menu
                </i>
            </div>

        </div>
        <ul class="header-right-side d-flex align-items-center m-0 p-0 list-unstyled">
            <li class="position-relative">
                <button
                    (click)="toggleTheme()"
                    class="dark-swtich-btn bg-transparent d-inline-block p-0 border-0 lh-1 position-relative"
                >
                    <i class="material-symbols-outlined">
                        light_mode
                    </i>
                </button>
            </li>
            <li class="position-relative">
                <button
                    (click)="toggleFullscreen()"
                    class="fullscreen-btn bg-transparent transition p-0 border-0 d-inline-block position-relative lh-1"
                >
                    <i class="material-symbols-outlined">
                        {{ isFullscreen ? 'fullscreen_exit' : 'fullscreen' }}
                    </i>
                </button>
            </li>
            <li class="position-relative profile-menu">
                <button
                    type="button"
                    (click)="toggleProfileDropdown()"
                    class="profile-menu-btn border-0 bg-transparent position-relative"
                >
                    <span class="text-center profile-avatar">
                        {{ initials }}
                    </span>
                    <span class="fw-semibold text-black">
                        {{ user.firstName }}
                    </span>
                </button>
                <div class="profile-menu-dropdown bg-white border-radius" *ngIf="isProfileDropdownOpen">
                    <div class="admin d-flex align-items-center">
                         <span class="text-center profile-avatar">
                            {{ initials }}
                        </span>
                        <div>
                            <span class="d-block text-black fw-medium">
                                {{ user.firstName + ' ' + user.lastName }}
                            </span>
                            <span class="d-block">
                                Marketing Manager
                            </span>
                        </div>
                    </div>
                    <ul class="list p-0 mt-0 mb-0 list-unstyled">
                        <li>
                            <a class="d-block position-relative" (click)="logout()">
                                <i class="material-symbols-outlined">
                                    logout
                                </i>
                                Logout
                            </a>
                        </li>
                    </ul>
                </div>
            </li>
        </ul>
    </div>
</div>
