/**
 * Tickets API
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { BillGenerationDto } from '../model/bill-generation-dto';
// @ts-ignore
import { BillPojo } from '../model/bill-pojo';
// @ts-ignore
import { GenerateBillPojo } from '../model/generate-bill-pojo';
// @ts-ignore
import { QueryResultsBillPojo } from '../model/query-results-bill-pojo';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface GenerateBillRequestParams {
    billGenerationDto: BillGenerationDto;
}

export interface GetBillRequestParams {
    invoiceNumber: string;
}

export interface SearchBillsRequestParams {
    biller?: string;
    agentId?: number;
    datePaid?: string;
    dueDate?: string;
    description?: string;
    billItems?: string;
    organizationId?: number;
    createdAt?: string;
    qrCode?: string;
    reasonForDisapproval?: string;
    customerEmail?: string;
    lastReviewer?: string;
    id?: number;
    paymentStatus?: 'PAID' | 'PENDING' | 'PARTIAL';
    customerAddress?: string;
    approvalStatus?: 'APPROVED' | 'PENDING' | 'DISAPPROVED';
    amount?: number;
    billType?: 'TICKET_BATCH';
    customerName?: string;
    lastReviewDate?: string;
    paymentInvoice?: string;
    billNumber?: string;
    customerPhoneNumber?: string;
    customer?: string;
    offset?: number;
    limit?: number;
    createdBefore?: string;
    createdAfter?: string;
    orderColumn?: string;
    order?: 'ASC' | 'DESC';
}


@Injectable({
  providedIn: 'root'
})
export class BillControllerService {

    protected basePath = 'http://tickets.serverna.com.ng/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
            if (firstBasePath != undefined) {
                basePath = firstBasePath;
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Generate Bill
     * A user can generate Bill
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generateBill(requestParameters?: GenerateBillRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<GenerateBillPojo>;
    public generateBill(requestParameters?: GenerateBillRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<GenerateBillPojo>>;
    public generateBill(requestParameters?: GenerateBillRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<GenerateBillPojo>>;
    public generateBill(requestParameters?: GenerateBillRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const billGenerationDto = requestParameters?.billGenerationDto;
        if (billGenerationDto === null || billGenerationDto === undefined) {
            throw new Error('Required parameter billGenerationDto was null or undefined when calling generateBill.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (ApiKey) required
        localVarCredential = this.configuration.lookupCredential('ApiKey');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else if (localVarHttpHeaderAcceptSelected.startsWith('blob')) {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/bills`;
        return this.httpClient.request<GenerateBillPojo>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: billGenerationDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Bill
     * Get details of a Bill
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBill(requestParameters?: GetBillRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<BillPojo>;
    public getBill(requestParameters?: GetBillRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<BillPojo>>;
    public getBill(requestParameters?: GetBillRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<BillPojo>>;
    public getBill(requestParameters?: GetBillRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const invoiceNumber = requestParameters?.invoiceNumber;
        if (invoiceNumber === null || invoiceNumber === undefined) {
            throw new Error('Required parameter invoiceNumber was null or undefined when calling getBill.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (ApiKey) required
        localVarCredential = this.configuration.lookupCredential('ApiKey');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else if (localVarHttpHeaderAcceptSelected.startsWith('blob')) {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/bills/${this.configuration.encodeParam({name: "invoiceNumber", value: invoiceNumber, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<BillPojo>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search Bills
     * A user can search for Bills
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchBills(requestParameters?: SearchBillsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<QueryResultsBillPojo>;
    public searchBills(requestParameters?: SearchBillsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<QueryResultsBillPojo>>;
    public searchBills(requestParameters?: SearchBillsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<QueryResultsBillPojo>>;
    public searchBills(requestParameters?: SearchBillsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const biller = requestParameters?.biller;
        const agentId = requestParameters?.agentId;
        const datePaid = requestParameters?.datePaid;
        const dueDate = requestParameters?.dueDate;
        const description = requestParameters?.description;
        const billItems = requestParameters?.billItems;
        const organizationId = requestParameters?.organizationId;
        const createdAt = requestParameters?.createdAt;
        const qrCode = requestParameters?.qrCode;
        const reasonForDisapproval = requestParameters?.reasonForDisapproval;
        const customerEmail = requestParameters?.customerEmail;
        const lastReviewer = requestParameters?.lastReviewer;
        const id = requestParameters?.id;
        const paymentStatus = requestParameters?.paymentStatus;
        const customerAddress = requestParameters?.customerAddress;
        const approvalStatus = requestParameters?.approvalStatus;
        const amount = requestParameters?.amount;
        const billType = requestParameters?.billType;
        const customerName = requestParameters?.customerName;
        const lastReviewDate = requestParameters?.lastReviewDate;
        const paymentInvoice = requestParameters?.paymentInvoice;
        const billNumber = requestParameters?.billNumber;
        const customerPhoneNumber = requestParameters?.customerPhoneNumber;
        const customer = requestParameters?.customer;
        const offset = requestParameters?.offset;
        const limit = requestParameters?.limit;
        const createdBefore = requestParameters?.createdBefore;
        const createdAfter = requestParameters?.createdAfter;
        const orderColumn = requestParameters?.orderColumn;
        const order = requestParameters?.order;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (biller !== undefined && biller !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>biller, 'biller');
        }
        if (agentId !== undefined && agentId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>agentId, 'agentId');
        }
        if (datePaid !== undefined && datePaid !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>datePaid, 'datePaid');
        }
        if (dueDate !== undefined && dueDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dueDate, 'dueDate');
        }
        if (description !== undefined && description !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>description, 'description');
        }
        if (billItems !== undefined && billItems !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>billItems, 'billItems');
        }
        if (organizationId !== undefined && organizationId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>organizationId, 'organizationId');
        }
        if (createdAt !== undefined && createdAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>createdAt, 'createdAt');
        }
        if (qrCode !== undefined && qrCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>qrCode, 'qrCode');
        }
        if (reasonForDisapproval !== undefined && reasonForDisapproval !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>reasonForDisapproval, 'reasonForDisapproval');
        }
        if (customerEmail !== undefined && customerEmail !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>customerEmail, 'customerEmail');
        }
        if (lastReviewer !== undefined && lastReviewer !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lastReviewer, 'lastReviewer');
        }
        if (id !== undefined && id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>id, 'id');
        }
        if (paymentStatus !== undefined && paymentStatus !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paymentStatus, 'paymentStatus');
        }
        if (customerAddress !== undefined && customerAddress !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>customerAddress, 'customerAddress');
        }
        if (approvalStatus !== undefined && approvalStatus !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>approvalStatus, 'approvalStatus');
        }
        if (amount !== undefined && amount !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>amount, 'amount');
        }
        if (billType !== undefined && billType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>billType, 'billType');
        }
        if (customerName !== undefined && customerName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>customerName, 'customerName');
        }
        if (lastReviewDate !== undefined && lastReviewDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lastReviewDate, 'lastReviewDate');
        }
        if (paymentInvoice !== undefined && paymentInvoice !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paymentInvoice, 'paymentInvoice');
        }
        if (billNumber !== undefined && billNumber !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>billNumber, 'billNumber');
        }
        if (customerPhoneNumber !== undefined && customerPhoneNumber !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>customerPhoneNumber, 'customerPhoneNumber');
        }
        if (customer !== undefined && customer !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>customer, 'customer');
        }
        if (offset !== undefined && offset !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>offset, 'offset');
        }
        if (limit !== undefined && limit !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>limit, 'limit');
        }
        if (createdBefore !== undefined && createdBefore !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>createdBefore, 'createdBefore');
        }
        if (createdAfter !== undefined && createdAfter !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>createdAfter, 'createdAfter');
        }
        if (orderColumn !== undefined && orderColumn !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>orderColumn, 'orderColumn');
        }
        if (order !== undefined && order !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>order, 'order');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (ApiKey) required
        localVarCredential = this.configuration.lookupCredential('ApiKey');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else if (localVarHttpHeaderAcceptSelected.startsWith('blob')) {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/bills`;
        return this.httpClient.request<QueryResultsBillPojo>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

}
