/**
 * Tickets API
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { QueryResultsTicketIssuancePojo } from '../model/query-results-ticket-issuance-pojo';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface SearchTicketIssuancesRequestParams {
    revenueIdentificationNumber?: string;
    appUser?: string;
    lastUpdatedBy?: string;
    ticket?: string;
    lastUpdatedAt?: string;
    ticketTypeId?: number;
    deactivatedBy?: string;
    createdAt?: string;
    recipientPhoneNumber?: string;
    zone?: string;
    createdBy?: string;
    ticketTypeCode?: string;
    recipientName?: string;
    vehicleNumber?: string;
    dateDeactivated?: string;
    id?: number;
    appUserId?: number;
    status?: 'ACTIVE' | 'INACTIVE';
    organizationId?: number;
    offset?: number;
    limit?: number;
    orderColumn?: string;
    order?: 'ASC' | 'DESC';
}


@Injectable({
  providedIn: 'root'
})
export class TicketIssuanceControllerService {

    protected basePath = 'http://tickets.serverna.com.ng/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
            if (firstBasePath != undefined) {
                basePath = firstBasePath;
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchTicketIssuances(requestParameters?: SearchTicketIssuancesRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<QueryResultsTicketIssuancePojo>;
    public searchTicketIssuances(requestParameters?: SearchTicketIssuancesRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<QueryResultsTicketIssuancePojo>>;
    public searchTicketIssuances(requestParameters?: SearchTicketIssuancesRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<QueryResultsTicketIssuancePojo>>;
    public searchTicketIssuances(requestParameters?: SearchTicketIssuancesRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const revenueIdentificationNumber = requestParameters?.revenueIdentificationNumber;
        const appUser = requestParameters?.appUser;
        const lastUpdatedBy = requestParameters?.lastUpdatedBy;
        const ticket = requestParameters?.ticket;
        const lastUpdatedAt = requestParameters?.lastUpdatedAt;
        const ticketTypeId = requestParameters?.ticketTypeId;
        const deactivatedBy = requestParameters?.deactivatedBy;
        const createdAt = requestParameters?.createdAt;
        const recipientPhoneNumber = requestParameters?.recipientPhoneNumber;
        const zone = requestParameters?.zone;
        const createdBy = requestParameters?.createdBy;
        const ticketTypeCode = requestParameters?.ticketTypeCode;
        const recipientName = requestParameters?.recipientName;
        const vehicleNumber = requestParameters?.vehicleNumber;
        const dateDeactivated = requestParameters?.dateDeactivated;
        const id = requestParameters?.id;
        const appUserId = requestParameters?.appUserId;
        const status = requestParameters?.status;
        const organizationId = requestParameters?.organizationId;
        const offset = requestParameters?.offset;
        const limit = requestParameters?.limit;
        const orderColumn = requestParameters?.orderColumn;
        const order = requestParameters?.order;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (revenueIdentificationNumber !== undefined && revenueIdentificationNumber !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>revenueIdentificationNumber, 'revenueIdentificationNumber');
        }
        if (appUser !== undefined && appUser !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>appUser, 'appUser');
        }
        if (lastUpdatedBy !== undefined && lastUpdatedBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lastUpdatedBy, 'lastUpdatedBy');
        }
        if (ticket !== undefined && ticket !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>ticket, 'ticket');
        }
        if (lastUpdatedAt !== undefined && lastUpdatedAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lastUpdatedAt, 'lastUpdatedAt');
        }
        if (ticketTypeId !== undefined && ticketTypeId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>ticketTypeId, 'ticketTypeId');
        }
        if (deactivatedBy !== undefined && deactivatedBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>deactivatedBy, 'deactivatedBy');
        }
        if (createdAt !== undefined && createdAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>createdAt, 'createdAt');
        }
        if (recipientPhoneNumber !== undefined && recipientPhoneNumber !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>recipientPhoneNumber, 'recipientPhoneNumber');
        }
        if (zone !== undefined && zone !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>zone, 'zone');
        }
        if (createdBy !== undefined && createdBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>createdBy, 'createdBy');
        }
        if (ticketTypeCode !== undefined && ticketTypeCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>ticketTypeCode, 'ticketTypeCode');
        }
        if (recipientName !== undefined && recipientName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>recipientName, 'recipientName');
        }
        if (vehicleNumber !== undefined && vehicleNumber !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>vehicleNumber, 'vehicleNumber');
        }
        if (dateDeactivated !== undefined && dateDeactivated !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dateDeactivated, 'dateDeactivated');
        }
        if (id !== undefined && id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>id, 'id');
        }
        if (appUserId !== undefined && appUserId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>appUserId, 'appUserId');
        }
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }
        if (organizationId !== undefined && organizationId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>organizationId, 'organizationId');
        }
        if (offset !== undefined && offset !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>offset, 'offset');
        }
        if (limit !== undefined && limit !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>limit, 'limit');
        }
        if (orderColumn !== undefined && orderColumn !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>orderColumn, 'orderColumn');
        }
        if (order !== undefined && order !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>order, 'order');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (ApiKey) required
        localVarCredential = this.configuration.lookupCredential('ApiKey');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else if (localVarHttpHeaderAcceptSelected.startsWith('blob')) {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/ticket-issuances`;
        return this.httpClient.request<QueryResultsTicketIssuancePojo>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

}
