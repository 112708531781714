/**
 * Tickets API
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PortalAccount } from './portal-account';
import { PaymentInvoice } from './payment-invoice';
import { Customer } from './customer';
import { BillItem } from './bill-item';
import { BinaryData } from './binary-data';
import { PortalUser } from './portal-user';


export interface Bill { 
    id?: number;
    createdAt?: string;
    lastUpdatedAt?: string;
    createdBy?: PortalUser;
    lastUpdatedBy?: PortalUser;
    status?: Bill.StatusEnum;
    dateDeactivated?: string;
    deactivatedBy?: PortalUser;
    amount?: number;
    billNumber?: string;
    datePaid?: string;
    customerName?: string;
    customerEmail?: string;
    customerPhoneNumber?: string;
    paymentStatus?: Bill.PaymentStatusEnum;
    description?: string;
    dueDate?: string;
    customerAddress?: string;
    billType?: Bill.BillTypeEnum;
    approvalStatus?: Bill.ApprovalStatusEnum;
    reasonForDisapproval?: string;
    lastReviewDate?: string;
    qrCode?: BinaryData;
    paymentInvoice?: PaymentInvoice;
    customer?: Customer;
    lastReviewer?: PortalUser;
    biller?: PortalAccount;
    billItems?: Array<BillItem>;
}
export namespace Bill {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE';
    export const StatusEnum = {
        ACTIVE: 'ACTIVE' as StatusEnum,
        INACTIVE: 'INACTIVE' as StatusEnum
    };
    export type PaymentStatusEnum = 'PAID' | 'PENDING' | 'PARTIAL';
    export const PaymentStatusEnum = {
        PAID: 'PAID' as PaymentStatusEnum,
        PENDING: 'PENDING' as PaymentStatusEnum,
        PARTIAL: 'PARTIAL' as PaymentStatusEnum
    };
    export type BillTypeEnum = 'TICKET_BATCH';
    export const BillTypeEnum = {
        TICKET_BATCH: 'TICKET_BATCH' as BillTypeEnum
    };
    export type ApprovalStatusEnum = 'APPROVED' | 'PENDING' | 'DISAPPROVED';
    export const ApprovalStatusEnum = {
        APPROVED: 'APPROVED' as ApprovalStatusEnum,
        PENDING: 'PENDING' as ApprovalStatusEnum,
        DISAPPROVED: 'DISAPPROVED' as ApprovalStatusEnum
    };
}


