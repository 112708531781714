<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Schemes
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 p-0">
        <li class="breadcrumb-item d-inline-block position-relative">
            <a routerLink="/dashboard" class="d-inline-block position-relative">
                <i class="material-symbols-outlined">
                    home
                </i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item d-inline-block position-relative">
            Schemes
        </li>
        <li class="breadcrumb-item d-inline-block position-relative">
            Create Scheme
        </li>
    </ol>
</div>

<div
        class="product-development-card trezo-card mb-25 border-radius d-block bg-white border-0 shadow-none"
        [class.component-rtl-enabled]="themeService.isRTLEnabled()"
>
    <div class="trezo-card-header d-flex align-items-center justify-content-between">
        <div class="trezo-card-title">
            <h5 class="mb-0">
                Create Scheme
            </h5>
        </div>
    </div>
    <div class="trezo-card-content">
        <div class="info bg-primary border-radius">
            <div class="d-sm-flex align-items-center justify-content-between">
                @for (tab of tabHeaders; track tab; let i = $index) {
                    <div class="item d-flex align-items-center">
                        <div class="icon text-center rounded-circle text-white position-relative">
                            <i class="material-symbols-outlined">
                                {{ tab.icon }}
                            </i>
                        </div>
                        <div>
                            <span class="info-title d-block text-white">
                                {{ tab.description }}
                            </span>
                            <span class="info-sub-title fw-semibold d-block text-white">
                                {{ tab.name }}
                            </span>
                        </div>
                    </div>
                }
            </div>
        </div>
        <div class="mt-3 mx-5">
            @switch (step) {
                @case (1) {
                    <organization-form
                            [data]="data"
                            (gotoNextStep)="goToNext($event)"
                            (locationData)="getLocation($event)"
                    />
                }
                @case (2) {
                    <tariff-form
                            [data]="data"
                            (gotoNextStep)="goToNext($event)"
                            (goToPreviousStep)="goToPreviousStep($event)"
                    />
                }
                @case (3) {
                    <organization-summary
                            [data]="data"
                            [locationData]="locationData"
                            (gotoNextStep)="goToNext($event)"
                            (goToPreviousStep)="goToPreviousStep($event)"
                    />
                }
                @case (4) {
                    <h1>Success Creation</h1>
                }
            }
        </div>

    </div>
</div>
