/**
 * Tickets API
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TicketType } from './ticket-type';
import { TicketBatchRequest } from './ticket-batch-request';
import { PortalUser } from './portal-user';


export interface TicketBatch { 
    id?: number;
    createdAt?: string;
    lastUpdatedAt?: string;
    createdBy?: PortalUser;
    lastUpdatedBy?: PortalUser;
    status?: TicketBatch.StatusEnum;
    dateDeactivated?: string;
    deactivatedBy?: PortalUser;
    batchNumber?: string;
    productionDate?: string;
    batchSoldOut?: boolean;
    numberOfTicketsInBatch?: number;
    unitAmount?: number;
    ticketType?: TicketType;
    ticketBatchRequest?: TicketBatchRequest;
}
export namespace TicketBatch {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE';
    export const StatusEnum = {
        ACTIVE: 'ACTIVE' as StatusEnum,
        INACTIVE: 'INACTIVE' as StatusEnum
    };
}


