import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {TicketTypeDto} from "../../../../../sdk/tickets-sdk";
import {PageChangedEvent, PaginationComponent} from "ngx-bootstrap/pagination";
import {CurrencyPipe} from "@angular/common";
import {FormsModule} from "@angular/forms";

@Component({
  selector: 'agent-ticket-config',
  standalone: true,
  imports: [
    PaginationComponent,
    CurrencyPipe,
    FormsModule
  ],
  templateUrl: './agent-ticket-config.component.html',
  styleUrl: './agent-ticket-config.component.scss'
})
export class AgentTicketConfigComponent implements OnInit, OnChanges {

  @Input() agentTicketsConfig: Array<TicketTypeDto>;
  @Input() fetchingTickets: boolean;
  activeIndex: number;
  isTicketCostEdit = false;
  ticketPrice = 0;
  page: number = 0;
  perPage: number = 10;
  agentTicketsConfigSearch: Array<TicketTypeDto>;
  ticketConfigErrorMessage: string;
  hasTicketConfigError: boolean;


  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.agentTicketsConfig && !changes.agentTicketsConfig.firstChange) {
      this.agentTicketsConfigSearch = changes.agentTicketsConfig.currentValue.length > 10
          ? changes.agentTicketsConfig.currentValue.slice(0, this.perPage) : this.agentTicketsConfig;
    }

  }

  onPageChange($event: PageChangedEvent) {
    this.isTicketCostEdit = false;
    this.activeIndex = undefined;
    this.page = ($event.page -1)
    let start = this.page * $event.itemsPerPage;
    let end = (this.page * $event.itemsPerPage) + $event.itemsPerPage;
    this.agentTicketsConfigSearch = this.agentTicketsConfig.slice(start,  end)
  }

  editTicket(index: number) {
    this.isTicketCostEdit = true;
    this.activeIndex = index;
    this.hasTicketConfigError = false;
    this.ticketPrice = this.agentTicketsConfig[this.getActualTicketIndex(index)].buyingPricePerUnit;
  }

  editDone(index: number) {
    if (this.hasTicketConfigError) {
      return;
    }
    this.isTicketCostEdit = false;
    this.activeIndex = undefined;
    this.agentTicketsConfig[this.getActualTicketIndex(index)].buyingPricePerUnit = this.ticketPrice;
  }

  resetCost(index: number) {
    this.ticketPrice = this.agentTicketsConfig[this.getActualTicketIndex(index)].defaultBuyingPricePerUnit;
    this.agentTicketsConfig[this.getActualTicketIndex(index)].buyingPricePerUnit = this.agentTicketsConfig[this.getActualTicketIndex(index)].defaultBuyingPricePerUnit;
    this.hasTicketConfigError = false;
  }

  setTicketAmount($event, index: number) {
    this.checkError(this.agentTicketsConfig[this.getActualTicketIndex(index)].defaultSellingPricePerUnit,
        this.ticketPrice)
    if (this.hasTicketConfigError) {
      return;
    }
    this.agentTicketsConfig[this.getActualTicketIndex(index)].buyingPricePerUnit = this.ticketPrice;
  }

  getActualTicketIndex(index: number) {
    return (this.page * this.perPage) + index;
  }

  checkError(selling: number, buying: number) {
    switch (true) {
      case  buying == null || buying == undefined:
        this.hasTicketConfigError = true;
        this.ticketConfigErrorMessage = 'Buying price cannot be empty';
        break;
      case  1/buying === -Infinity: // check if buying is -0
        this.hasTicketConfigError = true;
        this.ticketConfigErrorMessage = 'Buying price cannot be negative';
        break;
      case  buying < 0:
        this.hasTicketConfigError = true;
        this.ticketConfigErrorMessage = 'Buying price cannot be negative';
        break;
      case buying > selling:
        this.hasTicketConfigError = true;
        this.ticketConfigErrorMessage = 'Buying price cannot be greater than Selling price';
        break;
      default:
        this.hasTicketConfigError = false;
        this.ticketConfigErrorMessage = '';
        break;
    }
  }

}
