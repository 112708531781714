/**
 * Tickets API
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Zone } from './zone';


export interface OrganizationWithMembershipStatus { 
    id?: number;
    name?: string;
    organizationType?: OrganizationWithMembershipStatus.OrganizationTypeEnum;
    adminName?: string;
    adminEmail?: string;
    adminPhoneNumber?: string;
    location?: string;
    bannerId?: number;
    approvalStatus?: OrganizationWithMembershipStatus.ApprovalStatusEnum;
    createdAt?: string;
    membershipStatus?: OrganizationWithMembershipStatus.MembershipStatusEnum;
    zone?: Zone;
}
export namespace OrganizationWithMembershipStatus {
    export type OrganizationTypeEnum = 'LOCAL_GOVERNMENT' | 'FEDERAL_GOVERNMENT' | 'STATE_GOVERNMENT' | 'OTHER';
    export const OrganizationTypeEnum = {
        LOCAL_GOVERNMENT: 'LOCAL_GOVERNMENT' as OrganizationTypeEnum,
        FEDERAL_GOVERNMENT: 'FEDERAL_GOVERNMENT' as OrganizationTypeEnum,
        STATE_GOVERNMENT: 'STATE_GOVERNMENT' as OrganizationTypeEnum,
        OTHER: 'OTHER' as OrganizationTypeEnum
    };
    export type ApprovalStatusEnum = 'APPROVED' | 'PENDING' | 'DISAPPROVED';
    export const ApprovalStatusEnum = {
        APPROVED: 'APPROVED' as ApprovalStatusEnum,
        PENDING: 'PENDING' as ApprovalStatusEnum,
        DISAPPROVED: 'DISAPPROVED' as ApprovalStatusEnum
    };
    export type MembershipStatusEnum = 'ACTIVE' | 'INACTIVE';
    export const MembershipStatusEnum = {
        ACTIVE: 'ACTIVE' as MembershipStatusEnum,
        INACTIVE: 'INACTIVE' as MembershipStatusEnum
    };
}


