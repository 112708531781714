<!-- Page Banner -->
<div class="page-banner-area text-center">
    <div class="container position-relative z-1">
        <h1 class="mb-0">
            Frequently Asked Questions
        </h1>
        <div class="shape1">
            <img src="images/front-pages/shape3.png" alt="shape3">
        </div>
        <div class="shape2">
            <img src="images/front-pages/shape5.png" alt="shape5">
        </div>
    </div>
</div>

<div class="position-relative z-2">
    <app-fp-faq />
</div>

<app-fp-cta />