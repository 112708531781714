import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CustomizerSettingsService} from "../../../../customizer-settings/customizer-settings.service";
import {
    AbstractControl,
    FormBuilder, FormControl,
    FormGroup,
    ReactiveFormsModule,
    ValidationErrors,
    Validators
} from "@angular/forms";
import {BsModalService} from "ngx-bootstrap/modal";
import {ImageCropComponent} from "../../../../shared/image-crop/image-crop.component";
import {base64ToFile} from "ngx-image-cropper";
import {ToastrService} from "ngx-toastr";
import {SharedModule} from "../../../../shared/shared.module";
import {CustomValidators} from "../../../../shared/validators/custom-validators";
import {
    Country,
    Lga,
    LocationService, NewOrganizationDto, Organization, OrganizationControllerService,
    PortalUserControllerService,
    State
} from "../../../../../../sdk/tickets-sdk";
import {catchError, debounceTime, distinctUntilChanged, map} from "rxjs/operators";
import {Observable, of, take} from "rxjs";
import {HttpErrorResponse} from "@angular/common/http";
import {Utils} from "../../../../shared/utils/utils";
import {RemoveUnderscorePipe} from "../../../../shared/pipes/remove-underscore.pipe";
import {NgClass, TitleCasePipe} from "@angular/common";
import {NgxDropzoneChangeEvent, NgxDropzoneModule} from "ngx-dropzone";
import TypeEnum = Organization.TypeEnum;
import {EmComponent} from "../../../../shared/components/em/em.component";

@Component({
    selector: 'organization-form',
    standalone: true,
    templateUrl: './organization-form.component.html',
    imports: [ReactiveFormsModule, SharedModule, RemoveUnderscorePipe, TitleCasePipe, NgClass, NgxDropzoneModule, EmComponent],
    styleUrl: './organization-form.component.scss'
})
export class OrganizationFormComponent implements OnInit {

    form!: FormGroup;
    schemeTypes = Utils.enumValues(TypeEnum);
    countries: Country[] | undefined;
    states: State[] | undefined;
    lgas: Lga[] | undefined;
    fetchingLga = false;
    fetchingStates = false;
    stateCode: any;
    countryId: any;
    bannerPreview: string | undefined | ArrayBuffer;

    @Output() gotoNextStep = new EventEmitter<NewOrganizationDto>();

    @Output() locationData = new EventEmitter<{
        lgaName: string,
        stateName: string,
        countryName: string
    }>

    @Input() data!: NewOrganizationDto;

    constructor(
        public themeService: CustomizerSettingsService,
        private toastrService: ToastrService,
        private locationService: LocationService,
        private organizationControllerService: OrganizationControllerService,
        private portalUserControllerService: PortalUserControllerService,
        private fb: FormBuilder, private modalService: BsModalService) {
    }

    ngOnInit() {
        this.initForm();
    }

    private initForm(): void {
        this.form = this.fb.group({
            adminFirstName: ['', [CustomValidators.notBlank, CustomValidators.minLength(3)]],
            adminEmail: ['', [CustomValidators.notBlank, CustomValidators.minLength(3), Validators.email], CustomValidators.getUniqueEmailValidator(this.portalUserControllerService)],
            adminLastName: ['', [CustomValidators.notBlank, CustomValidators.minLength(3)]],
            adminPhoneNumber: ['', [CustomValidators.notBlank, CustomValidators.minLength(3)], [CustomValidators.uniqueUserPhoneNumber(this.portalUserControllerService)]],
            type: ['', []],
            name: ['', [CustomValidators.notBlank, CustomValidators.minLength(3)], [this.uniqueSchemeName()]],
            revenueHeadCode: ['', [CustomValidators.notBlank, CustomValidators.minLength(3)]],
            revenueHeadName: ['', [CustomValidators.notBlank, CustomValidators.minLength(3)]],
            countryId: ['', []],
            stateId: ['', []],
            lgaId: ['', []],
            banner: ['', [Validators.required]],
        });

        this.locationService.searchCountries({alpha2: 'NG'})
            .subscribe(value => {
                this.countries = value.results;
            });

        this.form.get('countryId')?.valueChanges.pipe(distinctUntilChanged()).subscribe(v => {
            this.countryId = v;
            this.states = [];
            this.fetchStates(v);
        })

        this.form.get('stateId')?.valueChanges.pipe(distinctUntilChanged()).subscribe(v => {
            this.stateCode = v;
            this.lgas = [];
            this.fetchLga(v);
        });

        if (this.data) {
            this.form.patchValue({
                adminFirstName: this.data?.admin?.firstName,
                adminEmail: this.data?.admin?.email,
                adminLastName: this.data?.admin?.lastName,
                adminPhoneNumber: this.data?.admin?.phoneNumber,
                type: this.data?.type || '',
                name: this.data?.name,
                revenueHeadCode: this.data?.revenueHeadCode,
                revenueHeadName: this.data?.revenueHeadName,
                countryId: this.data?.countryId || '',
                stateId: this.data?.stateId || '',
                lgaId: this.data?.lgaId || '',
                banner: this.data.banner,
            })
        }
    }


    fetchStates(countryId: number) {
        this.fetchingStates = true;
        this.locationService.searchStates({countryId: countryId})
            .subscribe(value => {
                this.fetchingStates = false;
                this.states = value.results;
            }, error => {
                this.fetchingStates = false;
            })
    }

    fetchLga(stateId: number) {
        this.fetchingLga = true;
        this.locationService.searchLgas({stateId: stateId as any})
            .subscribe(value => {
                this.fetchingLga = false;
                this.lgas = value.results;
            }, error => {
                this.fetchingLga = false;
            })
    }

    onBannerSelected($event: NgxDropzoneChangeEvent) {
        let f = $event.addedFiles[0];
        if (f?.size > 1024 * 1024 * 5) {
            this.toastrService.info('File size must not be more than 5MB');
            return;
        }
        if (!f.name.toLowerCase().endsWith('.jpg') && !f.name.toLowerCase().endsWith('.png') && !f.name.toLowerCase().endsWith('.jpeg')) {
            this.toastrService.info('Invalid file type');
            return;
        }
        let bsModalRef = this.modalService.show(ImageCropComponent, {
            initialState: {
                imageFile: f,
                maxWidth: 900,
                maxHeight: 300,
                aspectRatio: 900 / 300
            },
            class: 'modal-lg'
        });
        bsModalRef?.content?.onCroppedImage.subscribe(v => {
            this.form.get('banner')?.patchValue(v);
            console.log('cropped file', v);
            this.bannerPreview = v;
        });
    }


    private uniqueSchemeName() {
        return  (control: AbstractControl):Observable<ValidationErrors> => {
            if(!control.value){
                return of(null);
            }
            return  this.organizationControllerService.uniqueOrganizationName({organizationName: control.value})
                .pipe(map(value => {
                            if (value.code == 200) {
                                return null;
                            }
                            return {'uniqueschemename': true};
                        }
                    ),
                    debounceTime(500),
                    take(1),
                    catchError((err: HttpErrorResponse, caught) => {
                        return of({'nointernet': true});
                    })
                );
        }
    }

    submit() {
        console.log(this.form)

        if(this.form.invalid){
            return;
        }
        if (this.form.get('banner')?.invalid) {
            this.toastrService.info('Please upload an image banner to proceed.')
            return;
        }
        let value = this.form.value;
        let d: NewOrganizationDto | any = {
            admin: {
                firstName: value.adminFirstName,
                lastName: value.adminLastName,
                email: value.adminEmail,
                phoneNumber: value.adminPhoneNumber,
            },
            countryId: value.countryId,
            stateId: value.stateId,
            lgaId: value.lgaId,
            name: value.name,
            revenueHeadCode: value.revenueHeadCode,
            revenueHeadName: value.revenueHeadName,
            type: value.type,
            lgaName: this.lgas?.find(v => v.id == value['lgaId'])?.name,
            stateName: this.states?.find(v => v.id == value['stateId'])?.name,
            countryName: this.countries?.find(v => v.id == value['countryId'])?.name,
            tariffs: this.data?.tariffs
        }
        this.data = d;
        this.data.banner = this.form.get('banner')?.value
        this.gotoNextStep.emit(this.data);
        this.locationData.emit({
            lgaName: this.lgas?.find(v => v.id == value['lgaId'])?.name,
            stateName: this.states?.find(v => v.id == value['stateId'])?.name,
            countryName: this.countries?.find(v => v.id == value['countryId'])?.name,
        });
    }
}
