/**
 * Tickets API
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { QueryResultsArea } from '../model/query-results-area';
// @ts-ignore
import { QueryResultsCountry } from '../model/query-results-country';
// @ts-ignore
import { QueryResultsLga } from '../model/query-results-lga';
// @ts-ignore
import { QueryResultsState } from '../model/query-results-state';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface SearchAreasRequestParams {
    createdAt?: string;
    lastUpdatedBy?: string;
    areaCode?: string;
    lastUpdatedAt?: string;
    createdBy?: string;
    areaName?: string;
    lga?: string;
    lgaCode?: string;
    dateDeactivated?: string;
    id?: number;
    status?: 'ACTIVE' | 'INACTIVE';
    deactivatedBy?: string;
    offset?: number;
    limit?: number;
    orderColumn?: string;
    order?: 'ASC' | 'DESC';
}

export interface SearchCountriesRequestParams {
    lastUpdatedBy?: string;
    lastUpdatedAt?: string;
    states?: string;
    deactivatedBy?: string;
    internationalDialingCode?: string;
    supportedOnSystem?: boolean;
    createdAt?: string;
    createdBy?: string;
    name?: string;
    alpha2?: string;
    alpha3?: string;
    dateDeactivated?: string;
    id?: number;
    status?: 'ACTIVE' | 'INACTIVE';
    offset?: number;
    limit?: number;
    orderColumn?: string;
    order?: 'ASC' | 'DESC';
}

export interface SearchLgasRequestParams {
    lastUpdatedBy?: string;
    lastUpdatedAt?: string;
    stateId?: number;
    lgaCode?: string;
    lgaName?: string;
    deactivatedBy?: string;
    createdAt?: string;
    createdBy?: string;
    dateDeactivated?: string;
    stateCode?: string;
    state?: string;
    id?: number;
    status?: 'ACTIVE' | 'INACTIVE';
    offset?: number;
    limit?: number;
    orderColumn?: string;
    order?: 'ASC' | 'DESC';
}

export interface SearchStatesRequestParams {
    country?: string;
    lastUpdatedBy?: string;
    code?: string;
    lastUpdatedAt?: string;
    countryId?: number;
    deactivatedBy?: string;
    createdAt?: string;
    createdBy?: string;
    name?: string;
    countryAlpha2?: string;
    dateDeactivated?: string;
    id?: number;
    status?: 'ACTIVE' | 'INACTIVE';
    offset?: number;
    limit?: number;
    orderColumn?: string;
    order?: 'ASC' | 'DESC';
}


@Injectable({
  providedIn: 'root'
})
export class LocationService {

    protected basePath = 'http://tickets.serverna.com.ng/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
            if (firstBasePath != undefined) {
                basePath = firstBasePath;
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * search areas
     * find areas in a LGA
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchAreas(requestParameters?: SearchAreasRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<QueryResultsArea>;
    public searchAreas(requestParameters?: SearchAreasRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<QueryResultsArea>>;
    public searchAreas(requestParameters?: SearchAreasRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<QueryResultsArea>>;
    public searchAreas(requestParameters?: SearchAreasRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const createdAt = requestParameters?.createdAt;
        const lastUpdatedBy = requestParameters?.lastUpdatedBy;
        const areaCode = requestParameters?.areaCode;
        const lastUpdatedAt = requestParameters?.lastUpdatedAt;
        const createdBy = requestParameters?.createdBy;
        const areaName = requestParameters?.areaName;
        const lga = requestParameters?.lga;
        const lgaCode = requestParameters?.lgaCode;
        const dateDeactivated = requestParameters?.dateDeactivated;
        const id = requestParameters?.id;
        const status = requestParameters?.status;
        const deactivatedBy = requestParameters?.deactivatedBy;
        const offset = requestParameters?.offset;
        const limit = requestParameters?.limit;
        const orderColumn = requestParameters?.orderColumn;
        const order = requestParameters?.order;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (createdAt !== undefined && createdAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>createdAt, 'createdAt');
        }
        if (lastUpdatedBy !== undefined && lastUpdatedBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lastUpdatedBy, 'lastUpdatedBy');
        }
        if (areaCode !== undefined && areaCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>areaCode, 'areaCode');
        }
        if (lastUpdatedAt !== undefined && lastUpdatedAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lastUpdatedAt, 'lastUpdatedAt');
        }
        if (createdBy !== undefined && createdBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>createdBy, 'createdBy');
        }
        if (areaName !== undefined && areaName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>areaName, 'areaName');
        }
        if (lga !== undefined && lga !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lga, 'lga');
        }
        if (lgaCode !== undefined && lgaCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lgaCode, 'lgaCode');
        }
        if (dateDeactivated !== undefined && dateDeactivated !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dateDeactivated, 'dateDeactivated');
        }
        if (id !== undefined && id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>id, 'id');
        }
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }
        if (deactivatedBy !== undefined && deactivatedBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>deactivatedBy, 'deactivatedBy');
        }
        if (offset !== undefined && offset !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>offset, 'offset');
        }
        if (limit !== undefined && limit !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>limit, 'limit');
        }
        if (orderColumn !== undefined && orderColumn !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>orderColumn, 'orderColumn');
        }
        if (order !== undefined && order !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>order, 'order');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (ApiKey) required
        localVarCredential = this.configuration.lookupCredential('ApiKey');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else if (localVarHttpHeaderAcceptSelected.startsWith('blob')) {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/location/areas`;
        return this.httpClient.request<QueryResultsArea>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * search countries
     * filter countries
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchCountries(requestParameters?: SearchCountriesRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<QueryResultsCountry>;
    public searchCountries(requestParameters?: SearchCountriesRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<QueryResultsCountry>>;
    public searchCountries(requestParameters?: SearchCountriesRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<QueryResultsCountry>>;
    public searchCountries(requestParameters?: SearchCountriesRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const lastUpdatedBy = requestParameters?.lastUpdatedBy;
        const lastUpdatedAt = requestParameters?.lastUpdatedAt;
        const states = requestParameters?.states;
        const deactivatedBy = requestParameters?.deactivatedBy;
        const internationalDialingCode = requestParameters?.internationalDialingCode;
        const supportedOnSystem = requestParameters?.supportedOnSystem;
        const createdAt = requestParameters?.createdAt;
        const createdBy = requestParameters?.createdBy;
        const name = requestParameters?.name;
        const alpha2 = requestParameters?.alpha2;
        const alpha3 = requestParameters?.alpha3;
        const dateDeactivated = requestParameters?.dateDeactivated;
        const id = requestParameters?.id;
        const status = requestParameters?.status;
        const offset = requestParameters?.offset;
        const limit = requestParameters?.limit;
        const orderColumn = requestParameters?.orderColumn;
        const order = requestParameters?.order;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (lastUpdatedBy !== undefined && lastUpdatedBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lastUpdatedBy, 'lastUpdatedBy');
        }
        if (lastUpdatedAt !== undefined && lastUpdatedAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lastUpdatedAt, 'lastUpdatedAt');
        }
        if (states !== undefined && states !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>states, 'states');
        }
        if (deactivatedBy !== undefined && deactivatedBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>deactivatedBy, 'deactivatedBy');
        }
        if (internationalDialingCode !== undefined && internationalDialingCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>internationalDialingCode, 'internationalDialingCode');
        }
        if (supportedOnSystem !== undefined && supportedOnSystem !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>supportedOnSystem, 'supportedOnSystem');
        }
        if (createdAt !== undefined && createdAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>createdAt, 'createdAt');
        }
        if (createdBy !== undefined && createdBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>createdBy, 'createdBy');
        }
        if (name !== undefined && name !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>name, 'name');
        }
        if (alpha2 !== undefined && alpha2 !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>alpha2, 'alpha2');
        }
        if (alpha3 !== undefined && alpha3 !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>alpha3, 'alpha3');
        }
        if (dateDeactivated !== undefined && dateDeactivated !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dateDeactivated, 'dateDeactivated');
        }
        if (id !== undefined && id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>id, 'id');
        }
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }
        if (offset !== undefined && offset !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>offset, 'offset');
        }
        if (limit !== undefined && limit !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>limit, 'limit');
        }
        if (orderColumn !== undefined && orderColumn !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>orderColumn, 'orderColumn');
        }
        if (order !== undefined && order !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>order, 'order');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (ApiKey) required
        localVarCredential = this.configuration.lookupCredential('ApiKey');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else if (localVarHttpHeaderAcceptSelected.startsWith('blob')) {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/location/countries`;
        return this.httpClient.request<QueryResultsCountry>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * search LGAs
     * find LGAs in a state
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchLgas(requestParameters?: SearchLgasRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<QueryResultsLga>;
    public searchLgas(requestParameters?: SearchLgasRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<QueryResultsLga>>;
    public searchLgas(requestParameters?: SearchLgasRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<QueryResultsLga>>;
    public searchLgas(requestParameters?: SearchLgasRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const lastUpdatedBy = requestParameters?.lastUpdatedBy;
        const lastUpdatedAt = requestParameters?.lastUpdatedAt;
        const stateId = requestParameters?.stateId;
        const lgaCode = requestParameters?.lgaCode;
        const lgaName = requestParameters?.lgaName;
        const deactivatedBy = requestParameters?.deactivatedBy;
        const createdAt = requestParameters?.createdAt;
        const createdBy = requestParameters?.createdBy;
        const dateDeactivated = requestParameters?.dateDeactivated;
        const stateCode = requestParameters?.stateCode;
        const state = requestParameters?.state;
        const id = requestParameters?.id;
        const status = requestParameters?.status;
        const offset = requestParameters?.offset;
        const limit = requestParameters?.limit;
        const orderColumn = requestParameters?.orderColumn;
        const order = requestParameters?.order;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (lastUpdatedBy !== undefined && lastUpdatedBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lastUpdatedBy, 'lastUpdatedBy');
        }
        if (lastUpdatedAt !== undefined && lastUpdatedAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lastUpdatedAt, 'lastUpdatedAt');
        }
        if (stateId !== undefined && stateId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>stateId, 'stateId');
        }
        if (lgaCode !== undefined && lgaCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lgaCode, 'lgaCode');
        }
        if (lgaName !== undefined && lgaName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lgaName, 'lgaName');
        }
        if (deactivatedBy !== undefined && deactivatedBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>deactivatedBy, 'deactivatedBy');
        }
        if (createdAt !== undefined && createdAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>createdAt, 'createdAt');
        }
        if (createdBy !== undefined && createdBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>createdBy, 'createdBy');
        }
        if (dateDeactivated !== undefined && dateDeactivated !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dateDeactivated, 'dateDeactivated');
        }
        if (stateCode !== undefined && stateCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>stateCode, 'stateCode');
        }
        if (state !== undefined && state !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>state, 'state');
        }
        if (id !== undefined && id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>id, 'id');
        }
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }
        if (offset !== undefined && offset !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>offset, 'offset');
        }
        if (limit !== undefined && limit !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>limit, 'limit');
        }
        if (orderColumn !== undefined && orderColumn !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>orderColumn, 'orderColumn');
        }
        if (order !== undefined && order !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>order, 'order');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (ApiKey) required
        localVarCredential = this.configuration.lookupCredential('ApiKey');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else if (localVarHttpHeaderAcceptSelected.startsWith('blob')) {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/location/lgas`;
        return this.httpClient.request<QueryResultsLga>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * search states
     * filter states using name, code parameters
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchStates(requestParameters?: SearchStatesRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<QueryResultsState>;
    public searchStates(requestParameters?: SearchStatesRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<QueryResultsState>>;
    public searchStates(requestParameters?: SearchStatesRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<QueryResultsState>>;
    public searchStates(requestParameters?: SearchStatesRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const country = requestParameters?.country;
        const lastUpdatedBy = requestParameters?.lastUpdatedBy;
        const code = requestParameters?.code;
        const lastUpdatedAt = requestParameters?.lastUpdatedAt;
        const countryId = requestParameters?.countryId;
        const deactivatedBy = requestParameters?.deactivatedBy;
        const createdAt = requestParameters?.createdAt;
        const createdBy = requestParameters?.createdBy;
        const name = requestParameters?.name;
        const countryAlpha2 = requestParameters?.countryAlpha2;
        const dateDeactivated = requestParameters?.dateDeactivated;
        const id = requestParameters?.id;
        const status = requestParameters?.status;
        const offset = requestParameters?.offset;
        const limit = requestParameters?.limit;
        const orderColumn = requestParameters?.orderColumn;
        const order = requestParameters?.order;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (country !== undefined && country !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>country, 'country');
        }
        if (lastUpdatedBy !== undefined && lastUpdatedBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lastUpdatedBy, 'lastUpdatedBy');
        }
        if (code !== undefined && code !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>code, 'code');
        }
        if (lastUpdatedAt !== undefined && lastUpdatedAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>lastUpdatedAt, 'lastUpdatedAt');
        }
        if (countryId !== undefined && countryId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>countryId, 'countryId');
        }
        if (deactivatedBy !== undefined && deactivatedBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>deactivatedBy, 'deactivatedBy');
        }
        if (createdAt !== undefined && createdAt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>createdAt, 'createdAt');
        }
        if (createdBy !== undefined && createdBy !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>createdBy, 'createdBy');
        }
        if (name !== undefined && name !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>name, 'name');
        }
        if (countryAlpha2 !== undefined && countryAlpha2 !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>countryAlpha2, 'countryAlpha2');
        }
        if (dateDeactivated !== undefined && dateDeactivated !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dateDeactivated, 'dateDeactivated');
        }
        if (id !== undefined && id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>id, 'id');
        }
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }
        if (offset !== undefined && offset !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>offset, 'offset');
        }
        if (limit !== undefined && limit !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>limit, 'limit');
        }
        if (orderColumn !== undefined && orderColumn !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>orderColumn, 'orderColumn');
        }
        if (order !== undefined && order !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>order, 'order');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (ApiKey) required
        localVarCredential = this.configuration.lookupCredential('ApiKey');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else if (localVarHttpHeaderAcceptSelected.startsWith('blob')) {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/location/states`;
        return this.httpClient.request<QueryResultsState>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

}
