/**
 * Tickets API
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface OrganizationSummaryPojo { 
    id?: number;
    name?: string;
    organizationType?: OrganizationSummaryPojo.OrganizationTypeEnum;
    adminName?: string;
    adminEmail?: string;
    adminPhoneNumber?: string;
    location?: string;
    bannerId?: number;
    approvalStatus?: OrganizationSummaryPojo.ApprovalStatusEnum;
    createdAt?: string;
}
export namespace OrganizationSummaryPojo {
    export type OrganizationTypeEnum = 'LOCAL_GOVERNMENT' | 'FEDERAL_GOVERNMENT' | 'STATE_GOVERNMENT' | 'OTHER';
    export const OrganizationTypeEnum = {
        LOCAL_GOVERNMENT: 'LOCAL_GOVERNMENT' as OrganizationTypeEnum,
        FEDERAL_GOVERNMENT: 'FEDERAL_GOVERNMENT' as OrganizationTypeEnum,
        STATE_GOVERNMENT: 'STATE_GOVERNMENT' as OrganizationTypeEnum,
        OTHER: 'OTHER' as OrganizationTypeEnum
    };
    export type ApprovalStatusEnum = 'APPROVED' | 'PENDING' | 'DISAPPROVED';
    export const ApprovalStatusEnum = {
        APPROVED: 'APPROVED' as ApprovalStatusEnum,
        PENDING: 'PENDING' as ApprovalStatusEnum,
        DISAPPROVED: 'DISAPPROVED' as ApprovalStatusEnum
    };
}


