import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, RouterLink} from "@angular/router";
import {BillControllerService, BillPojo} from "../../../../../sdk/tickets-sdk";
import {environment} from "../../../environments/environment";
import {CurrencyPipe, DatePipe, Location} from "@angular/common";

@Component({
  selector: 'app-bill-details',
  standalone: true,
  imports: [
    RouterLink,
    DatePipe,
    CurrencyPipe
  ],
  templateUrl: './bill-details.component.html',
  styleUrl: './bill-details.component.scss'
})
export class BillDetailsComponent implements OnInit {
  bill: BillPojo;
  private defaultTicketsInBatch: number = 100;
  billDownloadUrl: string;

  constructor(
      private activatedRoute: ActivatedRoute,
      private billControllerService: BillControllerService,
      private location: Location
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe( params => {
      if (params['invoiceNumber']) {
        const invoiceNumber = params['invoiceNumber'];
        this.billControllerService.getBill({invoiceNumber}).subscribe( v => {
          this.bill = v;
          this.billDownloadUrl = `${environment.reportUrl}/payment-invoice/${v.paymentInvoice.invoiceNumber}`;
        }, (error) => {
          this.location.back();
        });
      }
    });
  }

  getAmountPerRow(unitAmount: number, quantity: number): number {
    return (unitAmount * this.defaultTicketsInBatch) * quantity;
  }
}
