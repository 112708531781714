import { AbstractControl, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { CURRENCY } from '../../models/enum/currency-enum';
import { PhoneNumber } from '../../models/others/phone-number';
import { Config } from '../validators/phone-number.validator';
import {PhoneNumberUtil} from "google-libphonenumber";



export const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export class Utils {

  public ctrDown: boolean = false;
  static getCurrencySymbol(currency: string) {
    switch (currency) {
      case CURRENCY.NGN:
        return '₦';
      case CURRENCY.USD:
        return '$';
      default:
        return '$';
    }
  }

  get alphabetsOnlyErrorMsg() {
    return [{
      error: 'pattern',
      format: (label) => `${label} can only contain alphabets.`
    }];
  }

  get digitsOnlyErrorMsg() {
    return [{
      error: 'pattern',
      format: (label) => `${label} can only contain digits.`
    }];
  }

  get phoneErrorMsg() {
    return [{
      error: 'phone',
      format: (label) => `${label} number is invalid.`
    }];
  }


  static enumEntries<T>(t: T): ReadonlyArray<readonly [keyof T, T[keyof T]]> {
    const entries = Object.entries(t);
    const plainStringEnum = entries.every(
      ([key, value]) => typeof value === 'string'
    );
    return (plainStringEnum
      ? entries
      : entries.filter(([k, v]) => typeof v !== 'string')) as any;
  }

  static enumKeys<T>(t: T): ReadonlyArray<keyof T> {
    return this.enumEntries(t).map(([key]) => key);
  }

  static enumValues<T>(t: T): Array<T[keyof T]> {
    const values = Object.values(t);
    const plainStringEnum = values.every(x => typeof x === 'string');
    return plainStringEnum ? values : values.filter(x => typeof x !== 'string');
  }

  static getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  static getUniqueArray(input: any[]) {
    const filteredArr = input.reduce((acc, current) => {
      const x = acc.find(item => item.name === current.name);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
    return filteredArr;
  }

  static monthNumToName(monthnum) {
    return months[monthnum - 1] || '';
  }

  static monthNameToNum(monthname) {
    var month = months.indexOf(monthname);
    return month ? month + 1 : 0;
  }

  // static maskCharacters(String )

  public static noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  public static maskCharacter(str, mask, n = 1) {
    return ('' + str).slice(0, -n)
        .replace(/./g, mask)
      + ('' + str).slice(-n);
  }

  public static preventNumberInput(event: KeyboardEvent) {
    const charCode = (typeof event.which == "undefined") ? event.keyCode : event.which;
    const charStr = String.fromCharCode(charCode);
// Check if the input is from the calculator section of the keyboard
    if (event?.code?.includes('Numpad')) {
      event?.preventDefault();
    } else if (/\d/.test(charStr)) {
      event?.preventDefault();
    }
  }

  public static maskString(str: string): string {
    const strLength = str.length;
    return "*".repeat(strLength - 3) + str.slice(strLength - 3);
  }




  public static preventAlphabetInput(e: KeyboardEvent){
    // const keyCode = e.keyCode;
    if (( (e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) && e.keyCode !=8) {
      e.preventDefault();
    }
  }

  public static preventAlphabetInputV2(e: KeyboardEvent){
    //TODO implement to handle paste and copy actions
  }

  preventAlphabetInput($event: KeyboardEvent) {
    const cmdKey = 91;
    const vKey = 86;
    const cKey = 67;
    const aKey = 65;
    const excludedKeys = [8, 37, 39, 46];
    if($event.ctrlKey || $event.keyCode ==cmdKey){
      this.ctrDown = true
    }

    if (this.ctrDown && $event.keyCode == vKey){
      //paste action, handle appropriately
    } else if(this.ctrDown && $event.keyCode == cKey){
      //copy action, handle appropriately
    } else if (this.ctrDown && $event.keyCode == aKey){
      //select all action, handle appropriately
    } else if (!(($event.keyCode >= 48 && $event.keyCode <= 57) || ($event.keyCode >= 96 && $event.keyCode <= 105) ||
      (excludedKeys.includes($event.keyCode)))) {
      $event.preventDefault();
    } else {
    }
    //TODO to be moved to Utils.preventAlphabetInputV2($event)
  }

  public static numberOnly(e: KeyboardEvent): void {
    if (
      // Only allow digits 0-9
      (e.keyCode < 48 || e.keyCode > 57) &&
      // Only allow decimal point (.)
      e.key !== '.' &&
      // Allow backspace (keyCode 8)
      e.keyCode !== 8 &&
      // Allow arrow keys (37-40)
      (e.keyCode < 37 || e.keyCode > 40)
    ) {
      e.preventDefault();
    }
  }
}


export function removeUndefinedOrNullFields(obj: any): any {
  Object.keys(obj).forEach(key => {
    if (obj[key] === undefined || obj[key] === 'null' || obj[key] === null || obj[key] === '' || obj[key] < 1) {
      delete obj[key];
    }
  });

  return obj;
}


export function  hasError(name: string, formGroup: FormGroup, formSubmitted: boolean, formGroupName?: string): boolean {
  if (formGroupName != null && formGroupName != undefined) {
    return formGroup.get(formGroupName).get(name).touched && !formGroup.get(formGroupName).get(name).valid ||
      formSubmitted && !formGroup.get(formGroupName).get(name).valid;
  }
  return formGroup.get(name).touched && !formGroup.get(name).valid ||
    formSubmitted && !formGroup.get(name).valid;
}

const phoneNumberUtil = PhoneNumberUtil.getInstance();


export function telePhoneNumberValidator(config: Config = {}): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const value: PhoneNumber = control.value;
    const error = {};
    error[config.errorCode || 'invalidPhoneNumber'] = true;

    if (!value) {
      return null;
    }
    if (!value.internationalNumber) {
      return error;
    }
    try {
      const phoneNumber = phoneNumberUtil.parse(value.internationalNumber.replace(/ /g, ''));
      if (phoneNumberUtil.isValidNumber(phoneNumber)) {
        return null;
      }
    } catch (e) {
      // console.error(e);
    }

    return error;
  };
}
