/**
 * Tickets API
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TicketIssuance } from './ticket-issuance';
import { Ticket } from './ticket';
import { Organization } from './organization';
import { AppUserAgent } from './app-user-agent';
import { AppUser } from './app-user';
import { GpsCoordinate } from './gps-coordinate';
import { TicketType } from './ticket-type';
import { PortalUser } from './portal-user';


export interface TicketValidationLogPojo { 
    status?: TicketValidationLogPojo.StatusEnum;
    dateDeactivated?: string;
    deactivatedBy?: PortalUser;
    id?: number;
    createdAt?: string;
    lastUpdatedAt?: string;
    createdBy?: PortalUser;
    lastUpdatedBy?: PortalUser;
    ticketNumber?: string;
    validationResult?: TicketValidationLogPojo.ValidationResultEnum;
    trackingId?: string;
    vehicleNumber?: string;
    phoneNumber?: string;
    gpsCoordinate?: GpsCoordinate;
    appUser?: AppUser;
    ticketType?: TicketType;
    organization?: Organization;
    ticketIssuance?: TicketIssuance;
    ticket?: Ticket;
    appUserAgent?: AppUserAgent;
    ticketAmount?: number;
}
export namespace TicketValidationLogPojo {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE';
    export const StatusEnum = {
        ACTIVE: 'ACTIVE' as StatusEnum,
        INACTIVE: 'INACTIVE' as StatusEnum
    };
    export type ValidationResultEnum = 'VALID' | 'INVALID';
    export const ValidationResultEnum = {
        VALID: 'VALID' as ValidationResultEnum,
        INVALID: 'INVALID' as ValidationResultEnum
    };
}


