/**
 * Tickets API
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Zone } from './zone';
import { TicketType } from './ticket-type';
import { RevenueItem } from './revenue-item';


export interface OrganizationSummaryWithTariffPojo { 
    id?: number;
    name?: string;
    organizationType?: OrganizationSummaryWithTariffPojo.OrganizationTypeEnum;
    adminName?: string;
    adminEmail?: string;
    adminPhoneNumber?: string;
    location?: string;
    bannerId?: number;
    approvalStatus?: OrganizationSummaryWithTariffPojo.ApprovalStatusEnum;
    createdAt?: string;
    tariffs?: Array<TicketType>;
    zones?: Array<Zone>;
    revenueItem?: RevenueItem;
}
export namespace OrganizationSummaryWithTariffPojo {
    export type OrganizationTypeEnum = 'LOCAL_GOVERNMENT' | 'FEDERAL_GOVERNMENT' | 'STATE_GOVERNMENT' | 'OTHER';
    export const OrganizationTypeEnum = {
        LOCAL_GOVERNMENT: 'LOCAL_GOVERNMENT' as OrganizationTypeEnum,
        FEDERAL_GOVERNMENT: 'FEDERAL_GOVERNMENT' as OrganizationTypeEnum,
        STATE_GOVERNMENT: 'STATE_GOVERNMENT' as OrganizationTypeEnum,
        OTHER: 'OTHER' as OrganizationTypeEnum
    };
    export type ApprovalStatusEnum = 'APPROVED' | 'PENDING' | 'DISAPPROVED';
    export const ApprovalStatusEnum = {
        APPROVED: 'APPROVED' as ApprovalStatusEnum,
        PENDING: 'PENDING' as ApprovalStatusEnum,
        DISAPPROVED: 'DISAPPROVED' as ApprovalStatusEnum
    };
}


