/**
 * Tickets API
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Organization } from './organization';
import { Address } from './address';
import { PortalAccountMembership } from './portal-account-membership';
import { PortalUser } from './portal-user';


export interface PortalAccount { 
    id?: number;
    createdAt?: string;
    lastUpdatedAt?: string;
    createdBy?: PortalUser;
    lastUpdatedBy?: PortalUser;
    status?: PortalAccount.StatusEnum;
    dateDeactivated?: string;
    deactivatedBy?: PortalUser;
    name?: string;
    type?: PortalAccount.TypeEnum;
    code?: string;
    approvalStatus?: PortalAccount.ApprovalStatusEnum;
    reasonForDisapproval?: string;
    accountEmail?: string;
    accountPhoneNumber?: string;
    address?: Address;
    organization?: Organization;
    portalAccountMembershipCollection?: Array<PortalAccountMembership>;
}
export namespace PortalAccount {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE';
    export const StatusEnum = {
        ACTIVE: 'ACTIVE' as StatusEnum,
        INACTIVE: 'INACTIVE' as StatusEnum
    };
    export type TypeEnum = 'ADMIN' | 'ORGANIZATION';
    export const TypeEnum = {
        ADMIN: 'ADMIN' as TypeEnum,
        ORGANIZATION: 'ORGANIZATION' as TypeEnum
    };
    export type ApprovalStatusEnum = 'APPROVED' | 'PENDING' | 'DISAPPROVED';
    export const ApprovalStatusEnum = {
        APPROVED: 'APPROVED' as ApprovalStatusEnum,
        PENDING: 'PENDING' as ApprovalStatusEnum,
        DISAPPROVED: 'DISAPPROVED' as ApprovalStatusEnum
    };
}


