import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {base64ToFile, ImageCroppedEvent, ImageCropperComponent, LoadedImage} from 'ngx-image-cropper';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-image-cropper',
    templateUrl: './image-crop.component.html',
    styleUrls: ['./image-crop.component.scss'],
    imports: [
        ImageCropperComponent
    ],
    standalone: true
})
export class ImageCropComponent {

    imageChangedEvent: any = '';
    croppedImage: any = '';
    onCroppedImage = new EventEmitter<string>();

    @Input() imageFile!: File;
    @Input() maxWidth: number = 100;
    @Input() maxHeight: number = 100;
    @Input() aspectRatio = this.maxWidth / this.maxHeight;

    constructor(private bsModalRef: BsModalRef) {
    }

    ngOnInit(): void {
    }

    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
    }

    imageCropped(event: ImageCroppedEvent) {
        if (event.blob) {
            const reader = new FileReader();
            reader.readAsDataURL(event.blob);
            reader.onloadend = () => {
                this.croppedImage = reader.result;
            };
        } else {
            console.error('Cropped image blob is undefined');
        }
    }

    imageLoaded(image: LoadedImage) {
        // show cropper
    }

    cropperReady() {
        // cropper ready
    }

    loadImageFailed() {
        // show message
    }

    save() {
        if (this.croppedImage) {
            try {
                this.onCroppedImage.emit(this.croppedImage);
            } catch (error) {
            }
        } else {
            console.error('Cropped image is undefined or empty');
        }
    }

    close() {
        this.bsModalRef.hide();
    }

    confirm() {
        this.save();
        this.bsModalRef.hide();
    }
}
