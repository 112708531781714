<div class="modal-content">
    <div class="modal-header">
        <h1 class="modal-title fs-5">{{ header }}</h1>
        <button type="button" class="btn-close shadow-none" aria-label="Close" (click)="close()"></button>
    </div>
    <div class="modal-body">
        {{ body }}
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="close()">Close</button>
        <button type="button" class="btn btn-primary" (click)="confirm()">
            @if (loading) {
                <div class="spinner-border spinner-border-sm" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            }
            {{ positiveButtonText }}
        </button>
    </div>
</div>
