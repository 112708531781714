import {PermissionType} from './permission-type.enum';
import {Agent, Organization} from "../../../../sdk/tickets-sdk";

export class UserAccount {

  public accountCode!: string;
  public accountId!: number;
  public accountName!: string;
  public accountType!: string;
  public permissions!: string[];
  public roles!: string[];
  public id!: number;
  public organization?: Organization;
  public agent?: Agent;

  constructor(data: any) {
    Object.assign(this, data);
  }

  public hasPermission(permission: string | PermissionType) {
    if (this.permissions && this.permissions.length > 0) {
      return this.permissions.filter((it: string) => it === permission).length;
    }
    return false;
  }

}
