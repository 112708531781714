<div class="modal-dialog">
    <div class="modal-content">
        <div class="modal-header">
            <h1 class="modal-title fs-5">{{ 'Disapprove' }}</h1>
            <button type="button" class="btn-close shadow-none" aria-label="Close" (click)="close()"></button>
        </div>
        <div class="modal-body">
            <div>
                <div>

                </div>
                <div>
                    <form [formGroup]="form" id="form">
                        <div class="col-sm-12 mb-4 text-center">
                            <span>Are you sure you want to disapprove this request?</span>
                        </div>
                        <div class="form-group col-sm-12">
                            <label for="r">Reason</label>
                            <textarea id="r" class="form-control"
                                      [maxLength]="100"
                                      [formControlName]="'reasonForDisapproval'"
                                      placeholder="Give a reason"></textarea>
                            <error-message
                                    [formControlName]="'reasonForDisapproval'"
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-danger" (click)="close()">Close</button>
            <button type="button" class="btn btn-primary" (click)="onDone()">
                @if (loading) {
                    <div class="spinner-border spinner-border-sm" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                }
                {{ 'Disapprove' }}</button>
        </div>
    </div>
</div>
