/**
 * Tickets API
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface NewUserDto { 
    accountCode: string;
    username: string;
    email: string;
    firstName: string;
    lastName: string;
    gender?: NewUserDto.GenderEnum;
    country?: number;
    password?: string;
    fromSocialProvider?: boolean;
    roles: { [key: string]: Set<string>; };
    phoneNumber?: string;
}
export namespace NewUserDto {
    export type GenderEnum = 'MALE' | 'FEMALE' | 'UNSPECIFIED' | 'OTHER';
    export const GenderEnum = {
        MALE: 'MALE' as GenderEnum,
        FEMALE: 'FEMALE' as GenderEnum,
        UNSPECIFIED: 'UNSPECIFIED' as GenderEnum,
        OTHER: 'OTHER' as GenderEnum
    };
}


