/**
 * Tickets API
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Agent } from './agent';
import { Address } from './address';
import { PortalUser } from './portal-user';


export interface Customer { 
    id?: number;
    createdAt?: string;
    lastUpdatedAt?: string;
    createdBy?: PortalUser;
    lastUpdatedBy?: PortalUser;
    status?: Customer.StatusEnum;
    dateDeactivated?: string;
    deactivatedBy?: PortalUser;
    type?: Customer.TypeEnum;
    name?: string;
    organization?: boolean;
    email?: string;
    phoneNumber?: string;
    agencyCustomerReference?: string;
    customerIdentifier?: string;
    address?: Address;
    agent?: Agent;
}
export namespace Customer {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE';
    export const StatusEnum = {
        ACTIVE: 'ACTIVE' as StatusEnum,
        INACTIVE: 'INACTIVE' as StatusEnum
    };
    export type TypeEnum = 'AGENT';
    export const TypeEnum = {
        AGENT: 'AGENT' as TypeEnum
    };
}


