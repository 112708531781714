import { Injectable } from '@angular/core';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  $toast = new Subject<ToastModel>();

  constructor() { }

  private message(toast: ToastModel): void {
    this.$toast.next(toast);
    setTimeout(() => {
        this.$toast.next(null);
    }, 1000);
  }

    success(message: string, title: string, subtitle?: string): void {
        this.message({message, title, subtitle, type: 'success'});
    }

    error(message: string, title: string, subtitle?: string): void {
        this.message({message, title, subtitle, type: 'error'});
    }

    info(message: string, title: string, subtitle?: string): void {
        this.message({message, title, subtitle, type: 'info'});
    }

    warning(message: string, title: string, subtitle?: string): void {
        this.message({message, title, subtitle, type: 'warning'});
    }

    public toast(): Subject<ToastModel> {
        return this.$toast;
    }

}

export interface ToastModel {
    message: string;
    type: 'info' | 'success' | 'warning' | 'error';
    title: string;
    subtitle?: string;
}
