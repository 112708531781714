
<div class=" m-0">
    <div class="modal-header">
        <div class="container-fluid">
            <div class="d-flex align-items-center">
                <div class="pull-left flex-fill">
                    <h6 class="modal-title" id="modal-title">Create App User</h6>
                </div>
                <button (click)="onCancel()" class="close pull-right" type="button">
                    <span aria-hidden="true" class="fa fa-times modal-title"></span>
                </button>
            </div>

        </div>

    </div>
    <div class="modal-body">
        <div class="container-fluid"><div class="filter-bg ">
            <form class="" (submit)="onCreate()" [formGroup]="form" id="form">
                <div class="custom-card">
                    <div class="row g-3">
                        <div class="col-md-6 form-group">
                            <input class="form-control"
                                   [formControlName]="'phoneNumber'" required id="phoneNumber"
                                   placeholder="07012345679" [maxLength]="30"/>
                            <error-message [form]="form" [controlName]="'phoneNumber'"/>
                        </div>
                        <div class="col-md-6 form-group">
                            <input [formControlName]="'firstName'" class="form-control" required="" id="firstName"
                                   placeholder="First Name" [readOnly]="fetchedExistingUser?.firstName" [maxLength]="30"/>
                            <error-message [form]="form" [controlName]="'firstName'"/>

                        </div>
                        <div class="col-md-6 form-group">
                            <input [formControlName]="'lastName'" class="form-control" required id="lastName"
                                   placeholder="Last Name" [readOnly]="fetchedExistingUser?.lastName" [maxLength]="30"/>
                            <error-message [form]="form" [controlName]="'lastName'"/>

                        </div>
                        <div class="col-md-6 form-group">
                            <input [formControlName]="'username'" class="form-control" required id="username"
                                   placeholder="Username" [readOnly]="fetchedExistingUser?.username" [maxLength]="100"/>
                            <error-message [form]="form" [controlName]="'username'"/>

                        </div>
                        <div class="col-md-12 form-group">
                            <hr/>
                        </div>
                        <div class="d-flex flex-column flex-md-row align-items-md-center">
                            @if (canSelectScheme) {
                                <div class="flex-fill form-group mr-1">
                                    <label class="required">Scheme</label>
                                    <select class="form-control" [formControlName]="'organizationId'" id="organizationId">
                                        <option [value]="''">-- Select Scheme --</option>
                                        @for (country of organizations; track  country) {
                                            <option [value]="country.id">{{country.name | titlecase}}</option>
                                        }
                                    </select>
                                    <error-message [form]="form" [controlName]="'organizationId'"/>

                                </div>
                            }

                            @if (canSelectAgent) {
                                <div class="flex-fill form-group mr-1">
                                    <label class="required">Agent</label>
                                    <select class="form-control" [formControlName]="'agentId'" id="agentId">
                                        @if (fetchingAgents) {
                                            <option [value]="''">Fetching Agents</option>
                                        }
                                        @if (!fetchingAgents && !organizationId) {
                                            <option [value]="''">-- Select a Scheme --</option>
                                        }
                                        @if (!fetchingAgents && agents?.length == 0) {
                                            <option [value]="''">No agents found</option>
                                        }
                                        @if (!fetchingAgents && agents?.length) {
                                            @if (agents?.length || 0 > 0) {
                                                <option [value]="''">-- Select an Agent --</option>
                                            }
                                            @for (p of agents; track  p) {
                                                <option [value]="p.id">{{p.portalUser.displayName}}</option>
                                            }
                                        }
                                    </select>
                                    <error-message [form]="form" [controlName]="'agentId'"/>
                                </div>
                            }
                            <div class="flex-fill form-group mr-1">
                                <label class="required">Role</label>
                                <select class="form-control" [formControlName]="'role'" id="role">
                                    <option [value]="''">-- Select Role --</option>
                                    <option [value]="'SELLER'">Seller</option>
                                    <option [value]="'ENFORCER'">Enforcer</option>
                                </select>
                                <error-message [form]="form" [controlName]="'role'"/>

                            </div>
                            <div class="text-right">
                                <button class="btn btn-outline-info" type="button" (click)="addRole()"><i class="fa fa-add"></i>Add</button>
                            </div>
                        </div>
                        <div class="col-md-12 form-group table-responsive">
                            <table class="table table-hover table-bordered table-sm">
                                <thead>
                                <tr class="">
                                    <th scope="col">#</th>
                                    @if (canSelectScheme) {
                                        <th scope="col">Scheme</th>
                                    }
                                    @if (canSelectAgent) {
                                        <th scope="col">Agent</th>
                                    }
                                    <th scope="col">Role</th>
                                    <th scope="col" style="max-width: 120px">Action</th>
                                </tr>
                                </thead>
                                <tbody>

                                @if (selectedRoles.length<1) {
                                    <tr>
                                        <td colspan="8" class="my-5 text-center">
                                            NO DATA
                                        </td>
                                    </tr>
                                }
                                @for (d of selectedRoles; track d; let i = $index) {
                                    <tr>
                                        <th scope="row">{{i+1}}</th>
                                        @if (canSelectScheme) {
                                            <td>{{d.organizationName}}</td>
                                        }

                                        @if (canSelectAgent) {
                                            <td>{{d.agentName}}</td>
                                        }
                                        <td>{{d.role}}</td>
                                        <td>
                                            <button (click)="removeRole(i)" type="button" class="btn btn-danger btn-sm"><i class="fa fa-trash"></i> Remove</button>

                                        </td>
                                    </tr>
                                }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </form>
        </div>
            <hr class="mx-0 mx-auto w-100"/>
            <div class="px-md-3">

            </div>
            <hr class="mx-0 mx-auto w-100"/>
        </div>

    </div>
    <div class="modal-footer">
        <div class="container-fluid text-end">
            <button (click)="onCancel()" class="btn btn-danger mr-2" type="button">
                <i class="fa fa-times mr-1 fa-spinner "></i
                > Cancel
            </button>
            <button form="form" class="btn btn-success" type="submit">
                <i class="fa" [ngClass]="{'fa-save':!loading,'fa-spinner fa-spin':loading}"></i>
                Create
            </button>
        </div>

    </div>
</div>
