<div class="trezo-card mb-25 border-radius d-block bg-white border-0 shadow-none">
    <div class="trezo-card-header d-flex align-items-center justify-content-between">
        <div class="trezo-card-title">
            <h5 class="mb-0">
                Top Selling Products
            </h5>
        </div>
        <div class="trezo-card-subtitle">
            <div class="trezo-card-header-menu position-relative">
                <button class="menu-btn transition d-inline-block bg-transparent border-radius" (click)="toggleCardHeaderMenu()">
                    <span class="d-block position-relative">
                        Today
                    </span>
                </button>
                <ul class="dropdown-menu border-radius border-0 show" *ngIf="isCardHeaderOpen">
                    <li>
                        <button type="button" class="dropdown-item d-block w-100 position-relative transition">
                            This Day
                        </button>
                    </li>
                    <li>
                        <button type="button" class="dropdown-item d-block w-100 position-relative transition">
                            This Week
                        </button>
                    </li>
                    <li>
                        <button type="button" class="dropdown-item d-block w-100 position-relative transition">
                            This Month
                        </button>
                    </li>
                    <li>
                        <button type="button" class="dropdown-item d-block w-100 position-relative transition">
                            This Year
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="trezo-card-content">
        <div class="basic-table table-responsive">
            <table class="table mb-0">
                <thead>
                    <tr>
                        <th scope="col" class="fw-medium">
                            Product
                        </th>
                        <th scope="col" class="fw-medium">
                            Price
                        </th>
                        <th scope="col" class="fw-medium">
                            Order
                        </th>
                        <th scope="col" class="fw-medium">
                            Stock
                        </th>
                        <th scope="col" class="fw-medium">
                            Amount
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <div class="product-info d-flex align-items-center">
                                <div class="image">
                                    <img alt="product-image" src="images/products/product1.jpg" />
                                </div>
                                <div class="info">
                                    <a routerLink="/dashboard/ecommerce-page/product-details" class="fw-medium d-inline-block">
                                        Smart Band
                                    </a>
                                    <span class="d-block text-body">
                                        08 Jun 2024
                                    </span>
                                </div>
                            </div>
                        </td>
                        <td>
                            $35.00
                        </td>
                        <td>
                            75
                        </td>
                        <td>
                            <span class="d-block">
                                750
                            </span>
                        </td>
                        <td>
                            $2,625
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="product-info d-flex align-items-center">
                                <div class="image">
                                    <img alt="product-image" src="images/products/product2.jpg" />
                                </div>
                                <div class="info">
                                    <a routerLink="/dashboard/ecommerce-page/product-details" class="fw-medium d-inline-block">
                                        Headphone
                                    </a>
                                    <span class="d-block text-body">
                                        07 Jun 2024
                                    </span>
                                </div>
                            </div>
                        </td>
                        <td>$49.00</td>
                        <td>25</td>
                        <td>
                            <span class="d-block">825</span>
                        </td>
                        <td>$1,225</td>
                    </tr>
                    <tr>
                        <td>
                            <div class="product-info d-flex align-items-center">
                                <div class="image">
                                    <img alt="product-image" src="images/products/product3.jpg" />
                                </div>
                                <div class="info">
                                    <a routerLink="/dashboard/ecommerce-page/product-details" class="fw-medium d-inline-block">
                                        iPhone 15 Plus
                                    </a>
                                    <span class="d-block text-body">
                                        06 Jun 2024
                                    </span>
                                </div>
                            </div>
                        </td>
                        <td>$99.00</td>
                        <td>55</td>
                        <td>
                            <span class="d-block stock-out">Stock Out</span>
                        </td>
                        <td>$5,445</td>
                    </tr>
                    <tr>
                        <td>
                            <div class="product-info d-flex align-items-center">
                                <div class="image">
                                    <img alt="product-image" src="images/products/product4.jpg" />
                                </div>
                                <div class="info">
                                    <a routerLink="/dashboard/ecommerce-page/product-details" class="fw-medium d-inline-block">
                                        Bluetooth Speaker
                                    </a>
                                    <span class="d-block text-body">
                                        05 Jun 2024
                                    </span>
                                </div>
                            </div>
                        </td>
                        <td>$59.00</td>
                        <td>40</td>
                        <td>
                            <span class="d-block">535</span>
                        </td>
                        <td>$2,360</td>
                    </tr>
                    <tr>
                        <td>
                            <div class="product-info d-flex align-items-center">
                                <div class="image">
                                    <img alt="product-image" src="images/products/product5.jpg" />
                                </div>
                                <div class="info">
                                    <a routerLink="/dashboard/ecommerce-page/product-details" class="fw-medium d-inline-block">
                                        Airbuds 2nd Gen
                                    </a>
                                    <span class="d-block text-body">
                                        04 Jun 2024
                                    </span>
                                </div>
                            </div>
                        </td>
                        <td>$79.00</td>
                        <td>56</td>
                        <td>
                            <span class="d-block">460</span>
                        </td>
                        <td>$4,424</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="trezo-card-pagination">
            <div class="d-md-flex align-items-center justify-content-between">
                <p class="mb-0">
                    Showing 5 of 36 results
                </p>
                <ol class="list-unstyled mb-0 p-0">
                    <li class="d-inline-block">
                        <a class="prev" routerLink=".">
                            <span class="opacity-0">
                                0
                            </span>
                            <i class="material-symbols-outlined">
                                chevron_left
                            </i>
                        </a>
                    </li>
                    <li class="d-inline-block">
                        <a routerLink="." class="active">
                            1
                        </a>
                    </li>
                    <li class="d-inline-block">
                        <a routerLink=".">
                            2
                        </a>
                    </li>
                    <li class="d-inline-block">
                        <a routerLink=".">
                            3
                        </a>
                    </li>
                    <li class="d-inline-block">
                        <a routerLink=".">
                            4
                        </a>
                    </li>
                    <li class="d-inline-block">
                        <a class="next" routerLink=".">
                            <span class="opacity-0">
                                0
                            </span>
                            <i class="material-symbols-outlined">
                                chevron_right
                            </i>
                        </a>
                    </li>
                </ol>
            </div>
        </div>
    </div>
</div>