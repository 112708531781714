<div class="modal-content">
    <div class="modal-header">
        <h1 class="modal-title fs-5">{{ 'Add Ticketing Agent' }}</h1>
        <button type="button" class="btn-close shadow-none" aria-label="Close" (click)="close()"></button>
    </div>
    <div class="modal-body">
        <div class="container-fluid">
            <div style="margin: 0 auto 10px;">
                <div class="filter-bg mt-1">
                    <form class="row g-3" id="agent-form"
                          [formGroup]="form">
                        <div class="custom-card">
                            <div class="row g-3">
                                @if (isPortalAdmin) {
                                    <div class="col-md-4 form-group">
                                        <label for="scheme" class="form-label required"> Scheme</label>
                                        <select id="scheme" [formControlName]="'organizationId'" class="form-select form-control form-select-sm">
                                            @if (fetchingOrganization) {
                                                <option [value]="''">Fetching Scheme</option>
                                            }

                                            @if (!fetchingOrganization  && organizations?.length==0) {
                                                <option [value]="''">No Scheme found</option>
                                            }

                                            @if (!fetchingOrganization && organizations?.length) {
                                                @if (organizations?.length||0 > 0) {
                                                    <option [value]="''">-- Select a Scheme --</option>
                                                }
                                                @for (p of organizations; track p) {
                                                    <option [value]="p.id">{{p.name}}</option>
                                                }
                                            }
                                        </select>
                                    </div>
                                }
                                <div class="col-md-4 form-group">
                                    <label for="zone" class="form-label required"> Zone</label>
                                    <select id="zone" [formControlName]="'zoneCode'" class="form-select form-control form-select-sm">

                                        @if (fetchingZone) {
                                            <option [value]="''">-- Fetching Zones --</option>
                                        }

                                        @if (!fetchingZone && !organizationId && isPortalAdmin) {
                                            <option [value]="''">-- Select a Scheme --</option>
                                        }

                                        @if (!fetchingZone  && zones?.length==0) {
                                            <option [value]="''">-- No zones found --</option>
                                        }

                                        @if (!fetchingZone && zones?.length) {
                                            @if (zones?.length||0 > 0) {
                                             <option [value]="''">-- Select a Zone --</option>
                                            }
                                            @for (p of zones; track p) {
                                                <option [value]="p.code">{{p.name}}</option>
                                            }
                                        }
                                    </select>
                                </div>
                                <div class="col-md-4 form-group">
                                    <label for="email" class="required">Agent Email</label>
                                    <input
                                            type="text"
                                            class="form-control"
                                            id="email" [formControlName]="'email'"
                                            placeholder="Agent Enter Email"
                                            required  [maxLength]="100"
                                    />
                                    <error-message [form]="form" [controlName]="'email'"/>
                                </div>
                                <div class="col-md-4 form-group">
                                    <label for="agent-first-name" class="required required">Agent First Name</label>
                                    <input
                                            type="text"
                                            class="form-control"
                                            id="agent-first-name" [formControlName]="'firstName'"
                                            placeholder="Agent First Name" [readonly]="setReadOnly" [maxLength]="100"
                                    />
                                    <error-message [form]="form" [controlName]="'firstName'"/>
                                </div>
                                <div class="col-md-4 form-group">
                                    <label for="agent-last-name" class="form-label required">Agent Last Name</label>
                                    <input
                                            type="text"
                                            class="form-control"
                                            id="agent-last-name" [formControlName]="'lastName'"
                                            placeholder="Agent Last Name" [readonly]="setReadOnly" [maxLength]="100"
                                    />
                                    <error-message [form]="form" [controlName]="'lastName'"/>
                                </div>
                                <div class="col-md-4 form-group">
                                    <label for="mobile-number" class="form-label required">Phone Number</label>
                                    <input type="text" id="mobile-number" [formControlName]="'mobileNumber'" class="form-control form-control"
                                           placeholder="Agent Phone Number" [readonly]="setReadOnly"/>
                                    <error-message [form]="form" [controlName]="'mobileNumber'"/>
                                </div>
                                <div class="col-md-4 ps-3 text-end">
                                </div>
                            </div>
                        </div>

                    </form>
                </div>

            </div>
            <agent-ticket-config
                    [agentTicketsConfig]="agentTicketsConfig"
                    [fetchingTickets]="fetchingTickets"/>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="close()">Close</button>
        <button type="button" class="btn btn-primary" (click)="submit()">
            @if (loading) {
                <div class="spinner-border spinner-border-sm" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            }
            {{ 'Create' }}
        </button>
    </div>
</div>
