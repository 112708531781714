/**
 * Tickets API
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MembershipPojo } from './membership-pojo';


export interface PortalUserPojo { 
    firstName?: string;
    lastName?: string;
    otherNames?: string;
    email?: string;
    phoneNumber?: string;
    displayName?: string;
    gender?: PortalUserPojo.GenderEnum;
    preferredName?: string;
    status?: PortalUserPojo.StatusEnum;
    id?: number;
    memberships?: Array<MembershipPojo>;
    createdAt?: string;
}
export namespace PortalUserPojo {
    export type GenderEnum = 'MALE' | 'FEMALE' | 'UNSPECIFIED' | 'OTHER';
    export const GenderEnum = {
        MALE: 'MALE' as GenderEnum,
        FEMALE: 'FEMALE' as GenderEnum,
        UNSPECIFIED: 'UNSPECIFIED' as GenderEnum,
        OTHER: 'OTHER' as GenderEnum
    };
    export type StatusEnum = 'ACTIVE' | 'INACTIVE';
    export const StatusEnum = {
        ACTIVE: 'ACTIVE' as StatusEnum,
        INACTIVE: 'INACTIVE' as StatusEnum
    };
}


