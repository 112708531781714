/**
 * Tickets API
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { State } from './state';
import { Lga } from './lga';
import { Country } from './country';
import { BinaryData } from './binary-data';
import { PortalUser } from './portal-user';


export interface Organization { 
    id?: number;
    createdAt?: string;
    lastUpdatedAt?: string;
    createdBy?: PortalUser;
    lastUpdatedBy?: PortalUser;
    name?: string;
    type?: Organization.TypeEnum;
    paymentValidationUrl?: string;
    schemeType?: Organization.SchemeTypeEnum;
    accountBanner?: BinaryData;
    state?: State;
    lga?: Lga;
    country?: Country;
}
export namespace Organization {
    export type TypeEnum = 'LOCAL_GOVERNMENT' | 'FEDERAL_GOVERNMENT' | 'STATE_GOVERNMENT' | 'OTHER';
    export const TypeEnum = {
        LOCAL_GOVERNMENT: 'LOCAL_GOVERNMENT' as TypeEnum,
        FEDERAL_GOVERNMENT: 'FEDERAL_GOVERNMENT' as TypeEnum,
        STATE_GOVERNMENT: 'STATE_GOVERNMENT' as TypeEnum,
        OTHER: 'OTHER' as TypeEnum
    };
    export type SchemeTypeEnum = 'DIRECT_PURCHASE_SCHEME' | 'ETRA_DEFAULT';
    export const SchemeTypeEnum = {
        DIRECT_PURCHASE_SCHEME: 'DIRECT_PURCHASE_SCHEME' as SchemeTypeEnum,
        ETRA_DEFAULT: 'ETRA_DEFAULT' as SchemeTypeEnum
    };
}


