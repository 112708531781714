<div
    class="sidebar-area bg-white transition"
    [ngClass]="{'active': isSidebarToggled}"
    [class.card-borderd]="themeService.isCardBordered()"
    [class.dark-sidebar]="themeService.isSidebarDark()"
    [class.hide-sidebar]="themeService.isHideSidebar()"
    [class.component-dark-theme]="themeService.isDark()"
    [class.right-sidebar]="themeService.isRightSidebar()"
    [class.component-rtl-enabled]="themeService.isRTLEnabled()"
    [class.card-with-box-shadow]="themeService.isCardWithBoxShadow()"
>
    <div class="logo bg-white">
        <a routerLink="/dashboard" class="d-flex align-items-center position-relative">
            <img src="images/logo-icon.svg" class="transition position-relative" alt="logo-icon">
            <span class="fw-bold text-black position-relative">
                SmartTickets
            </span>
        </a>
    </div>
    <div
        (click)="toggle()"
        class="burger-menu transition"
        [ngClass]="{'active': isSidebarToggled}"
    >
        <span class="top-bar transition d-block bg-black"></span>
        <span class="middle-bar transition d-block bg-black"></span>
        <span class="bottom-bar transition d-block bg-black"></span>
    </div>
    <ng-scrollbar visibility="hover">
        <div class="sidebar-inner">
            <div class="sidebar-menu">
                <div class="accordion">

                    <div class="accordion-item border-radius border-0">
                        <a routerLink="/dashboard" class="sidebar-menu-link w-100 position-relative border-radius fw-medium transition" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            <i class="material-symbols-outlined">
                                dashboard
                            </i>
                            <span class="title lh-1">
                                Dashboard
                            </span>
                        </a>
                    </div>
                    <div class="accordion-item border-radius border-0">
                        <a routerLink="/dashboard/organizations" class="sidebar-menu-link w-100 position-relative border-radius fw-medium transition" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            <i class="material-symbols-outlined">
                                calendar_today
                            </i>
                            <span class="title lh-1">
                                Schemes
                            </span>
                        </a>
                    </div>
                    <div class="accordion-item border-radius border-0">
                        <a routerLink="/dashboard/widgets" class="sidebar-menu-link w-100 position-relative border-radius fw-medium transition" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            <i class="material-symbols-outlined">
                                payment
                            </i>
                            <span class="title lh-1">
                                Tariff Management
                            </span>
                        </a>
                    </div>
                    <div class="accordion-item border-radius border-0">
                        <a routerLink="/dashboard/widgets" class="sidebar-menu-link w-100 position-relative border-radius fw-medium transition" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            <i class="material-symbols-outlined">
                                chat
                            </i>
                            <span class="title lh-1">
                                Vending Logs
                            </span>
                        </a>
                    </div>
                    <div class="accordion-item border-radius border-0">
                        <a routerLink="/dashboard/agents" class="sidebar-menu-link w-100 position-relative border-radius fw-medium transition" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            <i class="material-symbols-outlined">
                                perm_contact_calendar
                            </i>
                            <span class="title lh-1">
                                Agents
                            </span>
                        </a>
                    </div>
                    <div class="accordion-item border-radius border-0">
                        <a routerLink="/dashboard/app-users" class="sidebar-menu-link w-100 position-relative border-radius fw-medium transition" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            <i class="material-symbols-outlined">
                                person
                            </i>
                            <span class="title lh-1">
                                App Users
                            </span>
                        </a>
                    </div>
                    <div class="accordion-item border-radius border-0">
                        <a routerLink="/dashboard/billing" class="sidebar-menu-link w-100 position-relative border-radius fw-medium transition" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            <i class="material-symbols-outlined">
                                receipt_long
                            </i>
                            <span class="title lh-1">
                                Billing
                            </span>
                        </a>
                    </div>
                    <div class="accordion-item border-radius border-0">
                        <a routerLink="/dashboard/tickets" class="sidebar-menu-link w-100 position-relative border-radius fw-medium transition" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            <i class="material-symbols-outlined">
                                batch_prediction
                            </i>
                            <span class="title lh-1">
                                Tickets
                            </span>
                        </a>
                    </div>
                    <div class="accordion-item border-radius border-0">
                        <a routerLink="/authentication/logout" class="sidebar-menu-link w-100 position-relative border-radius fw-medium transition" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            <i class="material-symbols-outlined">
                                analytics
                            </i>
                            <span class="title lh-1">
                                Analytics
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </ng-scrollbar>
</div>
