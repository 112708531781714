import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {EventEmitter, Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {from, Observable, of} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';
import moment from 'moment';

import {KeycloakService} from 'keycloak-angular';
import {BrowserInfo, BrowserInformationService} from './browser-information.service';
import {PageManager} from './page-manager';
import {environment} from '../environments/environment';
import {AuthenticationService} from './authentication.service';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
    private _lastSeen: moment.Moment | undefined;
    private browserInfo?: BrowserInfo;
    private macAddress?: string;
    private buildVersion?: string;
    private _httpError = new EventEmitter<HttpErrorResponse>();

    constructor(
        private toastr: ToastrService,
        private keycloakService: KeycloakService,
        private browserInfoService: BrowserInformationService,
        private pageManager: PageManager,
        private authenticationService: AuthenticationService
    ) {
        const lastSeen = localStorage.getItem(HttpInterceptorService.name + '.lastSeen');
        this._lastSeen = lastSeen ? moment.unix(parseInt(lastSeen, 10)) : undefined;
        this.browserInfo = this.browserInfoService.getBrowserInfo();
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.headers.has('LOCAL')) {
            return next.handle(req.clone());
        }

        return from(this.authenticationService.getToken().asObservable())
            .pipe(
                catchError((error) => {
                    console.error('Failed to retrieve token:', error);
                    return of(null); // Fallback to null token on error
                }),
                mergeMap((token) => {
                    const headers: Record<string, string> = this.buildHeaders(token);

                    if (this.sessionTimedOut()) {
                        this.toastr.info(
                            `Session timed out after ${environment.sessionTimeout} mins of inactivity`,
                            'Session Timeout'
                        );
                        this.authenticationService.logout(window.location.origin);
                    }

                    this.updateLastSeen();
                    return next.handle(req.clone({setHeaders: headers}));
                })
            );
    }

    private buildHeaders(token: string | null): Record<string, string> {
        const headers: Record<string, string> = {};

        if (token) headers['Authorization'] = `Bearer ${token}`;
        if (this.pageManager.currentUserAccount$.value.accountCode) {
            headers['X-ACCOUNT-CODE'] = this.pageManager.currentUserAccount$.value.accountCode;
        }
        if (this.macAddress) headers['X-Device-ID'] = this.macAddress;
        if (this.buildVersion) headers['X-Plugin-Version'] = this.buildVersion;
        if (this.browserInfo?.browserVersion) {
            headers['X-BROWSER-VERSION'] = this.browserInfo.browserVersion;
        }

        return headers;
    }

    private sessionTimedOut(): boolean {
        return this._lastSeen &&
            moment().subtract(environment.sessionTimeout, 'minutes').isAfter(this._lastSeen);
    }

    private updateLastSeen(): void {
        this._lastSeen = moment();
        localStorage.setItem(
            HttpInterceptorService.name + '.lastSeen',
            `${this._lastSeen.valueOf() / 1000}`
        );
    }
}
