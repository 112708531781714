import {Injectable} from '@angular/core';
import {mergeMap, ReplaySubject} from 'rxjs';
import {KeycloakEvent, KeycloakEventType, KeycloakService} from 'keycloak-angular';

@Injectable({
    providedIn: 'root',
})
export class KeycloakEventService {

    private token$: ReplaySubject<string | null> = new ReplaySubject(1);

    constructor(
        private keycloak: KeycloakService,
    ) {
        keycloak.keycloakEvents$
            .pipe(
                mergeMap((value) => this.handleEvent(value))
            )
            .subscribe();
    }

    private async handleEvent(event: KeycloakEvent): Promise<void> {
        switch (event.type) {
            case KeycloakEventType.OnAuthSuccess:
            case KeycloakEventType.OnAuthRefreshSuccess:
                const token = await this.keycloak.getToken();
                this.token$.next(token);
                break;

            case KeycloakEventType.OnTokenExpired:
                try {
                    await this.keycloak.updateToken(30); // Attempt to refresh token
                } catch (error) {
                    console.error('[Keycloak] Failed to refresh token:', error);
                }
                break;

            case KeycloakEventType.OnAuthRefreshError:
                break;

            default:
                console.log(`[Keycloak] Unhandled event: ${event.type}`);
        }
    }


    public getToken(): ReplaySubject<string | null> {
        return this.token$;
    }

}