import {APP_INITIALIZER, ApplicationConfig, provideZoneChangeDetection} from '@angular/core';
import {provideRouter, withComponentInputBinding} from '@angular/router';

import {routes} from './app.routes';
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {environment} from "./environments/environment";
import {KeycloakService} from "keycloak-angular";
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import {BASE_PATH} from "../../sdk/tickets-sdk";
import {provideToastr} from "ngx-toastr";
import {HttpInterceptorService} from "./services/http-interceptor.service";
import {AuthenticationService} from "./services/authentication.service";

export const appConfig: ApplicationConfig = {
    providers: [
        provideZoneChangeDetection({eventCoalescing: true}),
        provideRouter(routes, withComponentInputBinding()),
        provideAnimationsAsync(),
        provideHttpClient(withInterceptorsFromDi()),
        provideZoneChangeDetection({eventCoalescing: true}),

        {
            provide: BASE_PATH,
            useValue: environment.siteUrl
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeKeycloak,
            multi: true,
            deps: [KeycloakService, AuthenticationService]
        },
        KeycloakService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorService,
            multi: true
        },
        provideToastr()
    ]
};


/**
 *
 * @param keycloak
 */
function initializeKeycloak(
    keycloak: KeycloakService
): () => Promise<boolean> {
    return (): Promise<boolean> => {
        return keycloak.init({
            config: environment.keycloakConfig,
            initOptions: {
                enableLogging: !environment.production,
                onLoad: 'check-sso',
                silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
            },
        });
    }
}

