import {EventEmitter, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AsyncSubject, from, mergeMap, Observable, of, ReplaySubject, Subject} from 'rxjs';
import {filter, tap} from 'rxjs/operators';
import {KeycloakEvent, KeycloakEventType, KeycloakService} from 'keycloak-angular';
import {HttpClient} from '@angular/common/http';
import {PageManager} from './page-manager';
import {User} from '../models/user/user.model';
import {UserAccount} from '../models/account/user-account.model';
import {PermissionType} from '../models/account/permission-type.enum';
import {environment} from '../environments/environment';
import {Constants} from '../models/enum/constants';
import {KeycloakLoginOptions} from 'keycloak-js';
import {KeycloakEventService} from "./keycloak-event.service";

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    private static _user: User;
    private static user: Subject<User | undefined> = new ReplaySubject(undefined);
    private static token: Subject<string | null> = new ReplaySubject(1);
    private static ongoingFetch: Observable<any> | null;
    private static initialized = false;
    private static _currentUserAccount: UserAccount | undefined;
    private static currentUserAccount$: Subject<UserAccount | null> = new ReplaySubject(undefined);
    private static newUserToken: EventEmitter<string | null> = new EventEmitter();
    public allAccounts!: UserAccount[];

    constructor(
        private httpClient: HttpClient,
        private keycloak: KeycloakService,
        private router: Router,
        private keycloakEventService: KeycloakEventService,
        private pageManager: PageManager,
    ) {
        AuthenticationService.user.subscribe((user) => this.initializeUser(user));
        // this.listenToKeycloakEvents();
        this.subscribeToTokenUpdates();
    }

    private subscribeToTokenUpdates(): void {
        this.keycloakEventService.getToken().subscribe((token) => {
            if (token) {
                this.setToken(token)
                this.fetchUser().subscribe();
            }
        });
    }

    private initializeUser(user: User | undefined): void {
        if (!user) {
            AuthenticationService._currentUserAccount = undefined;
            AuthenticationService.currentUserAccount$.next(null);
            return;
        }

        AuthenticationService.initialized = true;
        AuthenticationService._user = user;
        if (user.accounts) {
            this.processUserAccounts(user);
        }
    }

    private processUserAccounts(user: User): void {
        this.allAccounts = user.accounts.map((it: any) => new UserAccount(it));
        const storedAccount = this.getCurrentUserAccountInStorage(user) ?? this.allAccounts[0];
        const firstBusinessAccount = this.allAccounts.find((acc) => acc.accountType === 'ORGANIZATION');
        const lastCreatedBusiness = this.getLastCreatedBusinessAccount() || firstBusinessAccount;

        AuthenticationService._currentUserAccount =
            storedAccount?.accountType !== 'ORGANIZATION' && firstBusinessAccount
                ? firstBusinessAccount
                : storedAccount;

        AuthenticationService.currentUserAccount$.next(AuthenticationService._currentUserAccount);
    }

    private getLastCreatedBusinessAccount(): UserAccount | undefined {
        const lastCreatedCode = sessionStorage.getItem('LAST_CREATED_ORG_ACC_CODE');
        if (lastCreatedCode) {
            const lastCreated = this.allAccounts.find((acc) => acc.accountCode === lastCreatedCode);
            sessionStorage.removeItem('LAST_CREATED_ORG_ACC_CODE');
            return lastCreated;
        }
        return undefined;
    }

    // private listenToKeycloakEvents(): void {
    //     this.keycloak.keycloakEvents$
    //         .pipe(
    //             filter(
    //                 (value) =>
    //                     value.type === KeycloakEventType.OnAuthSuccess ||
    //                     value.type === KeycloakEventType.OnAuthRefreshSuccess ||
    //                     value.type === KeycloakEventType.OnAuthRefreshError ||
    //                     value.type === KeycloakEventType.OnTokenExpired
    //             ),
    //             mergeMap(() => from(this.keycloak.getToken()))
    //         )
    //         .subscribe((token: string | null) => {
    //             console.log("In auth service token guy")
    //             AuthenticationService.token.next(token);
    //         });
    // }

    public clearStaleSession(): void {
        const redirect = AuthenticationService._user;
        AuthenticationService.user.next(undefined);
        localStorage.clear();
        sessionStorage.clear();
        this.pageManager.clearAllData();
        if (redirect) location.href = this.router.createUrlTree(['/']).toString();
    }

    public logout(redirectUri?: string): Observable<void> {
        localStorage.setItem('isFirstLogin', 'true');
        this.clearStaleSession();
        return from(this.keycloak.logout(redirectUri)).pipe(
            tap(() => AuthenticationService.user.next(undefined))
        );
    }

    public login(loginOptions: KeycloakLoginOptions): Promise<void> {
        return this.keycloak.login(loginOptions);
    }

    public getToken(): Subject<string | null> {
        return AuthenticationService.token;
    }

    public getAccount(): Subject<UserAccount | null> {
        return AuthenticationService.currentUserAccount$;
    }

    private permissions(): string[] {
        const currentAccount = AuthenticationService._currentUserAccount;
        if (!currentAccount) {
            return [];
        }
        return currentAccount.permissions;
    }

    public hasPermission(permission: string | PermissionType): boolean {
        return this.permissions().includes(permission as string);
    }

    public hasAnyPermission(permissions: string[] | PermissionType[]): boolean {
        return permissions.some((permission) => this.hasPermission(permission));
    }

    public hasRole(role: string): boolean {
        return AuthenticationService._currentUserAccount?.roles.some((r) => r.toUpperCase() === role.toUpperCase()) ?? false;
    }

    public hasAnyRole(roles: string[]): boolean {
        return roles.some((role) => this.hasRole(role));
    }

    public hasAccountType(accountType: string): boolean {
        return AuthenticationService._currentUserAccount?.accountType === accountType;
    }

    public getUser(): Subject<User | undefined> {
        return AuthenticationService.user;
    }

    public forbidAccess(): void {
        this.router.navigate(['/forbidden']);
    }

    public fetchUser(): Observable<User> {

        if (AuthenticationService.initialized) {
            return of(AuthenticationService._user);
        }
        return this.fetch();
    }

    private fetch(): Observable<any> {
        if (AuthenticationService.ongoingFetch) {
            return AuthenticationService.ongoingFetch;
        }

        const subject = new AsyncSubject<any>();
        AuthenticationService.ongoingFetch = subject;

        this.httpClient.get(`${environment.apiBaseUrl}/user/me`).subscribe({
            next: (userData) => {
                const user = new User(userData);
                AuthenticationService.user.next(user);
                subject.next(user);
                subject.complete();
            },
            error: (error) => {
                AuthenticationService.user.next(undefined);
                subject.error(error);
            },
            complete: () => {
                AuthenticationService.ongoingFetch = null;
            },
        });

        return subject;
    }

    private getCurrentUserAccountFromStorage(): UserAccount | undefined {
        return this.pageManager.getData('USER_ACCOUNT', 'currentAccount', Constants.Storage.LOCAL) as UserAccount;
    }

    public getCurrentAccount(): UserAccount | null {
        return this.pageManager.getData('USER_ACCOUNT', 'currentAccount') as UserAccount | null;
    }

    public setCurrentUserAccount(userAccount: UserAccount): void {
        AuthenticationService.currentUserAccount$.next(userAccount);
        this.pageManager.storeData('USER_ACCOUNT', 'currentAccount', userAccount, Constants.Storage.LOCAL);
    }

    private getCurrentUserAccountInStorage(user: User): UserAccount | undefined {
        const accountInStorage = this.getCurrentUserAccountFromStorage();
        return this.allAccounts.find((acc) => acc.accountCode === accountInStorage?.accountCode);
    }

    public setToken(token: string): void {
        AuthenticationService.token.next(token);
    }

    public loginAndNavigateToDashboard(): Promise<any> {
        return this.login({
            redirectUri: `${window.location.origin}/dashboard`
        });
    }
}
