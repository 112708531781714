import {NgClass} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {ToggleService} from '../common/header/toggle.service';
import {CustomizerSettingsComponent} from '../customizer-settings/customizer-settings.component';
import {CustomizerSettingsService} from '../customizer-settings/customizer-settings.service';
import {FooterComponent} from '../common/footer/footer.component';
import {HeaderComponent} from '../common/header/header.component';
import {SidebarComponent} from '../common/sidebar/sidebar.component';
import {AuthenticationService} from "../services/authentication.service";
import {User} from "../models/user/user.model";

@Component({
    selector: 'app-dashboard',
    standalone: true,
    imports: [RouterOutlet, NgClass, HeaderComponent, SidebarComponent, FooterComponent, CustomizerSettingsComponent],
    templateUrl: './dashboard.component.html',
    styleUrl: './dashboard.component.scss'
})
export class DashboardComponent implements OnInit {

    user: User | undefined;
    userResponse = false;

    // isSidebarToggled
    isSidebarToggled = false;

    // isToggled
    isToggled = false;

    constructor(
        private toggleService: ToggleService,
        public themeService: CustomizerSettingsService,
        private authenticationService: AuthenticationService
    ) {
        this.toggleService.isSidebarToggled$.subscribe(isSidebarToggled => {
            this.isSidebarToggled = isSidebarToggled;
        });
        this.themeService.isToggled$.subscribe(isToggled => {
            this.isToggled = isToggled;
        });
    }

    ngOnInit(): void {
        this.authenticationService.fetchUser().subscribe({
            next: (user) => this.user = user
        }).add(() => this.userResponse = true);

    }

}
