/**
 * Tickets API
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TarrifManagementPojo { 
    buyingPrice?: number;
    sellingPrice?: number;
    organizationName?: string;
    name?: string;
    createdAt?: string;
    status?: TarrifManagementPojo.StatusEnum;
    ticketTypeId?: number;
}
export namespace TarrifManagementPojo {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE';
    export const StatusEnum = {
        ACTIVE: 'ACTIVE' as StatusEnum,
        INACTIVE: 'INACTIVE' as StatusEnum
    };
}


