/**
 * Tickets API
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NewOrganizationAdminDto } from './new-organization-admin-dto';
import { NewTariffItem } from './new-tariff-item';


export interface NewOrganizationDto { 
    name: string;
    revenueHeadCode: string;
    revenueHeadName: string;
    type: NewOrganizationDto.TypeEnum;
    address?: string;
    lgaId?: number;
    stateId?: number;
    countryId?: number;
    banner: string;
    tariffs: Array<NewTariffItem>;
    zones: Array<string>;
    admin?: NewOrganizationAdminDto;
}
export namespace NewOrganizationDto {
    export type TypeEnum = 'LOCAL_GOVERNMENT' | 'FEDERAL_GOVERNMENT' | 'STATE_GOVERNMENT' | 'OTHER';
    export const TypeEnum = {
        LOCAL_GOVERNMENT: 'LOCAL_GOVERNMENT' as TypeEnum,
        FEDERAL_GOVERNMENT: 'FEDERAL_GOVERNMENT' as TypeEnum,
        STATE_GOVERNMENT: 'STATE_GOVERNMENT' as TypeEnum,
        OTHER: 'OTHER' as TypeEnum
    };
}


