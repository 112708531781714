/**
 * Tickets API
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface NewAppUserRole { 
    organizationId: number;
    organizationName?: string;
    agentId: number;
    agentName?: string;
    role: NewAppUserRole.RoleEnum;
}
export namespace NewAppUserRole {
    export type RoleEnum = 'SELLER' | 'ENFORCER';
    export const RoleEnum = {
        SELLER: 'SELLER' as RoleEnum,
        ENFORCER: 'ENFORCER' as RoleEnum
    };
}


