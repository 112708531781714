<app-fp-banner />

<app-fp-key-features />

<app-fp-widgets />

<app-fp-testimonials />

<app-fp-team />

<app-fp-faq />

<app-fp-contact />

<app-fp-cta />