<div
    class="recent-orders-card trezo-card mb-25 border-radius d-block bg-white border-0 shadow-none"
    [class.component-rtl-enabled]="themeService.isRTLEnabled()"
>
    <div class="trezo-card-header d-sm-flex align-items-center justify-content-between">
        <div class="trezo-card-title">
            <h5 class="mb-0">
                Recent Orders
            </h5>
        </div>
        <div class="trezo-card-subtitle d-flex align-items-center">
            <form class="search-box position-relative">
                <label class="text-black">
                    <i class="material-symbols-outlined">
                        search
                    </i>
                </label>
                <input type="text" class="input-search d-block w-100 border-radius" placeholder="Search here.....">
            </form>
            <div class="trezo-card-header-menu position-relative">
                <button class="menu-btn transition d-inline-block bg-transparent border-radius" (click)="toggleCardHeaderMenu()">
                    <span class="d-block position-relative">
                        Show All
                    </span>
                </button>
                <ul class="dropdown-menu border-radius border-0 show" *ngIf="isCardHeaderOpen">
                    <li>
                        <button type="button" class="dropdown-item d-block w-100 position-relative transition">
                            This Day
                        </button>
                    </li>
                    <li>
                        <button type="button" class="dropdown-item d-block w-100 position-relative transition">
                            This Week
                        </button>
                    </li>
                    <li>
                        <button type="button" class="dropdown-item d-block w-100 position-relative transition">
                            This Month
                        </button>
                    </li>
                    <li>
                        <button type="button" class="dropdown-item d-block w-100 position-relative transition">
                            This Year
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="trezo-card-content">
        <div class="basic-table table-responsive">
            <table class="table mb-0">
                <thead>
                    <tr>
                        <th scope="col" class="fw-medium">
                            Order ID
                        </th>
                        <th scope="col" class="fw-medium">
                            Customer
                        </th>
                        <th scope="col" class="fw-medium">
                            Created
                        </th>
                        <th scope="col" class="fw-medium">
                            Total
                        </th>
                        <th scope="col" class="fw-medium">
                            Status
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            #JAN-2345
                        </td>
                        <td>
                            <div class="customer-info d-flex align-items-center">
                                <div class="img">
                                    <img alt="user-image" src="images/users/user1.jpg">
                                </div>
                                <span class="d-block fw-medium"> Sarah Johnson </span>
                            </div>
                        </td>
                        <td>
                            12 Jun 2024
                        </td>
                        <td>
                            $10,490
                        </td>
                        <td>
                            <span class="trezo-badge shipped">
                                Shipped
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            #JAN-1323
                        </td>
                        <td>
                            <div class="customer-info d-flex align-items-center">
                                <div class="img">
                                    <img alt="user-image" src="images/users/user2.jpg">
                                </div>
                                <span class="d-block fw-medium">
                                    Michael Smith
                                </span>
                            </div>
                        </td>
                        <td>
                            11 Jun 2024
                        </td>
                        <td>
                            $6,575
                        </td>
                        <td>
                            <span class="trezo-badge">
                                Confirmed
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            #DEC-1234
                        </td>
                        <td>
                            <div class="customer-info d-flex align-items-center">
                                <div class="img">
                                    <img alt="user-image" src="images/users/user3.jpg">
                                </div>
                                <span class="d-block fw-medium">
                                    Emily Brown
                                </span>
                            </div>
                        </td>
                        <td>
                            10 Jun 2024
                        </td>
                        <td>
                            $12,870
                        </td>
                        <td>
                            <span class="trezo-badge pending">
                                Pending
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            #DEC-3567
                        </td>
                        <td>
                            <div class="customer-info d-flex align-items-center">
                                <div class="img">
                                    <img alt="user-image" src="images/users/user4.jpg">
                                </div>
                                <span class="d-block fw-medium">
                                    Jason Lee
                                </span>
                            </div>
                        </td>
                        <td>
                            09 Jun 2024
                        </td>
                        <td>
                            $7,895
                        </td>
                        <td>
                            <span class="trezo-badge shipped">
                                Shipped
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            #DEC-1098
                        </td>
                        <td>
                            <div class="customer-info d-flex align-items-center">
                                <div class="img">
                                    <img alt="user-image" src="images/users/user5.jpg">
                                </div>
                                <span class="d-block fw-medium">
                                    Ashley Davis
                                </span>
                            </div>
                        </td>
                        <td>
                            08 Jun 2024
                        </td>
                        <td>
                            $4,680
                        </td>
                        <td>
                            <span class="trezo-badge rejected">
                                Rejected
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="trezo-card-pagination">
            <div class="d-md-flex align-items-center justify-content-between">
                <p class="mb-0">
                    Showing 5 of 36 results
                </p>
                <ol class="list-unstyled mb-0 p-0">
                    <li class="d-inline-block">
                        <a class="prev" routerLink=".">
                            <span class="opacity-0">
                                0
                            </span>
                            <i class="material-symbols-outlined">
                                chevron_left
                            </i>
                        </a>
                    </li>
                    <li class="d-inline-block">
                        <a routerLink="." class="active">
                            1
                        </a>
                    </li>
                    <li class="d-inline-block">
                        <a routerLink=".">
                            2
                        </a>
                    </li>
                    <li class="d-inline-block">
                        <a routerLink=".">
                            3
                        </a>
                    </li>
                    <li class="d-inline-block">
                        <a routerLink=".">
                            4
                        </a>
                    </li>
                    <li class="d-inline-block">
                        <a class="next" routerLink=".">
                            <span class="opacity-0">
                                0
                            </span>
                            <i class="material-symbols-outlined">
                                chevron_right
                            </i>
                        </a>
                    </li>
                </ol>
            </div>
        </div>
    </div>
</div>