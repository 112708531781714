<footer
    [class.card-without-border-radius]="themeService.isCardWithoutBorderRadius()"
    [class.card-with-box-shadow]="themeService.isCardWithBoxShadow()"
    class="footer-area text-center bg-white border-top-radius"
    [class.card-borderd]="themeService.isCardBordered()"
>
    <p>
        © <span>SmartTickets</span> powered by <a href="https://serverna.dev/" target="_blank">Serverna</a>
    </p>
</footer>
