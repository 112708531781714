/**
 * Tickets API
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrganizationWithMembershipStatus } from './organization-with-membership-status';
import { Zone } from './zone';
import { PortalUserPojo } from './portal-user-pojo';


export interface AgentPojo { 
    status?: AgentPojo.StatusEnum;
    portalUser?: PortalUserPojo;
    zone?: Zone;
    agentId?: string;
    id?: number;
    organizations?: Array<OrganizationWithMembershipStatus>;
}
export namespace AgentPojo {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE';
    export const StatusEnum = {
        ACTIVE: 'ACTIVE' as StatusEnum,
        INACTIVE: 'INACTIVE' as StatusEnum
    };
}


