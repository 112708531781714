<div
    class="total-customers-card trezo-card mb-25 border-radius d-block bg-white border-0 shadow-none"
    [class.component-rtl-enabled]="themeService.isRTLEnabled()"
    [class.component-dark-theme]="themeService.isDark()"
>
    <div class="trezo-card-content">
        <div class="title">
            <div class="d-flex d-md-block d-lg-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                    <span class="d-block">
                        Total Customers
                    </span>
                    <span class="info-badge d-inline-block">
                        +5.4
                    </span>
                </div>
                <span class="days d-block">
                    Last 7 days
                </span>
            </div>
            <h5 class="mb-0">
                1,528
            </h5>
        </div>
        <div class="chart mx-auto">
            <div id="ecommerce_total_customers_chart"></div>
        </div>
        <div class="info d-flex align-items-center justify-content-between">
            <span class="d-block">
                1 June
            </span>
            <span class="d-block">
                7 June
            </span>
        </div>
    </div>
</div>