<div class="trezo-card-content">
    <div class="">
        <div class="row g-3">
            <div class="col-sm-12"></div>
            <div class="col-sm-12 text-center mb-2">
                <i class="material-symbols-outlined">
                    {{ 'settings' }}
                </i>
                <h3>Organization Summary</h3>
            </div>
        </div>
    </div>

    <div class="row mt-3">
        <div class="row px-5">
            <div class="col-md-4 mb-5">
                <h6>Full Name</h6>
                <span>{{ data.admin.firstName|titlecase }} {{ data.admin.lastName|titlecase }}</span>
            </div>
            <div class="col-md-4 mb-5">
                <h6>Email address</h6>
                <span>{{ data.admin.email }}</span>
            </div>
            <div class="col-md-4 mb-5">
                <h6>Phone number</h6>
                <span>{{ data.admin.phoneNumber|titlecase }}</span>
            </div>
            <div class="col-md-4 mb-5">
                <h6>Country</h6>
                <span>{{ locationData.countryName || '-'|titlecase }}</span>
            </div>
            <div class="col-md-4 mb-5">
                <h6>State</h6>
                <span>{{ locationData.stateName || '-'|titlecase }}</span>
            </div>
            <div class="col-md-4 mb-5">
                <h6>LGA</h6>
                <span>{{ locationData.lgaName || '-'|titlecase }}</span>
            </div>
            <div class="col-md-4 mb-5">
                <h6>Type</h6>
                <span>{{ data.type | removeUnderscore |titlecase }}</span>
            </div>
            <div class="col-md-4 mb-5">
                <h6>Name</h6>
                <span>{{ data.name | titlecase }}</span>
            </div>
            <div class="col-md-4 mb-4">

            </div>
        </div>

        <div>
            <div class="mt-2 mb-5 d-flex table-responsive">
                <table class="table table-hover text-center flex-fill">
                    <thead class="w-100">
                    <tr class="">
                        <th scope="col">S/N</th>
                        <th scope="col">Vehicle Types</th>
                        <th scope="col">Buying Price</th>
                        <th scope="col">Selling Price</th>
                    </tr>
                    </thead>
                    <tbody class="w-100">
                        @if (data && (data.tariffs?.length || 0) > 0) {
                            @for (row of data.tariffs; track row; let i = $index) {
                                <tr>
                                    <th scope="row">{{ i + 1 }}</th>
                                    <td>
                                        <span>{{ row.ticketType }}</span>
                                    </td>
                                    <td>
                                        <span>{{ row.agentBuyingPricePerUnit | currency:'₦' }}</span>
                                    </td>
                                    <td>
                                        <span>{{ row.agentSellingPricePerUnit|currency:'₦' }}</span>
                                    </td>
                                </tr>
                            }
                        }
                        @if ((data.tariffs?.length || 0) < 1) {
                            <tr style="background-color: #eff0f0">
                                <td colspan="5">
                                    <div class="d-flex flex-column text-center py-5">
                                        <div class="flex-fill mb-2">

                                            <i class="material-symbols-outlined">
                                                {{ 'cloud_download' }}
                                            </i>
                                        </div>
                                        <div class="flex-fill">
                                            <h5 class="mx-5 text-muted font-weight-lighter">No Tariff has been
                                                configured</h5>
                                        </div>
                                    </div>
                                </td>

                            </tr>
                        }
                    </tbody>
                </table>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="d-flex px-2 py-1" style="background-color: #eff0f0">
                        <div class="">
                            <strong> Zones:</strong>
                        </div>
                        @if (data && data.zones.length > 0) {
                            <div class="flex-fill flex-wrap">

                                @for (item of data.zones; track item) {

                                    <span class="zone-pills px-3 mx-1 my-1">{{ item|titlecase }}</span>
                                }
                            </div>
                        }
                        @if (!data || data.zones.length < 1) {
                            <div class="flex-fill text-center">
                                <span> NO ZONES </span>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div class="row mt-5">
                <div class="mt-4 d-flex justify-content-between">
                    <button type="submit" class="btn btn-outline-dark" (click)="previousStep()">
                        <i class="material-symbols-outlined btn-icon">
                            arrow_backward
                        </i>
                        Previous
                    </button>

                    <button class="btn btn-primary" (click)="submit()">
                        Submit
                        @if (!working) {
                            <i class="fa fa-check"></i>
                        }

                        @if (working) {
                            <i class="fa fa-spinner fa-spin"></i>
                        }
                    </button>

                </div>
            </div>
        </div>
    </div>
</div>
