<div
    class="testimonials-area pb-120"
    [class.component-rtl-enabled]="themeService.isRTLEnabled()"
>
    <div class="container">
        <div class="section-title text-center mx-auto">
            <div class="sub-title d-inline-block position-relative">
                <span class="d-inline-block position-relative text-purple">
                    Testimonials
                </span>
            </div>
            <h2 class="mb-0">
                Inspiring Feedback: What Users Love About Trezo Dashboard
            </h2>
        </div>
        <div class="row justify-content-center">
            <div class="col-sm-6 col-lg-4">
                <div class="single-testimonial-item bg-white border-radius">
                    <div class="ratings lh-1">
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                    </div>
                    <p>
                        Trezo Dashboard Template has transformed how we manage our data. Its intuitive design and customizable features have streamlined our analytics process, enabling us to make informed decisions faster than ever before.
                    </p>
                    <div class="info d-flex align-items-center">
                        <img src="images/front-pages/user1.jpg" class="rounded-circle" alt="user-image">
                        <div>
                            <h5 class="fw-semibold">
                                Sarah Thompson
                            </h5>
                            <span class="d-block">
                                Data Analyst
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="single-testimonial-item bg-white border-radius">
                    <div class="ratings lh-1">
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-half-fill"></i>
                    </div>
                    <p>
                        As a developer, I appreciate the flexibility and robustness of Trezo Dashboard Template. It offers a wide range of features that cater to our diverse needs, and its clean codebase has made customization a breeze. Highly recommended!
                    </p>
                    <div class="info d-flex align-items-center">
                        <img src="images/front-pages/user2.jpg" class="rounded-circle" alt="user-image">
                        <div>
                            <h5 class="fw-semibold">
                                John Smith
                            </h5>
                            <span class="d-block">
                                Software Engineer
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="single-testimonial-item bg-white border-radius">
                    <div class="ratings lh-1">
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-line"></i>
                    </div>
                    <p>
                        Trezo Dashboard Template has been a lifesaver for our organization. It's helped us streamline our reporting processes and communicate insights effectively across departments. The time saved has allowed us to focus more on strategic initiatives.
                    </p>
                    <div class="info d-flex align-items-center">
                        <img src="images/front-pages/user3.jpg" class="rounded-circle" alt="user-image">
                        <div>
                            <h5 class="fw-semibold">
                                Alex Rodriguez
                            </h5>
                            <span class="d-block">
                                Marketing Director
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="single-testimonial-item bg-white border-radius">
                    <div class="ratings lh-1">
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-half-fill"></i>
                        <i class="ri-star-line"></i>
                    </div>
                    <p>
                        I can't recommend the Trezo Template enough. It's helped us gain a deeper understanding of our business metrics and identify areas for improvement. The responsive support team is a bonus, we are always ready to assist whenever needed.
                    </p>
                    <div class="info d-flex align-items-center">
                        <img src="images/front-pages/user4.jpg" class="rounded-circle" alt="user-image">
                        <div>
                            <h5 class="fw-semibold">
                                Kevin Brown
                            </h5>
                            <span class="d-block">
                                Jessica Martinez
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="single-testimonial-item bg-white border-radius">
                    <div class="ratings lh-1">
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                    </div>
                    <p>
                        Using Trezo Dashboard Template has been a game-changer for our team. The ability to customize widgets to suit our specific needs has allowed us to gain deeper insights into our performance metrics and drive business growth.
                    </p>
                    <div class="info d-flex align-items-center">
                        <img src="images/front-pages/user5.jpg" class="rounded-circle" alt="user-image">
                        <div>
                            <h5 class="fw-semibold">
                                Olivia Adams
                            </h5>
                            <span class="d-block">
                                Marketing Coordinator
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="single-testimonial-item bg-white border-radius">
                    <div class="ratings lh-1">
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-fill"></i>
                        <i class="ri-star-line"></i>
                        <i class="ri-star-line"></i>
                    </div>
                    <p>
                        As a startup, we needed a dashboard solution that was both powerful and cost-effective. Trezo Dashboard Template checked all the boxes for us. It's helped us stay agile and competitive in a fast-paced market.
                    </p>
                    <div class="info d-flex align-items-center">
                        <img src="images/front-pages/user6.jpg" class="rounded-circle" alt="user-image">
                        <div>
                            <h5 class="fw-semibold">
                                Daniel Lee
                            </h5>
                            <span class="d-block">
                                Co-founder, StartupX
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>