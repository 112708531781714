import { Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EcommerceComponent } from './dashboard/ecommerce/ecommerce.component';
import {FrontPagesComponent} from "./front-pages/front-pages.component";
import {HomeComponent} from "./front-pages/home/home.component";
import {FeaturesComponent} from "./front-pages/features/features.component";
import {TeamComponent} from "./front-pages/team/team.component";
import {FaqComponent} from "./front-pages/faq/faq.component";
import {ContactComponent} from "./front-pages/contact/contact.component";
import {NotFoundComponent} from "./not-found/not-found.component";
import {OrganizationDetailsComponent} from "./modules/organization/organization-details/organization-details.component";
import {
    OrganizationCreationComponent
} from "./modules/organization/organization-creation/organization-creation.component";
import {OrganizationComponent} from "./modules/organization/organization.component";
import {OrganizationListComponent} from "./modules/organization/organization-list/organization-list.component";
import {LoggedInGuard} from "./shared/guards/logged-in/logged-in.guard";
import {AgentComponent} from "./modules/agent/agent.component";
import {AgentListComponent} from "./modules/agent/agent-list/agent-list.component";
import {AppUserComponent} from "./modules/app-user/app-user.component";
import {AppUserListComponent} from "./modules/app-user/app-user-list/app-user-list.component";
import {BillingComponent} from "./modules/billing/billing.component";
import {BillListComponent} from "./modules/billing/bill-list/bill-list.component";
import {BillDetailsComponent} from "./modules/billing/bill-details/bill-details.component";

export const routes: Routes = [
    {
        path: '',
        component: FrontPagesComponent,
        children: [
            {path: '', component: HomeComponent},
            {path: 'features', component: FeaturesComponent},
            {path: 'team', component: TeamComponent},
            {path: 'faq', component: FaqComponent},
            {path: 'contact', component: ContactComponent}
        ]
    },
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [LoggedInGuard],
        children: [
            {path: '', component: EcommerceComponent},
            {
                path: 'organizations',
                component: OrganizationComponent,
                children: [
                    {
                        path: '',
                        component: OrganizationListComponent
                    },

                    {
                        path: 'new',
                        component: OrganizationCreationComponent,
                        pathMatch: 'full',
                        data: {
                            isExtranet: false,
                            loadingText: 'Loading...',
                            title: 'Create Scheme'
                        },
                        canDeactivate:[/*PendingChangesGuard*/ ]
                    },
                    {
                        path: 'detail/:id',
                        component: OrganizationDetailsComponent,
                        pathMatch: 'full',
                        data: {
                            isExtranet: false,
                            loadingText: 'Fetching Details',
                            title: 'Scheme details',
                            showBackLink:true,
                            backLinkTitle:'Back to Schemes',
                            backLink:'/organizations'
                        },
                    },
                ]
            },
            {
                path: 'agents',
                component: AgentComponent,
                children: [
                    {
                        path: '',
                        component: AgentListComponent
                    }
                ]

            },
            {
                path: 'app-users',
                component: AppUserComponent,
                children: [
                    {
                        path: '',
                        component: AppUserListComponent
                    }
                ]

            },
            {
                path: 'billing',
                component: BillingComponent,
                children: [
                    {
                        path: '',
                        component: BillListComponent
                    },
                    {
                        path: ':invoiceNumber/details',
                        component: BillDetailsComponent
                    }
                ]

            }
        ]
    },
    {path: '**', component: NotFoundComponent}
];
