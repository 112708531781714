/**
 * Tickets API
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Agent } from './agent';
import { Organization } from './organization';


export interface AccountMembershipPojo { 
    accountName?: string;
    accountCode?: string;
    accountId?: number;
    accountType?: AccountMembershipPojo.AccountTypeEnum;
    approvalStatus?: AccountMembershipPojo.ApprovalStatusEnum;
    roles?: Set<string>;
    permissions?: Set<AccountMembershipPojo.PermissionsEnum>;
    organization?: Organization;
    otherData?: object;
    agent?: Agent;
}
export namespace AccountMembershipPojo {
    export type AccountTypeEnum = 'ADMIN' | 'ORGANIZATION';
    export const AccountTypeEnum = {
        ADMIN: 'ADMIN' as AccountTypeEnum,
        ORGANIZATION: 'ORGANIZATION' as AccountTypeEnum
    };
    export type ApprovalStatusEnum = 'APPROVED' | 'PENDING' | 'DISAPPROVED';
    export const ApprovalStatusEnum = {
        APPROVED: 'APPROVED' as ApprovalStatusEnum,
        PENDING: 'PENDING' as ApprovalStatusEnum,
        DISAPPROVED: 'DISAPPROVED' as ApprovalStatusEnum
    };
    export type PermissionsEnum = 'CREATE_ORGANIZATION' | 'CREATE_USER' | 'REVIEW_SCHEME' | 'CREATE_AGENT';
    export const PermissionsEnum = {
        CREATE_ORGANIZATION: 'CREATE_ORGANIZATION' as PermissionsEnum,
        CREATE_USER: 'CREATE_USER' as PermissionsEnum,
        REVIEW_SCHEME: 'REVIEW_SCHEME' as PermissionsEnum,
        CREATE_AGENT: 'CREATE_AGENT' as PermissionsEnum
    };
}


