import {AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {parsePhoneNumber} from 'google-libphonenumber'
import {combineLatest, Observable, of, take} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, map, startWith} from "rxjs/operators";
import {PortalUserControllerService} from "../../../../sdk/tickets-sdk";
import {HttpErrorResponse} from "@angular/common/http";
import moment from "moment";


export class CustomValidators {

    static supportedExtensions(extensions: string[]): ValidatorFn {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            let fileName!: string;
            if (typeof control.value == 'string') {
                fileName = control.value;
            } else if (control.value instanceof File) {
                fileName = control.value.name;
            }

            if (!fileName) {
                return null;
            }
            for (let extension of extensions) {
                if (fileName.endsWith(extension)) {
                    return null;
                }
            }
            return {'unsupportedFileType': true};
        };
    }

    static sameValue(siblingControlPath: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            if (!control || !control.value) {
                return null;
            }
            const sibling = control?.parent?.get(siblingControlPath);

            return control.value === sibling?.value ? {'sameValue': true} : null;
        };
    }

    static minDate(minDate: Date): ValidatorFn {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            if (control.value == null) {
                return null;
            }
            return moment(control.value).isBefore(moment(minDate)) ? null : {'minDate': true};
        };
    }

    /**
     * @description
     * Checks that a string input is not blank. This implementation trims all whitespace characters before checking for
     * length.
     *
     * This validator works for only string inputs.
     *
     * @returns A validator error map with the
     * `notBlank` property if the validation check fails (the length of the string is less than 1 after trimming), otherwise `null`.
     * Note that this validator passes for null|undefined values but fails for empty strings
     */
    static notBlank(control: AbstractControl): ValidationErrors | null {
        return notBlankValidator(control);
    }

    static nigerianNumber(control: AbstractControl): ValidationErrors | null {
        try {
            if (control.value == null || control.value == '') {
                return null;
            }
            if (control.value.length < 11) {
                return {'nigerianNumber': true};
            }
            let phoneNumber = parsePhoneNumber(control.value, 'NG');
            if (!phoneNumber.isValid()) {
                return {'nigerianNumber': true};
            }
            return null;
        } catch (e) {
            console.log(e)
            return {'nigerianNumber': true};
        }
    }


  public static initAllRequiredChecker(... controls: AbstractControl[]) {
    let observable: Observable<any>[] = [];

    controls.forEach(control => {
      observable.push(control?.valueChanges?.pipe(startWith('')));
    });
    combineLatest(observable).
    pipe(distinctUntilChanged()).subscribe(([...v]) => {
      if (this.allRequired([...v])) {
        controls.forEach((v) => {
          v.addValidators(Validators.required);
          v.updateValueAndValidity({emitEvent: false});
        });
      } else {
        controls.forEach((v) => {
          v.removeValidators(Validators.required);
          v.updateValueAndValidity({emitEvent: false});
        });
      }
    });

  }

  static allRequired(array: any[]): boolean {
    return !array.every(x => x === null || x === '');

  }

    /**
     * @description
     * Validator that requires the length of the control's value to be greater than or equal
     * to the provided minimum length. This validator is also provided by default if you use the
     * the HTML5 `minlength` attribute. Note that the `minLength` validator is intended to be used
     * only for strings. The
     * `minLength` validator logic is also not invoked for values when their `length` property is 0
     * (for example in case of an empty string), to support optional controls. You
     * can use the standard `required` validator if empty values should not be considered valid.
     *
     * @usageNotes
     *
     * ### Validate that the field has a minimum of 3 characters
     *
     * ```typescript
     * const control = new FormControl('ng', Validators.minLength(3));
     *
     * console.log(control.errors); // {minlength: {requiredLength: 3, actualLength: 2}}
     * ```
     *
     * ```html
     * <input minlength="5">
     * ```
     *
     * @returns A validator function that returns an error map with the
     * `minlength` property if the validation check fails, otherwise `null`.
     *
     * @see `updateValueAndValidity()`
     *
     */
    static minLength(minLength: number, trimFirst = true): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value == null || control.value.length === 0) {
                // don't validate empty values to allow optional controls
                // don't validate values without `length` property
                return null;
            }

            return control.value.trim().length < minLength ?
                {'minlength': {'requiredLength': minLength, 'actualLength': control.value.trim().length}} :
                null;
        };
    }

    static maxLength(maxLength: number, trimFirst = true): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value == null || control.value.length === 0) {
                // don't validate empty values to allow optional controls
                // don't validate values without `length` property
                return null;
            }

            return control.value.trim().length > maxLength ?
                {'minlength': {'requiredLength': maxLength, 'actualLength': control.value.trim().length}} :
                null;
        };
    }


    static dateValidator(c: AbstractControl): { [key: string]: boolean } {
        let value = c.value;
        value = moment(value).format('YYYY-MM-DD')
        if (value && typeof value == "string") {
            let match = value.match(/^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/);
            if (!match) {
                return {'dateInvalid': true};
            } else if (match && match[0] !== value) {
                return {'dateInvalid': true};
            }
            return null;
        } else {
            return {'dateInvalid': true};
        }
    }


    static ninValidator(c: AbstractControl): { [key: string]: boolean } {
        let value = c.value;

        if (value && (typeof value === "string" || typeof value === 'number')) {
            let match = value.toString().match(/^[1-9][0-9]{10}$/);
            if (!match) {

                return {'ninInvalid': true};
            } else if (match && match[0] !== value) {
                return {'ninInvalid': true};
            }
            return null;
        } else {
            return {'ninInvalid': true};
        }
    }

    static async(obs: (control: AbstractControl) => Observable<ValidationErrors>): AsyncValidatorFn {
        return (control: AbstractControl): Observable<ValidationErrors> => {
            if (!control.value) {
                return of(null);
            }
            return obs.call(control);
        };
    }

    static uniqueUserPhoneNumber(portalUserControllerService: PortalUserControllerService) {
        return (control: AbstractControl): Observable<ValidationErrors> => {
            if (!control.value || control.value < 10) {
                return of(null);
            }
            return portalUserControllerService.uniqueUserPhone({phoneNumber: control.value})
                .pipe(map(value => {
                            if (value.code == 200) {
                                return null;
                            }
                            return {'uniqueuserphone': true};
                        }
                    ),
                    debounceTime(500),
                    take(1),
                    catchError((err: HttpErrorResponse, caught) => {
                        return of({'nointernet': true});
                    })
                );
        }
    }


    static getUniqueEmailValidator(portalUserControllerService: PortalUserControllerService) {
        return (control: AbstractControl): Observable<ValidationErrors> => {
            if (!control.value) {
                return of(null);
            }
            return portalUserControllerService.uniqueUserEmail({email: control.value})
                .pipe(map(value => {
                            if (value.code == 200) {
                                return null;
                            }
                            return {'uniqueuseremail': true};
                        }
                    ),
                    debounceTime(500),
                    take(1),
                    catchError((err: HttpErrorResponse, caught) => {
                        return of({'nointernet': true});
                    })
                );
        }
    }

}

export interface CustomAsyncValidator {
    validate(control: AbstractControl): Observable<ValidationErrors>;
}

export function notBlankValidator(control: AbstractControl): ValidationErrors | null {
    if (!control.value || control.value.length == 0) {
        return null;
    }
    return ('' + control.value).trim().length > 0 ? null : {'notBlank': true};
}

export function minDateValidator(control: AbstractControl, minDate: Date): ValidationErrors | null {
    if (control.value == null) {
        return null;
    }
    return moment(control.value).isBefore(moment(minDate)) ? null : {'minDate': true};
}
