/**
 * Tickets API
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PaymentTransaction } from './payment-transaction';
import { Bill } from './bill';
import { BinaryData } from './binary-data';
import { PortalUser } from './portal-user';


export interface PaymentInvoice { 
    id?: number;
    createdAt?: string;
    lastUpdatedAt?: string;
    createdBy?: PortalUser;
    lastUpdatedBy?: PortalUser;
    status?: PaymentInvoice.StatusEnum;
    dateDeactivated?: string;
    deactivatedBy?: PortalUser;
    amount?: number;
    invoiceNumber?: string;
    datePaid?: string;
    customerName?: string;
    customerEmail?: string;
    customerPhoneNumber?: string;
    paymentStatus?: PaymentInvoice.PaymentStatusEnum;
    description?: string;
    dueDate?: string;
    customerAddress?: string;
    billType?: PaymentInvoice.BillTypeEnum;
    approvalStatus?: PaymentInvoice.ApprovalStatusEnum;
    reasonForDisapproval?: string;
    totalAmountPaid?: number;
    qrCode?: BinaryData;
    bills?: Array<Bill>;
    paymentTransactions?: Array<PaymentTransaction>;
}
export namespace PaymentInvoice {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE';
    export const StatusEnum = {
        ACTIVE: 'ACTIVE' as StatusEnum,
        INACTIVE: 'INACTIVE' as StatusEnum
    };
    export type PaymentStatusEnum = 'PAID' | 'PENDING' | 'PARTIAL';
    export const PaymentStatusEnum = {
        PAID: 'PAID' as PaymentStatusEnum,
        PENDING: 'PENDING' as PaymentStatusEnum,
        PARTIAL: 'PARTIAL' as PaymentStatusEnum
    };
    export type BillTypeEnum = 'TICKET_BATCH';
    export const BillTypeEnum = {
        TICKET_BATCH: 'TICKET_BATCH' as BillTypeEnum
    };
    export type ApprovalStatusEnum = 'APPROVED' | 'PENDING' | 'DISAPPROVED';
    export const ApprovalStatusEnum = {
        APPROVED: 'APPROVED' as ApprovalStatusEnum,
        PENDING: 'PENDING' as ApprovalStatusEnum,
        DISAPPROVED: 'DISAPPROVED' as ApprovalStatusEnum
    };
}


